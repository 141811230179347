<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Fund Transfer</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Fund Transfer</a></li>
              <li class="breadcrumb-item active" aria-current="page">Fund Transfer</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Fund Transfer</h3>

    <!-- Card body -->
 <div>
  <b-tabs content-class="mt-3" tabs pills justified active-nav-item-class="font-weight-bold">
    <!------------------------------Start of tab one----------------------->
    <b-tab @click="tab('tab1_form1_1')" title="Internal" active>
        <code class="mb-4"> Transfer Funds to QUICKGEM </code>
        <form id="form1_1" v-if="page_transfer==='tab1_form1_1'"
          @submit.prevent="submitForm1_1" >
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Debit Account Number"
                      name="Debit Account Number"
                      type="number"
                      placeholder="Debit Account Number"
                      required
                      v-model="tab1.debitAccountNumber">
          </base-input>
        </div>
      </div>

      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Check Account</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
    <!----------------------------------End of form1---------------------------------->
<!-----------------------------------Start of form2------------------------------->
    <form id="form1_2" v-if="page_transfer==='tab1_form1_2'"
          @submit.prevent="submitForm1_2" >
           <div class="row"><div class="col-12"><div class="confirmation-box mb-4"><p class="destination text-left"> from </p><p class="text-left"><span class="confirmation-box-wrap"><div><!----><span title="View Customers Info" class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer">
               {{`${response_transfer.accountName.split(' ')[0].charAt(0)}${response_transfer.accountName.split(' ')[1].charAt(0)}`}}</span><div tabindex="-1" class="vodal app-modal" style="animation-duration: 300ms; display: none;"><div class="vodal-mask"></div> <div class="vodal-dialog" style="width: 450px; height: 500px; animation-duration: 300ms; display: none;"><span class="vodal-close"></span> <div class="card"><div class="card-body"><div><div ><div  class="small-loader"><div ><div  class="jt"><svg  height="100%" viewBox="0 0 32 32" width="100%"><circle  cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); opacity: 0.2;"></circle><circle  cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle></svg></div></div></div></div></div></div></div></div></div></div><span class="media-body chat-user-box">
               <span class="user-title m-0">{{response_transfer.accountName}}</span>
               <span class="text-muted"> {{`${response_transfer.accountNumber} (${response_transfer.accountBankName})`}} </span></span>
               <span class="balance"> <strong>₦ {{response_transfer.accountBalance}}</strong> </span></span></p></div></div></div>
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Credit Account Number"
                      name="Credit Account Number"
                      type="number"
                      placeholder="Credit Account Number"
                      required
                      v-model="tab1.creditAccountNumber">
          </base-input>
        </div>
      </div>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="previousPage('tab1_form1_1')">
       Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Check Account</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      </div>
      </div>
    </form>
<!-----------------------------------End of form2--------------------------------->
<!-----------------------------------Start of form 3----------------------------->
<form id="form1_3" v-if="page_transfer==='tab1_form1_3'"
          @submit.prevent="submitForm1_3" >
           <div class="row">
               <div class="col-6">
                   <div class="confirmation-box mb-4"><p class="destination text-left"> from </p>
                   <p class="text-left"><span class="confirmation-box-wrap"><div ><!---->
                   <span  title="View Customers Info" class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer">
                       {{`${response_transfer.accountName.split(' ')[0].charAt(0)}${response_transfer.accountName.split(' ')[1].charAt(0)}`}}</span>
                   <div  tabindex="-1" class="vodal app-modal" style="animation-duration: 300ms; display: none;">
                    <div class="vodal-mask"></div> <div class="vodal-dialog" style="width: 450px; height: 500px; animation-duration: 300ms; display: none;">
                        <span class="vodal-close"></span> <div  class="card"><div  class="card-body"><div >
                            <div ><div class="small-loader"><div><div class="jt"><svg height="100%" viewBox="0 0 32 32" width="100%">
                            <circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); opacity: 0.2;"></circle>
                            <circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle></svg></div></div></div></div></div></div></div></div></div></div>
                            <span class="media-body chat-user-box"><span class="user-title m-0">{{response_transfer.accountName}}</span>
                            <span class="text-muted"> {{`${response_transfer.accountNumber} (${response_transfer.accountBankName})`}} </span></span>
                            <span class="balance"><strong> ₦ {{response_transfer.accountBalance}} </strong></span>
                            </span></p></div></div><div class="col-6"><div class="confirmation-box mb-4">
                            <p class="destination text-left"> to </p><p class="text-left"><span class="confirmation-box-wrap">
                                <div ><!----><span  title="View Customers Info" class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer">
                                    {{`${response_transfer1.accountName.split(' ')[0].charAt(0)}${response_transfer1.accountName.split(' ')[1].charAt(0)}`}}</span>
                                <div  tabindex="-1" class="vodal app-modal" style="animation-duration: 300ms; display: none;">
                                    <div class="vodal-mask"></div> <div class="vodal-dialog" style="width: 450px; height: 500px; animation-duration: 300ms; display: none;">
                                        <span class="vodal-close"></span> <div  class="card"><div  class="card-body"><div ><div >
                                <div class="small-loader"><div><div class="jt"><svg height="100%" viewBox="0 0 32 32" width="100%">
                                    <circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); opacity: 0.2;"></circle><circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle></svg></div></div></div></div></div></div></div></div></div></div>
                                    <span class="media-body chat-user-box"><span class="user-title m-0">{{response_transfer1.accountName}}</span>
                                    <span class="text-muted">  {{`${response_transfer1.accountNumber} (${response_transfer1.accountBankName})`}}  </span></span>
                                    <span class="balance"> <strong>₦ {{response_transfer1.accountBalance}}</strong> </span></span></p></div></div></div>
      
      <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Amount"
                      name="Amount"
                      type="number"
                      placeholder="Amount"
                      required
                      v-model="tab1.amount">
          </base-input>
        </div>
         <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="FT Product">
                      <el-select class="select-danger" clearable filterable required
             placeholder="FT Product"
             v-model="tab1.product">
    <el-option v-for="option in option_product"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="accCtrl" class="form-error-message"> The FT Product is Required' </p>
                    </base-input>
        </div>
      </div>

      
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Narration"
                      name="Narration"
                      type="text"
                      placeholder="Narration"
                      :required="inputRequired"
                      v-model="tab1.narration">
          </base-input>
        </div>
      </div>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="previousPage('tab1_form1_2')">
       Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Transfer</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      </div>
      </div>
    </form>
<!-----------------------------------End of form 3--------------------------------->

    
    </b-tab>
    <!---------------------------------End of Tab one---------------------------->


    <!---------------------------------Start of Tab Two---------------------------->
    <!------------------------------Start of tab one----------------------->
    <b-tab @click="tab('tab2_form1_1')" title="External">
        <code class="mb-4"> Transfer Funds to other Bank </code>
        <form id="form2_1" v-if="page_transfer==='tab2_form1_1'"
          @submit.prevent="submitForm2_1" >
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Debit Account Number"
                      name="Debit Account Number"
                      type="number"
                      placeholder="Debit Account Number"
                      required
                      v-model="tab2.debitAccountNumber">
          </base-input>
        </div>
      </div>

      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Check Account</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
    <!----------------------------------End of form1---------------------------------->
<!-----------------------------------Start of form2------------------------------->
    <form id="form2_2" v-if="page_transfer==='tab2_form1_2'"
          @submit.prevent="submitForm2_2" >
           <div class="row"><div class="col-12"><div class="confirmation-box mb-4"><p class="destination text-left"> from </p><p class="text-left"><span class="confirmation-box-wrap"><div><!----><span title="View Customers Info" class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer">
               {{`${response_transfer.accountName.split(' ')[0].charAt(0)}${response_transfer.accountName.split(' ')[1].charAt(0)}`}}</span><div tabindex="-1" class="vodal app-modal" style="animation-duration: 300ms; display: none;"><div class="vodal-mask"></div> <div class="vodal-dialog" style="width: 450px; height: 500px; animation-duration: 300ms; display: none;"><span class="vodal-close"></span> <div class="card"><div class="card-body"><div><div ><div  class="small-loader"><div ><div  class="jt"><svg  height="100%" viewBox="0 0 32 32" width="100%"><circle  cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); opacity: 0.2;"></circle><circle  cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle></svg></div></div></div></div></div></div></div></div></div></div><span class="media-body chat-user-box">
               <span class="user-title m-0">{{response_transfer.accountName}}</span>
               <span class="text-muted"> {{`${response_transfer.accountNumber} (${response_transfer.accountBankName})`}} </span></span>
               <span class="balance"> <strong>₦ {{response_transfer.accountBalance}}</strong> </span></span></p></div></div></div>
      <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Credit Account Number"
                      name="Credit Account Number"
                      type="number"
                      placeholder="Credit Account Number"
                      required
                      v-model="tab2.creditAccountNumber">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Bank">
                      <el-select class="select-danger"  clearable filterable required
             placeholder="Bank Name"
             v-model="tab2.bank">
    <el-option v-for="option in option_bank"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <!--<p v-show="accCtrl" class="form-error-message"> The FT Product is Required' </p>-->
                    </base-input>
        </div>
      </div>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="previousPage('tab2_form1_1')">
       Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Check Account</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      </div>
      </div>
    </form>
<!-----------------------------------End of form2--------------------------------->
<!-----------------------------------Start of form 3----------------------------->
<form id="form2_3" v-if="page_transfer==='tab2_form1_3'"
          @submit.prevent="submitForm2_3" >
           <div class="row">
               <div class="col-6">
                   <div class="confirmation-box mb-4"><p class="destination text-left"> from </p>
                   <p class="text-left"><span class="confirmation-box-wrap"><div ><!---->
                   <span  title="View Customers Info" class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer">
                       {{`${response_transfer.accountName.split(' ')[0].charAt(0)}${response_transfer.accountName.split(' ')[1].charAt(0)}`}}</span>
                   <div  tabindex="-1" class="vodal app-modal" style="animation-duration: 300ms; display: none;">
                    <div class="vodal-mask"></div> <div class="vodal-dialog" style="width: 450px; height: 500px; animation-duration: 300ms; display: none;">
                        <span class="vodal-close"></span> <div  class="card"><div  class="card-body"><div >
                            <div ><div class="small-loader"><div><div class="jt"><svg height="100%" viewBox="0 0 32 32" width="100%">
                            <circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); opacity: 0.2;"></circle>
                            <circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle></svg></div></div></div></div></div></div></div></div></div></div>
                            <span class="media-body chat-user-box"><span class="user-title m-0">{{response_transfer.accountName}}</span>
                            <span class="text-muted"> {{`${response_transfer.accountNumber} (${response_transfer.accountBankName})`}}  </span></span>
                            <span class="balance"><strong> ₦ {{response_transfer.accountBalance}} </strong></span>
                            </span></p></div></div><div class="col-6"><div class="confirmation-box mb-4">
                            <p class="destination text-left"> to </p><p class="text-left"><span class="confirmation-box-wrap">
                                <div ><!----><span  title="View Customers Info" class="d-flex mr-3 avatar-title bg-primary rounded-circle font-size-16 real-avatar pointer">
                                    {{`${response_transfer1.accountName.split(' ')[0].charAt(0)}${response_transfer1.accountName.split(' ')[1].charAt(0)}`}}</span>
                                <div  tabindex="-1" class="vodal app-modal" style="animation-duration: 300ms; display: none;">
                                    <div class="vodal-mask"></div> <div class="vodal-dialog" style="width: 450px; height: 500px; animation-duration: 300ms; display: none;">
                                        <span class="vodal-close"></span> <div  class="card"><div  class="card-body"><div ><div >
                                <div class="small-loader"><div><div class="jt"><svg height="100%" viewBox="0 0 32 32" width="100%">
                                    <circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); opacity: 0.2;"></circle><circle cx="16" cy="16" fill="none" r="14" stroke-width="4" style="stroke: rgb(85, 110, 230); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle></svg></div></div></div></div></div></div></div></div></div></div>
                                    <span class="media-body chat-user-box"><span class="user-title m-0">{{response_transfer1.accountName}}</span>
                                    <span class="text-muted"> {{`${response_transfer1.accountNumber} (${response_transfer1.accountBankName})`}} </span></span>
                                    <span class="balance"> <strong> ₦ {{response_transfer1.accountBalance}}</strong> </span></span></p></div></div></div>
      
      <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Amount"
                      name="Amount"
                      type="number"
                      placeholder="Amount"
                      required
                      v-model="tab2.amount">
          </base-input>
        </div>
         <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="FT Product">
                      <el-select class="select-danger" clearable filterable required
             placeholder="FT Product"
             v-model="tab2.product">
    <el-option v-for="option in option_product"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="accCtrl" class="form-error-message"> The FT Product is Required' </p>
                    </base-input>
        </div>
      </div>

      
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Narration"
                      name="Narration"
                      type="text"
                      placeholder="Narration"
                      required
                      v-model="tab2.narration">
          </base-input>
        </div>
      </div>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="previousPage('tab2_form1_2')">
       Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transfer}">
     <span class="btn-inner--text">Check Account</span>
       <span :class="{'spinner-border': loading_transfer}"></span>
     </base-button>
      </div>
      </div>
    </form>
<!-----------------------------------End of form 3--------------------------------->

    
    </b-tab>
    <!---------------------------------End of Tab one---------------------------->
<!---------------------------------End of Tab two---------------------------->
    </b-tabs>
</div>
    
  </card>
 

        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import TabPane from '@/components/Tabs/Tab';
import Tabs from '@/components/Tabs/Tabs';
import { mapState, mapActions, mapGetters } from "vuex";
export default {

    components: {
        BaseHeader,
        //tab,
        //TabPane,
        //Tabs,
      },
    data() {
      return {
          //selected_transferCode:{},
          //option_bank:[{value:'00001',label:'VI'},{value:'00002',label:'Ikeja'}],
          accountNumber:'',
          inputRequired:true,

           tab1:{
               debitAccountNumber:'',
               creditAccountNumber:'',
               narration:'',
               product:'',
               amount:''
           },
           tab2:{
               debitAccountNumber:'',
               creditAccountNumber:'',
               narration:'',
               product:'',
               amount:'',
               bank:''
           }, 

accCtrl:false,
          modals:{
              modal3:false
              },
  result:false,
  option_bank:[],
  option_product:[],

  

      }
    },
    methods: {
        submitForm1_1(){
          var payload ={};
          var page={};
             this.$store.dispatch("checkBalBank",{
                 payload:{accountBankCode: this.userInformation.userBankCode,
                accountNumber: this.tab1.debitAccountNumber,
                request: "banklist"},
                page:{
                page:'tab1_form1_2'
                }
    }, { root: false });
        },
        submitForm1_2(){
           var payload ={};
          var page={};
            this.$store.dispatch("checkBalProd",{
         payload:{
           accountBankCode: this.userInformation.userBankCode,
                accountNumber: this.tab1.creditAccountNumber,
               productBankCode: this.userInformation.userBankCode,
                productType: "FT"
                },
                page:{
                page:'tab1_form1_3'
                }
    }, { root: false });
        },
        submitForm1_3(){
           var payload ={};
          var page={};
            this.$store.dispatch("fundTransfer",{
                page:{
                  page:'tab1_form1_1'
                  },
          payload:{
            creditAccountBank: this.response_transfer1.accountBankName,
creditAccountName:this.response_transfer1.accountName,
creditAccountNumber: this.response_transfer1.accountNumber,
creditAccountPhone: this.response_transfer1.accountCustomerPhone,
creditAccountSwitchCode: this.response_transfer1.accountBankCode,
debitAccountBankCode:this.response_transfer.accountBankCode,
debitAccountBranchCode:this.response_transfer.accountBranchCode,
debitAccountName: this.response_transfer.accountName,
debitAccountNumber: this.response_transfer.accountNumber,
ftProductCode: this.tab1.product,
reference: `${this.makeIDNum(8)}-${this.makeIDNum(4)}-${this.makeIDNum(4)}-${this.makeIDNum(4)}-${this.makeIDNum(12)}-${this.userInformation.userId}`,
source: "CBA",
trnAmount: this.tab1.amount,
trnChecker: "SYSTEM",
trnMaker: this.userInformation.userId,
trnNarration: this.tab1.narration,
trnSwitch: "INTERNAL",
trnSwitchService: "INTERNAL",
trnType: "INTERNAL"
}
    }, { root: false });
},

        submitForm2_1(){
           var payload ={};
          var page={};
            this.$store.dispatch("checkBalBank",{
                payload:{
                accountBankCode: this.userInformation.userBankCode,
                accountNumber: this.tab2.debitAccountNumber,
                request: "banklist"
                },
                page:{
                page:'tab2_form1_2'
                }
    }, { root: false });
        },
        submitForm2_2(){
         this.$store.dispatch("checkNameProd",{
             payload:{
            username: this.userInformation.userId,
            serviceBank:this.userInformation.userBankCode,
          accountBankCode:this.tab2.bank, 
                accountNumber:this.tab2.creditAccountNumber,
               productBankCode:this.userInformation.userBankCode,
                productType: "FT"
          },
              page:{
             page:'tab2_form1_3'
          } 
    }, { root: false });
        },
        submitForm2_3(){

        },

makeIDNum(length) {
   var result           = '';
   var characters       = '0123456789abcdefghijklmnopqrstuvwxyz';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
},

        previousPage(page){
            this.$store.dispatch("updatePage",{
          page:page
    }, { root: false });
        },

        tab(page){
            console.log(`>>>>> ${page}`)
            this.$store.dispatch("updatePage",{
          page:page
    }, { root: false });
        },
       resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
      submit() {
         this.$store.dispatch("balanceEnquiry",{
          accountBankCode: this.userInformation.userBankCode,
          accountNumber: this.accountNumber
    }, { root: false });
    document.getElementById("submitForm").reset();
      },
     
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{
       
response_bankList(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_bankList).length !== 0){
           if(this.response_bankList.data.length!==0){
    var bank = [];
    bank = this.response_bankList.data;
    this.option_bank = bank.map((item) => {
            return {
                value: `${item.bankCode}`,
                label: `${item.bankName} (${item.bankCode})`
            };
        });
           }
           else{
            this.option_bank=[{value:'',label:'No Record'}];
        }
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },

response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
           if(this.response_product.data.length!==0){
    var bank = [];
    bank = this.response_product.data;
    this.option_product = bank.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code}`
            };
        });
           }
           else{
            this.option_product=[{value:'',label:'No Record'}];
        }
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },


    error_transfer(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    success_transfer(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        this.modals.modal3 = true;
        this.result = true;
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
       this.$store.dispatch("updatePage",{
          page:'tab1_form1_1'
    }, { root: false });
    },
    computed:{
           ...mapState({
              userInformation: state => state.auth.userInfo,
        response_user: state => state.user.user_response,
    response_transfer: state => state.transfer.transfer_response, 
    response_transfer1: state => state.transfer.transfer_response1,
    loading_transfer: state => state.transfer.transfer_loading,
    success_transfer: state => state.transfer.transfer_success,error_transfer: state => state.transfer.transfer_errors,
    error_user: state => state.user.user_error,
    page_transfer: state => state.transfer.transfer_page,
    response_bankList:state => state.transfer.bankList,
    response_product:state => state.transfer.productList
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}

.notification-modal-inner {
    padding: 20px;
}
.transactional-notification .title {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 800;
    color: #9E9E9E;
}
 .cen{
   display: flex;
  justify-content: center;
  margin-bottom: 15px;
 }

 .icon {
    text-align: center !important;
    /*margin: 0 auto !important;*/
    float:center!important;
    margin-bottom: 100px;
    
}
.transactional-notification .cen span.avatar {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    background-color: #1e222e;
    display: inline-block;
    text-align: center;
    padding: 35px 0;
    font-size: 40px;
    line-height: 50px;
    color: #ffffff;
}
.transactional-notification .transaction-info {
    margin-bottom: 20px;
}
.transactional-notification .info-card {
    margin-bottom: 3px;
}
.transactional-notification .transaction-info .title {
    text-align: right;
    display: block;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #9E9E9E;
    margin-bottom: 8px;
}
.transactional-notification .transaction-info .value {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 18px;
    color: #1f232e;
}
.card-profile-image span{
    max-width: 55px;
    border-radius: .375rem;
    border: 3px solid #fff;
    -webkit-transform: translate(-50%,-50%) scale(1);
    transform: translate(-50%,-50%) scale(1);
    position: absolute;
    left: 50%;
    -webkit-transition: all .15s ease;
    transition: all .15s ease;
}
.avatar-title{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #556ee6;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 55px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100px;
}
.destination {
    margin-bottom: 10px;
    padding-left: 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #1d1c1c;
}

.confirmation-box .confirmation-box-wrap {
    display: flex;
}
.confirmation-box .destination{
    margin-bottom: 10px;
    padding-left: 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #BDBDBD;
}
.confirmation-box .real-avatar {
    padding: 5px 5px;
    width: 40px;
    height: 40px;
    margin-right: 10px !important;
}
.pointer{
    cursor: pointer;
}
.confirmation-box .user-title {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}
.confirmation-box .text-muted {
    font-size: 12px;
    line-height: 18px;
}
.confirmation-box .balance {
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
    color: #464545;
    padding: 10px;
}
.confirmation-box .amount {
    font-size: 22px;
    font-weight: 700;
    color: #757575;
}

.counter-party-radio {
    display: flex;
}
.counter-party-radio > span {
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
}
.counter-party-radio > span .custom-control{
    margin-right: 20px;
    margin-top: 10px;
}
</style>
