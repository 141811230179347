<template>
<div>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Customer</h6>
<!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
<!--            <ol class="breadcrumb breadcrumb-links">-->
<!--              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>-->
<!--              <li class="breadcrumb-item"><a href="#">Customer</a></li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">Customer</li>-->
<!--            </ol>-->
<!--          </nav>-->
        </div>
        <div class="col-lg-6 col-5 text-right">
          <router-link to="/customer/create-upload"> <base-button size="md" outline class="m-1"><i class="fas fa-plus"></i> Create Customers Upload</base-button></router-link>
          <base-button size="md" outline @click="show=true" class=""><i class="fas fa-plus"></i> New Customer</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
  <div class="row">
         <div class="col">
<ViewCustomerTable></ViewCustomerTable>
<CreateCustomer @closeCreateCustomer="updateCreateCustomer" :showCreateCustomer="show" ></CreateCustomer>
        </div>
  </div>

</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import ViewCustomerTable from './ViewCustomerTable';
import CreateCustomer from './CreateCustomer';

  export default {
    components: {
        BaseHeader,
        ViewCustomerTable,
        CreateCustomer

    },
    data(){
  return{
    show:false
  }
},
  methods: {
       updateCreateCustomer(value){
   this.show=value;
},

}
  }
</script>
