<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Request Account Statement</h6>
<!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
<!--            <ol class="breadcrumb breadcrumb-links">-->
<!--              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>-->
<!--              <li class="breadcrumb-item"><a href="#">customer</a></li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">Request Account Statement</li>-->
<!--            </ol>-->
<!--          </nav>-->
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
             <card>
    <!-- Card header -->
<!--    <h3 slot="header" class="mb-0">Request Account Statement</h3>-->

    <!-- Card body -->

    <form @click="resetForm"
          @submit.prevent="submit">
<!------------------------------Line1-------------------------------->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Account Number"
                      name="Account Number"
                      placeholder="Account Number"
                      :required='inputRequired'
                      v-model="model.accountNumber">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Email"
                      name="Email"
                      placeholder="Email"
                      :required='inputRequired'
                      v-model="model.email">
          </base-input>
        </div>
        </div>
<!------------------------------End of Line1-------------------------------->

<!------------------------------Line2-------------------------------->
        <div class="row">
           <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <label class="form-control-label"> Start Date</label>
  <datetime
        v-model="model.startDate" input-class="form-control" class="theme-green"
        placeholder="Pick a day"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="minDatetime" :max-datetime="maxDatetime"></datetime>
         <p v-show="s_dateCtrl" class="form-error-message"> The Start Date is required </p>
          <!--<base-input label="Start Date">
                     <el-date-picker
      v-model="model.startDate"
      type="date"
      placeholder="Pick a day">
    </el-date-picker>
    <p v-show="s_dateCtrl" class="form-error-message"> The Start Date is required </p>
          </base-input>-->

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
           <label class="form-control-label"> End Date</label>
  <datetime
        v-model="model.endDate" input-class="form-control" class="theme-green"
        placeholder="Pick a day"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="minDatetime" :max-datetime="maxDatetime"></datetime>
        <p v-show="e_dateCtrl" class="form-error-message"> The End Date is required </p>
         <!-- <base-input label="End Date">
                     <el-date-picker
      v-model="model.endDate"
      type="date"
      placeholder="Pick a day">
    </el-date-picker>
    <p v-show="e_dateCtrl" class="form-error-message"> The End Date is required </p>
          </base-input>-->

        </div>
        </div>
<!------------------------------End of Line2-------------------------------->


      <div class="text-right mt-2">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_account}">
     <span class="btn-inner--text">Submit</span>
        <span :class="{'spinner-border': loading_account}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
    <modal modal-classes="modal-sm" :show.sync="modals.modal0">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Success</h5>
     </template>
     <div>
      <h4>Your request is being processed. An email will be sent to {{model.email}} shortly.</h4>
     </div>
     <template slot="footer">
         <base-button type="primary" @click="clearForm">Ok</base-button>
     </template>
   </modal>
  </card>

        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import { Select, Option, DatePicker } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import { Datetime } from 'vue-datetime';
export default {

    components: {
        BaseHeader,
        Datetime
       //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          inputRequired:true,
          s_dateCtrl:false,
          e_dateCtrl:false,
          minDatetime:'1970-01-01',
           maxDatetime:'',
        model: {
            accountNumber:'',
            endDate:'',
            startDate:'',
            email:''

        },
        modals:{
            modal0:false,
        }
      }
    },
    methods: {
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
    submit(){
        if(this.validateSelect()){

            console.log(typeof e);
            this.model.startDate=this.formatDate(String(this.model.startDate).split("T")[0]);
            this.model.endDate=this.formatDate(String(this.model.endDate).split("T")[0]);
            this.model.supportEmail= "";
this.model.supportPhone= "";
this.model.bankCode= this.userInformation.userBankCode;
this.model.bankName= "";
this.model.color= "";
this.model.logo= "";
            console.log(JSON.stringify(this.model));
            this.$store.dispatch("accountStatementRequest",this.model, { root: false });
        }
    },
    formatDate(date){
          return date.split('-')[2]+'-'+this.convert(date.split('-')[1])+'-'+date.split('-')[0];
      },
      convert(str){
          var res ='';
          switch (str) {
              case '01':
              case '1':
                  res = 'JAN';
                  break;
              case '02':
              case '2':
                  res = 'FEB';
                  break;
              case '03':
              case '3':
                  res = 'MAR';
                  break;
              case '04':
              case '4':
                  res = 'APR';
                  break;
              case '05':
              case '5':
                  res = 'MAY';
                  break;
             case '06':
            case '6':
                  res = 'JUN';
                  break;
            case '07':
              case '7':
                  res = 'JUL';
                  break;
            case '08':
            case '8':
                  res = 'AUG';
                  break;
            case '09':
            case '9':
                  res = 'SEP';
                  break;
            case '10':
                  res = 'OCT';
                  break;
            case '11':
                  res = 'NOV';
                  break;
            case '12':
                  res = 'DEC';
                  break;
              default:
                  res=str;
                  break;
          }
          return res;
      },


      validateSelect(){
         // this.imageValidator();
         console.log(JSON.stringify(this.model.startDate));
          if(JSON.stringify(this.model.startDate).length === 0){
              this.s_dateCtrl=true;
          }
          else{
              this.s_dateCtrl=false;
          }
          if(JSON.stringify(this.model.endDate).length === 0){
              this.e_dateCtrl=true;
          }
          else{
              this.e_dateCtrl=false;
          }

          return !this.e_dateCtrl && !this.s_dateCtrl;
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      clearForm(){
        this.model={
            accountNumber:'',
            endDate:'',
            startDate:'',
            email:''
         };
         this.inputRequired=false;
         this.modals.modal0=false;
      }
    },
    watch:{
success_account(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        //this.notifyVue("success", `${newValue}`);
        this.modals.modal0=true;
        //successMsg = newValue;

      }
    },
error_account(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;

      }
    },

    },
   mounted:function (){
    var dateObj = new Date();
var month = (String(dateObj.getUTCMonth() + 1).length===1)?'0'+(dateObj.getUTCMonth() + 1):(dateObj.getUTCMonth() + 1); //months from 1-12
var day = String(dateObj.getUTCDate().length===1)?'0'+dateObj.getUTCDate():dateObj.getUTCDate();
var year = dateObj.getUTCFullYear();
var newdate = year + "-" + month + "-" + day;
this.maxDatetime=newdate;

    },
    computed:{//
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    loading_account: state => state.account.account_loading,
    success_account: state => state.account.account_success,error_account: state => state.account.account_errors,

    }),
    ...mapActions(['accountStatementRequest']),
    ...mapGetters([""]),

    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}
</style>
