<template>
  <div class="content">
    <notifications></notifications>
    <div class="">
      <div>
        <card
          class="no-border-card"
          body-classes="px-3 pb-1"
          footer-classes="pb-2"
        >
          <div>
            <div v-if="Object.keys(response).length !== 0">
              <div class="form-body">
                <div class="table-section col-12">
                  <div class="m-2 top-area">
                    <div class="search-bar">
                      <input
                        type="search"
                        class="search"
                        placeholder="Search.."
                        v-model="filter"
                        name="search"
                        required
                      />
                      <div class="search-icon">
                        <img
                          src="@/assets/search-bar.jpeg"
                          alt=""
                          class=""
                          outline
                          :disabled="loading"
                          @click="querySingleUser()"
                        /><span :class="{ 'spinner-border': loading }"></span>
                      </div>
                    </div>
                    <div class="child-nav">
                      <div class="btn-nav">
                        <select class="sel-opt" v-model="perPage">
                          <option
                            v-for="option in [
                              { label: '10', value: 10 },
                              { label: '50', value: 50 },
                              { label: '75', value: 75 },
                              { label: '100', value: 100 },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                          ></option>
                        </select>
                        <span class="page">Per page</span>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-7 col-12">
                        <div class="text-right">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="items.length"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row mb-2">
                            <label for="search" class="form-control-label">Search</label>
                                    <div class="input-group">
                              <input type="text" v-model="filter" placeholder="Search ..." class="form-control" name="search">
                              <div class="input-group-append">
                                <base-button size="md" outline :disabled="loading" @click="querySingleUser()"  class="btn btn-primary"><i class="fas fa-search"></i>Search More  <span :class="{'spinner-border': loading}"></span></base-button>
                              </div>
                            </div>
                        </div> -->

                  <!-- <div class="row my-1 mx--4">
                          <div class="col-lg-6 col-md-6 col-sm-5 col-12">
                                  <el-select class="select-danger" filterable
                                      placeholder=""
                                      v-model="perPage">
                              <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
                                        class="select-danger"
                                        :value="option.value"
                                        :label="option.label"
                                        :key="option.value">
                              </el-option>
                            </el-select>
                            <label class="form-control-label ml-1" for="">Per Page</label>
                       </div>


                        <div class="col-lg-6 col-md-6 col-sm-7 col-12">
                          <div class="text-right">
                            <b-pagination
                              v-model="currentPage"
                              :total-rows="items.length"
                              :per-page="perPage"
                              align="fill"
                              size="sm"
                              class="my-0"
                            ></b-pagination>
                            </div>
                        </div>
                        </div> -->

                  <!-- Main table element -->
                  <div>
                    <b-table
                      striped
                      hover
                      :items="items"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :head-variant="light"
                      stacked="md"
                      show-empty
                      small
                      @filtered="onFiltered"
                    >
                      <!--<template #cell(privileges)="row">
        <div class="pointer" @click="modalsDisplay(row.item, row.index)">
          <i class="ni ni-zoom-split-in"></i>
          <span class="ml-1">Info</span>


        </div>
      </template>-->
                      <!-- <template #cell(name)="data">
                        <span
                          v-for="n in data.value.toString().split(' ')"
                          :key="n"
                        >
                          <span>{{ n }}</span>
                          <br />
                        </span>
                      </template> -->

                      <template #cell(status)="row">
                        <!-- {{ row.value }} {{ row.value}}{{ row.value}} -->
                        <template>
                          <badge class="badge-dot mr-4" type="">
                            <div v-if="row.value === 'ACTIVE'">
                              <badge type="success">Active</badge>
                            </div>
                            <div v-else>
                              <badge type="danger">Pending</badge>
                            </div>
                            <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                          </badge>
                        </template>
                      </template>

                      <template #cell(actions)="row">
                        <!--<b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
          Info modal
        </b-button>
        <b-button size="sm" @click="row.toggleDetails">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>-->
                        <template>
                          <el-dropdown trigger="click" class="dropdown">
                            <span class="btn btn-sm btn-icon-only text-dark">
                              <i class="fa fa-ellipsis-v mt-2"></i>
                              <!--<i class="ni ni-settings"></i>-->
                            </span>
                            <el-dropdown-menu
                              class="dropdown-menu dropdown-menu-arrow show"
                              slot="dropdown"
                            >
                              <a
                                class="dropdown-item"
                                @click="resetPasswordModal(row.item, row.index)"
                              >
                                <!-- <i class="far fa-edit"></i>-->
                                <!--<i class="fas fa-user-edit"></i>-->
                                <i class="ni ni-lock-circle-open"></i>
                                Reset Password</a
                              >
                              <a
                                class="dropdown-item"
                                @click="editUser(row.item, row.index)"
                              >
                                <!-- <i class="far fa-edit"></i>-->
                                <i class="fa fa-user-edit"></i>
                                <!--<i class="ni ni-ruler-pencil"></i>-->
                                Edit</a
                              >
                              <!--<a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>-->
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </template>

                      <template #row-details="row">
                        <b-card>
                          <ul>
                            <li v-for="(value, key) in row.item" :key="key">
                              {{ key }}: {{ value }}
                            </li>
                          </ul>
                        </b-card>
                      </template>
                    </b-table>
                  </div>

                  <modal1 modal-classes="modal-sm" :show.sync="modals.modal0">
                    <template slot="header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Confirmation
                      </h5>
                    </template>
                    <div>
                      <h4>
                        Are you sure you want to Reset this user's Password?
                      </h4>
                    </div>
                    <template slot="footer">
                      <base-button
                        type="secondary"
                        @click="modals.modal0 = false"
                        >No</base-button
                      >
                      <base-button type="primary" @click="resetPassword"
                        >Yes</base-button
                      >
                    </template>
                  </modal1>
                  <modal modal-classes="modal-sm" :show.sync="modals.modal1">
                    <template slot="header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </template>
                    <div>
                      <h3 class="text-center">Success</h3>
                      <h5 class="text-center">{{ sucess_reset }}</h5>
                    </div>
                    <template slot="footer">
                      <!--<base-button type="secondary" @click="modals.modal0 = false">No</base-button>-->
                      <base-button type="primary" @click="modals.modal1 = false"
                        >Close</base-button
                      >
                    </template>
                  </modal>

                  <modal1
                    :show="modals.modal3"
                    body-classes="p-1"
                    modal-classes="modal-dialog-centered modal-lg"
                  >
                    <!--<div class="card">-->
                    <!-- Card header -->
                    <div class="card-head">
                      <!-- Title -->
                      <!--<div class="row">-->
                      <!--<div class="col-12">-->
                      <article class="item-right">
                        <!-- @click="displayDetail()">-->
                        <a
                          ><span @click="modals.modal3 = false"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              style="fill: #171717"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              />
                            </svg> </span
                        ></a>
                      </article>
                      <!--</div>-->
                      <div class="py-2">
                        <h5 class="h3 mb-0 text-center">Edit User</h5>
                      </div>

                      <!-- </div>-->
                    </div>
                    <!-- Card body -->
                    <div class="card-body">
                      <div>
                        <form>
                          <div class="row mt-0">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="User Firstname"
                                name="User First Name"
                                placeholder="User First Name"
                                v-model="model.userFirstName"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="User Middlename"
                                name="User Middle Name"
                                placeholder="User Middle Name"
                                v-model="model.userMiddleName"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="User Lastname"
                                name="User Last Name"
                                placeholder="User Last Name"
                                v-model="model.userLastName"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="Address Line One"
                                name="Address Line One"
                                placeholder="Address Line One"
                                v-model="model.addressLineOne"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="Address Line Two"
                                name="Address Line Two"
                                placeholder="Address Line Two"
                                v-model="model.addressLineTwo"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="Street"
                                name="Street"
                                placeholder="Street"
                                v-model="model.addressStreet"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="City"
                                name="City"
                                placeholder="City"
                                v-model="model.addressCity"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                              <base-input
                                label="State"
                                name="State"
                                placeholder="State"
                                v-model="model.addressState"
                              >
                              </base-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                              <base-input label="Country">
                                <el-select
                                  class="select-danger"
                                  filterable
                                  placeholder="Country"
                                  v-model="model.addressCountry"
                                >
                                  <el-option
                                    v-for="option in option_country"
                                    class="select-danger"
                                    :value="option.value"
                                    :label="option.label"
                                    :key="option.value"
                                  >
                                  </el-option>
                                </el-select>
                                <p
                                  v-show="countryCtrl"
                                  class="form-error-message"
                                >
                                  The Role Name is required
                                </p>
                              </base-input>
                            </div>
                          </div>
                          <div class="text-right">
                            <base-button
                              :class="{ disabled: loading }"
                              icon
                              type="primary"
                              @click="submitEdit"
                            >
                              <span class="btn-inner--text">Submit</span>
                              <span
                                :class="{ 'spinner-border': loading }"
                              ></span>
                            </base-button>
                            <!--<base-button icon type="primary" native-type="submit">Submit</base-button>-->
                          </div>
                        </form>
                      </div>
                    </div>
                    <!--<div v-else>
                      <LoadingPanel></LoadingPanel>
                    </div>-->
                  </modal1>
                </div>
              </div>
            </div>
            <div v-else>
              <LoadingPanel></LoadingPanel>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from "@/components/LoadingPanel";
import Loader from "@/components/Loader";
import country from "@/components/country";

export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    LoadingPanel,
    //Loader
  },
  data() {
    return {
      light: "light",
      reset_resp: "",
      countryCtrl: false,
      model: {
        userFirstName: "",
        userMiddleName: "",
        userLastName: "",
        userId: "",
        userPhone: "",
        userEmail: "",
        userRoleId: "",
        userMaker: "",
        userBranchCode: "",
        userBankCode: "",
        addressCountry: "",
        addressState: "",
        addressCity: "",
        addressStreet: "",
        addressLineOne: "",
        addressLineTwo: "",
        addressLocation: "6.520978, 3.383103",
        userCountryCode: "234",
        source: "WEB",
      },
      modals: {
        modal3: false,
        modal0: false,
        modal1: false,
      },
      items: [],
      fields: [
        { key: "sn", label: "S/N", sortable: true, sortDirection: "desc" },
        { key: "userId", label: "ID", sortable: true, class: "text-left" },
        { key: "name", label: "Name", sortable: true, class: "text-left" },
        {
          key: "roleName",
          label: "Role Name",
          sortable: true,
          class: "text-left",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          class: "text-left",
          //sortByFormatted: true,
          //filterByFormatted: true
        },
        {
          key: "phoneNumber",
          label: "Phone Number",
          sortable: true,
          class: "text-left",
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
          class: "text-left",
        },
        { key: "status", label: "Status", sortable: true, class: "text-left" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 75, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    ...mapState({
      errors: (state) => state.user.user_error,
      loading: (state) => state.user.user_loading,
      success: (state) => state.user.user_success,
      userInformation: (state) => state.auth.userInfo,
      response: (state) => state.user.user_response,
      response_single: (state) => state.user.user_single_read,
      error_reset: (state) => state.user.user_reset_error,
      sucess_reset: (state) => state.user.user_reset_success,
      //response_single:state => state.user.user_single,
      //r_user: state => state.user.users_reset,
      reset_loader: (state) => state.user.user_loader,
    }),
    ...mapActions(["userRead", "userUpdate", "resetUser"]),
    ...mapGetters([""]),
  },
  /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
  methods: {
    querySingleUser() {
      if (
        this.filter !== null &&
        this.filter !== "" &&
        this.filter.length !== 0
      ) {
        if (this.filter.includes("@")) {
          console.log("Email");
          this.$store.dispatch(
            "userReadSingle",
            {
              userBankCode: this.userInformation.userBankCode,
              username: this.filter,
              userCountryCode: 234,
              source: "WEB",
            },
            { root: false }
          );
        } else {
          console.log("ID");
          this.$store.dispatch(
            "userReadSingleById",
            {
              userBankCode: this.userInformation.userBankCode,
              userId: this.filter,
              source: "WEB",
            },
            { root: false }
          );
        }
      } else {
        this.notifyVue("danger", "Enter a role ID");
      }
    },

    closeDialog() {
      consle.log("Heloollll");
    },
    resetPasswordModal(item, index) {
      this.modals.modal0 = true;
      this.model.username = item.username;
      //alert(this.model.username);
    },
    resetPassword() {
      let user_obj = this.response.data.find(
        (obj) => obj.username === this.model.username
      );
      this.model.userBankCode = user_obj.userBankCode;
      this.$store.dispatch(
        "resetUser",
        {
          userBankCode: this.model.userBankCode,
          username: this.model.username,
          userCountryCode: "234",
          source: "WEB",
        },
        { root: false }
      );
      this.modals.modal0 = false;
    },

    editUser(item, index) {
      this.modals.modal3 = true;
      console.log(`>>???${JSON.stringify(item)}`);
      this.model.userFirstName = item.name.split(" ")[0];
      this.model.userMiddleName = item.name.split(" ")[1];
      this.model.userLastName = item.name.split(" ")[2];
      this.model.userPhone = item.phoneNumber;
      this.model.userEmail = item.email;
      this.model.userId = item.userId;
      let user_obj = this.response.data.find(
        (obj) => obj.userId === item.userId
      );
      console.log(`>>???${JSON.stringify(user_obj)}`);
      this.model.userRoleId = user_obj.userRoleId;
      this.model.userMaker = user_obj.userMaker;
      this.model.userBankCode = user_obj.userBankCode;
      this.model.userBranchCode = user_obj.userBranchCode;
      this.model.addressLocation = "6.520978, 3.383103";
      this.model.userCountryCode = "234";
      this.model.source = "WEB";
    },
    async submitEdit() {
      this.$store.dispatch("userUpdate", this.model, { root: false });

      /*{
          roleBankCode:roleBankCode,
          roleId:roleId
        }*/
    },
    capitalizeWord(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    info(item, index) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    callapi() {
      //console.log(`<<<>>> ${this.userInformation.role.roleBankCode}`);
      this.$store.dispatch(
        "roleRead",
        {
          readAll: "YES",
          roleBankCode: "00004",
        },
        { root: false }
      );
    },
  },

  watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    error_reset(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);
      if(newValue !== null){
          this.modals.modal3 = false;
      }
      // Do whatever makes sense now
      // if (newValue !== null) {
      //   this.notifyVue("success", `${newValue}`);
      //   this.modals.modal3 = false;
      //   //this.showDismissibleAlert=true;
      // }
    },
    sucess_reset(newValue, oldValue) {
      console.log(`%%%%%%Updating from ${oldValue} to ${newValue}`);
      if (newValue !== null) {
        this.reset_resp = newValue;
        this.modals.modal1 = true;
      }
    },
    response_single(newValue, oldValue) {
      console.log("Response Query " + this.response_single);
      if (Object.keys(this.response_single).length !== 0) {
        if ("userId" in this.response_single) {
          var arr = [];
          // data = this.response.data;
          arr.push(this.response_single);
          //console.log("&$$$$$&"+this.response.data);
          var count = 1;
          this.items = arr.map((item) => {
            return {
              sn: count++,
              userId: item.userId,
              name: `${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
              roleName: item.roleName,
              email: item.userEmail,
              phoneNumber: item.userPhone,
              username: item.username,
              status: item.userStatus,
            };
          });
        } else {
          this.items = [];
          //this.initialLoading = true;
        }
      } else {
        this.items = [];
        //this.initialLoading = true;
      }
    },
    response(newValue, oldValue) {
      console.log("<><<><><><" + this.response.data);
      if (Object.keys(this.response).length !== 0) {
        if ("data" in this.response) {
          var data = [];
          data = this.response.data;
          console.log("&$$$$$&" + this.response.data);
          var count = 1;
          this.items = data.map((item) => {
            return {
              sn: count++,
              userId: item.userId,
              name: `${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
              roleName: item.roleName,
              email: item.userEmail,
              phoneNumber: item.userPhone,
              username: item.username,
              status: item.userStatus,
            };
          });
        } else {
          this.items = [];
          //this.initialLoading = true;
        }
      } else {
        this.items = [];
        //this.initialLoading = true;
      }
    },
  },
  updated() {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
  },
  created: function () {
    //this.$store.dispatch("roleRead", {
    //   readAll: "YES",
    // roleBankCode:"00004"} , { root: false });
    //this.$store.dispatch("privilageRead", {
    //     readAll: "YES"} , { root: false });
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data[0].privilageModuleName);
  },
  //beforeRouteEnter(to, from, next) {
  //ledgerBalance((err, balance) => {
  //next(vm => vm.callapi());

  // },
  mounted: function () {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data);
    if ("data" in this.response) {
      var data = [];
      data = this.response.data;
      console.log("&$$$$$&" + this.response.data);
      var count = 1;
      this.items = data.map((item) => {
        return {
          sn: count++,
          userId: item.userId,
          name: `${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
          roleName: item.roleName,
          email: item.userEmail,
          phoneNumber: item.userPhone,
          username: item.username,
          status: item.userStatus,
        };
      });
    }

    let countries = country;
    this.option_country = countries.map((item) => {
      return {
        value: `${item.name}`,
        label: `${item.name}`,
      };
    });

    this.$store.dispatch(
      "userRead",
      {
        readAll: "YES",
        userBankCode: this.userInformation.userBankCode,
        source: "WEB",
      },
      { root: false }
    );
    //this.data = this.response;
    //console.log(`All length ${JSON.stringify(this.data)}`);
    // Set the initial number of items
  },
};
</script>
<style lang="css" scoped>
.top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  display: flex;
  justify-content: center;
  width: 20%;
}
.nav-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.child-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
}
.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.nav-btn {
  width: 35px;
  height: 35px;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.num-dis {
  width: 35px;
  height: 35px;

  background: #175d85;
  border: 1px solid #175d85;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.sel-opt {
  width: 28%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.sel-opt:focus {
  outline: none;
}

.page {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #175d85;
  margin: 10px;
}

.search-bar {
  width: 287px;
  height: 5vh;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search {
  background: rgba(196, 196, 196, 0.11);
  border: none;
  box-sizing: border-box;
  height: 5vh;
  width: 100%;
  padding: 10px;
}

.search:focus {
  outline: none;
}


::placeholder {
  color: rgba(23, 93, 133, 0.26);
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}


.table-section {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  color: #6666 !important;
  font-weight: 900 !important;
}

.table {
  width: 100%;
  /* margin-bottom: 1rem; */
  color: #0000 !important;
  font-weight: 500;
}
/* .table-section tbody {
  max-width: 500px;
  overflow-x: scroll;
  color: #000 !important;
} */
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.text-title {
  font-size: 0.875rem;
  font-weight: 600;
}
.item-right {
  padding-left: 90%;
  cursor: pointer;
  margin: 0px 0% 5px 0px;
  float: right !important;
}


.view-more-customer-info p {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}
.pointer {
  cursor: pointer;
}
.queue-value {
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 20px;
}
.queue-title {
  margin-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #9e9e9e;
}
.left-pane {
  overflow-y: scroll;
  display: inline-block;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  width: 18%;
  height: 100%;
  background-color: #ffffff;
}

.ui-modal__mask {
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 50;
}
.ui-modal {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 0.875rem;
}
.ui-modal__mask {
  background-color: rgba(0, 0, 0, 0.35) !important;
}
.ui-modal.is-aligned-top .ui-modal__wrapper {
  vertical-align: initial;
}
.ui-modal__wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow-x: hidden;
  text-align: center;
}
.ui-modal--size-small > .ui-modal_wrapper > .ui-modal_container {
  width: 20rem;
}
.ui-modal__container {
  background-color: #fff;
  border-radius: 0.125rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  display: inline-block;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 100vw;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-align: initial;
  transition: all 0.3s ease;
  width: 33rem;
}
.ui-modal__container {
  background-color: transparent !important;
  box-shadow: none !important;
}
.ui-focus-container__focus-redirector,
.ui-focus-container__last-focusable {
  opacity: 0;
  position: absolute;
}
.ui-focus-container__content {
  outline: none;
}
.ui-modal:not(.has-footer) .ui-modal__body {
  padding: 1rem 1.5rem 1.5rem;
}
.ui-modal:not(.has-footer) .ui-modal__body {
  padding: 0 !important;
}
.ui-modal__body {
  max-height: calc(100vh - 3.5rem);
  overflow-y: auto;
  padding: 1rem 1.5rem;
}
.notification-modal.sm .head {
  display: block;
  position: relative;
  width: 100%;
  height: 27px;
  padding: 0 41%;
}
.notification-modal.sm .bottom {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  overflow-x: hidden;
}
.notification-modal.sm .bottom .modal-body {
  padding: 25px 0 15px 0;
}
.modal-body.history {
  display: block;
  padding: 10px 0 15px 0 !important;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.item-right {
  padding-left: 100%;
  cursor: pointer;
  margin: 0px 0% -10px 0px;
  float: right !important;
}
.card-head:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-head {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table-btn {
  margin-top: 2rem !important;
}


active.page-link{
  background-color: #1da1f2 !important;
}

</style>
