<template>
  <div>
    <notifications></notifications>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <i @click="goBack()" class="mr-2 pointer fa fa-arrow-left"></i>
          <h6 class="h2 d-inline-block mb-0">
            Create {{ selectedProduct(response_product_select) }} Product
          </h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div v-if="product_data.length !== 0">
            <card>
              <div v-if="response_product_select==='AE'">
                <form
                  id="createAccountEntry"
                  @submit.prevent="createAccountingEntry"
                >
                  <!------------------------------Line1-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <label class="form-control-label">Account Entry Code</label>
                      <input class="form-control" required name="Account Entry Description"
                             placeholder="Account Entry Code"
                             v-model="model.entryCode"  >
                      <!-- <base-input
                         label="Account Entry Code"
                         name="Account Entry Code"
                         placeholder="Account Entry Code"
                         required
                         v-model="model.entryCode"
                       >
                       </base-input>-->
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                      <label class="form-control-label">Account Entry Description</label>
                      <input class="form-control" required name="Account Entry Description"
                             placeholder="Account Entry Description"
                             v-model="model.entryDescription"  >
                      <!--<base-input
                        label="Account Entry Description"
                        name="Account Entry Description"
                        placeholder="Account Entry Description"
                        required
                        v-model="model.entryDescription"
                      >
                      </base-input>-->
                    </div>
                  </div>
                  <!------------------------------End of Line1-------------------------------->

                  <!------------------------------Line6-------------------------------->

                  <!------------------------------End of Line6-------------------------------->

                  <div class="text-right">
                    <base-button
                      icon
                      class="btn btn-dark btn-dark-outline"
                      @click="addNewSlab"
                    >
                      <i :style="{color:'white'}" class="fas fa-plus"></i>
                      Add Slab
                    </base-button>

                    <base-button
                      icon
                      class="btn btn-dark btn-dark-outline"
                      native-type="submit"
                      :class="{ disabled: loading_product }"
                    >
                      Next
                      <span :class="{ 'spinner-border': loading_product }"></span>
                    </base-button>
                    <!--<button
                      class="btn btn-dark btn-dark-outline"
                      native-type="submit"
                      :class="{ disabled: loading_product }"
                    >
                      Next
                    </button>-->
                    <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
                  </div>
                  <div class="text-center mt-3">
                    <span class="form-control-label">Account Entry</span>
                  </div>
                  <div id="aspect-content">
                    <div v-for="(slab) in entry"
                         class="aspect-tab ">
                      <input :id="slab.slabCode" :value="slab.slabCode" v-model="checkedSlabCode" type="checkbox" class="aspect-input" name="aspect">

                      <label :for="slab.slabCode" class="aspect-label"></label>
                      <div class="aspect-content">
                        <div class="aspect-info">
                          <div class="d-flex">
                            <article class="item-left my-auto ml-2">
                              <a><span @click="deleteNewSlab(slab.slabCode)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
                   </span></a>
                            </article>
                            <span class="aspect-name my-auto">{{slab.slabCode}}</span>
                          </div>
                        </div>
                        <div class="aspect-stat">

                          <div>

                            <!--<img @click="addNewSlab" :style="{'z-index':'999999'}" src="https://img.icons8.com/plasticine/20/000000/filled-trash.png"/>-->
                          </div>
                        </div>
                      </div>
                      <div class="aspect-tab-content">
                        <div class="">
                          <div>
                            <div>
                              <assign-entry @sendSlabEntry="receiveSlabEntry"
                                            :entry-ae-code="model.entryCode"
                                            :entryDescription="model.entryDescription"
                                            :slab-code="slab.slabCode"></assign-entry>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>


                </form>
              </div>
              <!-- Card header -->
              <!--<h3 slot="header" class="mb-0">Select Product Type</h3>-->
              <div v-else>
                <form
                  ref="createForm"
                  id="createProductForm"
                  @submit.prevent="submited"
                  action= "submited()"
                >
                  <div class="row">
                    <!------------------------------Line 1-------------------------------------->
                    <div
                      v-for="(prod, templateSequence) in product_data"
                      :key="templateSequence"
                      class="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                      <div class="form-group">
                        <label class="form-control-label" :style="{'display':(prod.templateLabel=='Code'||prod.templateLabel=='Description')?'none':''}">
                          {{ prod.templateLabel }}</label
                        >
                        <div class="has-label">
                          <div v-if="prod.templateDataType === 'NUMBER'">
                            <input
                              type="number"
                              :name="prod.templateDescription"
                              :placeholder="prod.templateLabel"
                              class="form-control"
                              required
                            />
                          </div>
                          <div v-else-if="prod.templateDataType === 'FLOAT'">
                            <input
                              type="number"
                              step="0.01"
                              :name="prod.templateDescription"
                              :placeholder="prod.templateLabel"
                              class="form-control"
                              required
                            />
                          </div>
                          <div
                            v-else-if="
                            prod.templateDataType === 'STRING' &&
                            prod.templateLabel === 'Code'
                          "
                          >
                            <input
                              type="text"
                              :name="prod.templateDescription"
                              :placeholder="prod.templateLabel"
                              class="form-control"
                              required
                              :style="{display: 'none'}"
                              :value="getallproductdata.code"
                            />
                          </div>
                          <div
                            v-else-if="
                            prod.templateDataType === 'STRING' &&
                            prod.templateLabel === 'Description'
                          "
                          >
                            <input
                              type="text"
                              :name="prod.templateDescription"
                              :placeholder="prod.templateLabel"
                              class="form-control"
                              required
                              :style="{display: 'none'}"
                              :value="getallproductdata.description"
                            />
                          </div>
                          <div v-else-if="prod.templateDataType === 'STRING'">
                            <input
                              type="text"
                              :name="prod.templateDescription"
                              :placeholder="prod.templateLabel"
                              class="form-control"
                              required
                              id="description"
                              v-model="description"
                            />
                          </div>
                          <div v-else>
                            <select
                              class="form-control"
                              :placeholder="prod.templateLabel"
                              :name="prod.templateDescription"
                              required
                            >
                              <!-- <option>Select {{ prod.templateLabel }}</option> -->
                              <option
                                v-for="(item, index) in selectOption(
                                prod.templateDescription
                              )"
                                :key="index"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!----><!---->
                      </div>
                    </div>
                    <div
                      id="error"
                      class="m-3 p-2 shadow text-center text-danger"
                    ></div>

                    <!------------------------------End of Line 1-------------------------------------->
                  </div>

                  <div class="text-right">
                    <base-button
                      icon
                      class="btn btn-dark btn-dark-outline"
                      native-type="submit"
                      :class="{ disabled: loading_product }"
                    >
                      {{(response_product_select==='FT'?'Submit':'Next')}}
                      <span :class="{ 'spinner-border': loading_product }"></span>
                    </base-button>

                    <!--<base-button
                      icon
                      type="primary"
                      native-type="submit"
                      :class="{ disabled: loading_product }"
                    >
                      <span class="btn-inner--text">Create Account Entry</span>
                      <span :class="{ 'spinner-border': loading_product }"></span>
                    </base-button>-->
                    <!-- <base-button icon type="primary" native-type="submit" >Create {{selectedProduct(response_product_select)}} Product
            </base-button> -->
                  </div>
                </form>
              </div>
              <div id="error"></div>
            </card>
          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
    </div>
    <modal modal-classes="modal-sm" :show.sync="modal.modal0">
      <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Confirm</h5>
      </template>
      <div>
        <form id="form_reason" @submit.prevent="SubmitConfirm">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <span class="form-control-label">{{ msg }}</span>
            </div>
          </div>
        </form>
      </div>
      <template slot="footer">
        <base-button type="secondary" @click="modal.modal0 = false">No</base-button>
        <base-button type="primary" native-type="submit" form="form_reason" >Yes </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import { Select, Option, DatePicker } from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from "@/components/LoadingPanel";
import AssignEntry from './AssignEntry'
export default {
  name:'EditProduct',
  components: {
    BaseHeader,
    LoadingPanel,
    AssignEntry
    //vSelect,
    //[Select.name]: Select,
    //[Option.name]: Option
  },
  data() {
    return {
      slabEntries:[],
      checkedSlabCode:[],
      msg:'',
      deleteSlabName:'',
      modal:{
        modal0:false
      },
      entry:[{
        slabCode:'SlabCode1'
      }],
      entryCounter:1,
      chargeCtrl:false,
      option_product_ae: [],
      inputRequired: true,
      model: {
        //chargeProduct: "",
        entryCode: "",
        entryDescription: "",
      },
      option_product: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "NO" },
      ],
      option_products: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "NO" },
      ],
      selected: "",
      edit: "phone",
      product_data: [],
      products: [],

      form:{
        name: null
      },
    };
  },

  mounted: function () {
    this.product_data=[];
    console.log(`select ${this.response_product_select}`);
    console.log("producet>>>> "+JSON.stringify(this.response_product));
    if (Object.keys(this.response_product).length !== 0) {
      this.product_data = this.response_product.data;
    };

    /* this.$store.dispatch(
       "readProduct",
       {
         productBankCode: this.userInformation.userBankCode,
         productType: "CH",
       },
       { root: false }
     );*/
    this.submited
  },


  methods: {
    receiveSlabEntry(payload){

      for( let i = 0; i < this.checkedSlabCode.length; i++){

        if ( this.checkedSlabCode[i] === payload.slabCode) {
          this.checkedSlabCode.splice(i, 1);
          i--;
        }
      }

      for( let i = 0; i < this.slabEntries.length; i++){

        if ( this.slabEntries[i].slabCode === payload.slabCode) {
          this.slabEntries.splice(i, 1);
          i--;
        }
      }

      this.slabEntries.push(payload);
      console.log("Received Slabsss "+JSON.stringify(this.slabEntries));
    },

    SubmitConfirm(){
      if(this.msg.includes("delete")){
        // this.entryCounter--;
        for( let i = 0; i < this.entry.length; i++){

          if ( this.entry[i].slabCode === this.deleteSlabName) {
            this.entry.splice(i, 1);
            i--;
          }
        }
        for( let i = 0; i < this.slabEntries.length; i++){

          if ( this.slabEntries[i].slabCode === this.deleteSlabName) {
            this.slabEntries.splice(i, 1);
            i--;
          }
        }
      }
      console.log("Received Slabsss after delete>> "+JSON.stringify(this.slabEntries));
      this.modal.modal0=false;
    },

    deleteNewSlab(slab){
      //this.entry++;
      if(this.entry.length>1) {
        this.msg = "Are you sure you wan to delete this slab"
        this.modal.modal0 = true;
        this.deleteSlabName = slab;

      }

    },

    addNewSlab(){

      //this.msg = "Are you sure you wan to Add a slab"
      this.entryCounter++;
      console.log(this.entryCounter+" >>>???");
      this.entry.push({slabCode: 'SlabCode'+this.entryCounter})
      //this.modal.modal0=true;
    },
    createAccountingEntry() {
      //if (this.validateSelect) {
      /*this.$store.dispatch(
        "createAccountingEntry",
        {
          aeBankCode: this.userInformation.userBankCode,
          aeChargeCode: this.model.chargeProduct,
          aeCode: this.model.entryCode,
          aeDescription: this.model.entryDescription,
          aeMaker: this.userInformation.userId,
          source: "web",
        },
        { root: false }
      );*/
      if(this.slabEntries.length>0){
        this.products.push({
          aeBankCode: this.userInformation.userBankCode,
          aeChargeCode: this.model.entryCode,
          aeCode: this.model.entryCode,
          aeDescription: this.model.entryDescription,
          aeMaker: this.userInformation.userId,
          source: "web",
          bankcode: this.userInformation.userBankCode,
          productname: this.response_product_select,
        });
        this.$store.dispatch(
          "productsMenu",
          {
            products:this.products,
            slabs:this.slabEntries
          },
          { root: false }
        );
        document.getElementById("createAccountEntry").reset();
        console.log("Final product>>>>>>>> "+JSON.stringify({
          products:this.products,
          slabs:this.slabEntries
        }));

        this.products=[];
      }
      else{
        this.notifyVue("danger","Enter aleast one slab");
      }
    },

    validateSelect() {
      // this.imageValidator();
      this.chargeCtrl = (this.model.chargeProduct === "")?true:false;
      return !this.chargeCtrl;
    },
    selectedProduct(str) {
      let product = "";
      switch (str) {
        case "AC":
          product = "Account";
          break;
        case "CH":
          product = "Charges";
          break;
        case "FT":
          product = "Fund Transfer";
          break;
        case "LO":
          product = "Loan";
          break;
        case "IN":
          product = "Interest";
          break;
        case "AE":
          product = "Account Entry";
          break;
      }
      return product;
    },

    selectOption(id) {
      let option_obj = this.product_data.find(
        (obj) => obj.templateDescription === id
      );
      let optionsList = option_obj.templateList.split(",");
      return optionsList;
    },

    notifyVue(type = "default", msg) {
      console.log(type);
      console.log(msg);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    resetForm() {
      console.log("test");
    },
    submit() {
      let formData = new FormData(this.$refs.createForm);

      for (let pair of formData.entries()) {
        this.products.push({
          bankcode: this.userInformation.userBankCode,
          productname: this.response_product_select,
          userId: this.userInformation.userId,
          key: pair[0],
          value: pair[1],
        });
      }

      console.log(this.products);
      this.$store.dispatch(
        "createProduct",
        {
          requestType: "create",
          products: this.products,
        },
        { root: false }
      );
    },

    submited(){
      let formData = new FormData(this.$refs.createForm);
      for (let pair of formData.entries()) {
        this.products.push({
          bankcode: this.userInformation.userBankCode,
          productname: this.response_product_select,
          userId: this.userInformation.userId,
          key: pair[0],
          value: pair[1],
        });
      }
      console.log(this.products);
      this.$store.dispatch(
        "productsMenu",
        {
          products: this.products,
        },
        { root: false }
      );
      this.products = [];
      console.log("Hello world");
      console.log(JSON.stringify(this.getallproductdata));
      console.log(this.getallproductdata);

    },

    getNextform() {
      this.$store.dispatch(
        "productReadTemplate",
        {
          templateBankCode: this.userInformation.userBankCode,
          templateType: prdTemplateCode,
        },
        { root: false }
      );
    },

    viewProduct(prdTemplateCode) {
      this.$store.dispatch(
        "redirectTocharges",
        {
          templateBankCode: this.userInformation.userBankCode,
          templateType: prdTemplateCode,
        },
        { root: false }
      );
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  watch: {
    response_product_ae(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if (Object.keys(this.response_product_ae).length !== 0) {
        let product = [];
        product = this.response_product_ae.data;
        this.option_product_ae = product.map((item) => {
          return {
            value: `${item.Code}`,
            label: `${item.Code} (${item.Description})`,
          };
        });
        console.log("//////////");
        //console.log("&$$$$$&"+this.option_branch);
      }
    },
    error_product(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    success_product(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        document.getElementById("createProductForm").reset();
        //this.showDismissibleAlert=true;
      }
    },

    response_product(newValue, oldValue) {
      console.log("Response Product>>> "+this.response_product);
      if (Object.keys(this.response_product).length !== 0) {
        this.product_data = this.response_product.data;
      }
    },

    /*  response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
           this.product_type = this.response_product.data;
           console.log(`Product types >>> ${JSON.stringify(this.product_type)}`);
           console.log(`Product length >>> ${this.product_type.length}`);
    }
        },*/
  },

  computed: {
    ...mapState({
      //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
      //success: state => state.role.role_success,
      response_product_select: (state) => state.product.response_product_selected,
      userInformation: (state) => state.auth.userInfo,
      response_product: (state) => state.product.product_view,
      response_product_ae: (state) => state.product.product_response,
      loading_product: (state) => state.product.product_loading,
      success_product: (state) => state.product.product_success,
      error_product: (state) => state.product.product_errors,
      getallproductdata: (state) => state.product.get_allproductdata,
    }),
    ...mapActions(["productReadTemplate"]),
    ...mapGetters([""]),
  },
};
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.form-error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  height: calc(1.5em + 1.25rem + 5px);
  line-height: 1.5;
  width: 100%;
}
.pointer {
  cursor: pointer;
}

.product-type-card {
  cursor: pointer;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  margin-bottom: 15px;
}
.product-type-card .card {
  margin-bottom: 0;
}
.product-type-card-main {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.product-type-card-sub {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}
.product-type-card-icon svg {
  height: 45px;
  margin-bottom: 15px;
}
svg {
  overflow: hidden;
}
img,
svg {
  vertical-align: middle;
}

.fa {
  color: #000000;
}



#aspect-content {
  margin: 30px 0 0;
  font-family: "Poppins", sans-serif;
}
#aspect-content * {
  box-sizing: border-box;
}
.aspect-tab {
  position: relative;
  width: 100%;
  /*max-width: 1000px;*/
  margin: 0 auto 10px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(236, 236, 236, 1);
  opacity: 1;
  transition: box-shadow 0.2s, opacity 0.4s;
}
.aspect-tab:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .11);
}
.aspect-input {
  display: none;
}
.aspect-input:checked ~ .aspect-content + .aspect-tab-content {
  max-height: 3000px;
}
.aspect-input:checked ~ .aspect-content:after {
  transform: rotate(0);
}
.aspect-label {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 80px;
  width: 90%;
  margin: 0;
  padding: 0;
  font-size: 0;
  z-index: 1;
  cursor: pointer;
}
.aspect-label:hover ~ .aspect-content:after {
  background-image: url('data:image/svg+xml; base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiM1NTZBRUEiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=');
}
.aspect-content {
  position: relative;
  display: block;
  height: 80px;
  margin: 0;
  /*padding: 0 87px 0 30px;*/
  font-size: 0;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}
.aspect-content:before, .aspect-content:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
}
.aspect-content:before {
  height: 100%;
}
.aspect-content:after {
  position: absolute;
  width: 24px;
  height: 100%;
  right: 30px;
  background-image: url('data:image/svg+xml; base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTI0IDI0SDBWMGgyNHoiIG9wYWNpdHk9Ii44NyIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNBOUFDQUYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1Ljg4IDE1LjI5TDEyIDExLjQxbC0zLjg4IDMuODhhLjk5Ni45OTYgMCAxIDEtMS40MS0xLjQxbDQuNTktNC41OWEuOTk2Ljk5NiAwIDAgMSAxLjQxIDBsNC41OSA0LjU5Yy4zOS4zOS4zOSAxLjAyIDAgMS40MS0uMzkuMzgtMS4wMy4zOS0xLjQyIDB6Ii8+CiAgICA8L2c+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
}
.aspect-name {
  display: inline-block;
  width: 75%;
  margin-left: 16px;
  font-weight: 500;
  color: #242a32;
  white-space: normal;
  text-align: left;
  vertical-align: middle;
}
.aspect-stat {
  width: 40%;
  text-align: right;
}
.all-opinions, .aspect-name {
  font-size: 14px;
  line-height: 22px;
}
.all-opinions {
  color: #5d5d5d;
  text-align: left;
}
.aspect-content + .aspect-tab-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}
.aspect-content > div, .aspect-stat > div {
  display: -webkit-inline-box;
}
.aspect-content > div {
  vertical-align: middle;
}
.positive-count, .negative-count, .neutral-count {
  display: inline-block;
  margin: 0 0 0 20px;
  padding-left: 26px;
  background-repeat: no-repeat;
  font-size: 13px;
  line-height: 20px;
  color: #363636;
}
.positive-count {
  background-image: url('data:image/svg+xml; base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiM3RUQzMjEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEwIDE4LjU3MWMtNC43MjYgMC04LjU3MS0zLjg0NS04LjU3MS04LjU3MSAwLTQuNzI2IDMuODQ1LTguNTcxIDguNTcxLTguNTcxIDQuNzI2IDAgOC41NzEgMy44NDUgOC41NzEgOC41NzEgMCA0LjcyNi0zLjg0NSA4LjU3MS04LjU3MSA4LjU3MXpNMjAgMTBjMCA1LjUxNC00LjQ4NiAxMC0xMCAxMFMwIDE1LjUxNCAwIDEwIDQuNDg2IDAgMTAgMHMxMCA0LjQ4NiAxMCAxMHpNNSAxMS40MjdhNSA1IDAgMCAwIDEwIDAgLjcxNC43MTQgMCAxIDAtMS40MjkgMCAzLjU3MSAzLjU3MSAwIDAgMS03LjE0MiAwIC43MTQuNzE0IDAgMSAwLTEuNDI5IDB6bTEuMDcxLTVhMS4wNzEgMS4wNzEgMCAxIDAgMCAyLjE0MyAxLjA3MSAxLjA3MSAwIDAgMCAwLTIuMTQzem03Ljg1OCAwYTEuMDcxIDEuMDcxIDAgMSAwIDAgMi4xNDMgMS4wNzEgMS4wNzEgMCAwIDAgMC0yLjE0M3oiLz4KPC9zdmc+Cg==');
}
.negative-count {
  background-image: url('data:image/svg+xml; base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNGRjZFMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEwIDE4LjU3MWMtNC43MjYgMC04LjU3MS0zLjg0NS04LjU3MS04LjU3MSAwLTQuNzI2IDMuODQ1LTguNTcxIDguNTcxLTguNTcxIDQuNzI2IDAgOC41NzEgMy44NDUgOC41NzEgOC41NzEgMCA0LjcyNi0zLjg0NSA4LjU3MS04LjU3MSA4LjU3MXpNMjAgMTBjMCA1LjUxNC00LjQ4NiAxMC0xMCAxMFMwIDE1LjUxNCAwIDEwIDQuNDg2IDAgMTAgMHMxMCA0LjQ4NiAxMCAxMHpNNSAxNC45OThhLjcxNC43MTQgMCAwIDAgMS40MjkgMCAzLjU3MSAzLjU3MSAwIDAgMSA3LjE0MiAwIC43MTQuNzE0IDAgMSAwIDEuNDI5IDAgNSA1IDAgMSAwLTEwIDB6bTEuMDcxLTguNTdhMS4wNzEgMS4wNzEgMCAxIDAgMCAyLjE0MiAxLjA3MSAxLjA3MSAwIDAgMCAwLTIuMTQzem03Ljg1OCAwYTEuMDcxIDEuMDcxIDAgMSAwIDAgMi4xNDIgMS4wNzEgMS4wNzEgMCAwIDAgMC0yLjE0M3oiLz4KPC9zdmc+Cg==');
}
.neutral-count {
  background-image: url('data:image/svg+xml; base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNCQUMyRDYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEwIDE4LjU3MWMtNC43MjYgMC04LjU3MS0zLjg0NS04LjU3MS04LjU3MSAwLTQuNzI2IDMuODQ1LTguNTcxIDguNTcxLTguNTcxIDQuNzI2IDAgOC41NzEgMy44NDUgOC41NzEgOC41NzEgMCA0LjcyNi0zLjg0NSA4LjU3MS04LjU3MSA4LjU3MXpNMjAgMTBjMCA1LjUxNC00LjQ4NiAxMC0xMCAxMFMwIDE1LjUxNCAwIDEwIDQuNDg2IDAgMTAgMHMxMCA0LjQ4NiAxMCAxMHpNNS43MTQgMTEuNDI3YS43MTQuNzE0IDAgMSAwIDAgMS40MjloOC41NzJhLjcxNC43MTQgMCAxIDAgMC0xLjQyOUg1LjcxNHptLjM1Ny01YTEuMDcxIDEuMDcxIDAgMSAwIDAgMi4xNDMgMS4wNzEgMS4wNzEgMCAwIDAgMC0yLjE0M3ptNy44NTggMGExLjA3MSAxLjA3MSAwIDEgMCAwIDIuMTQzIDEuMDcxIDEuMDcxIDAgMCAwIDAtMi4xNDN6Ii8+Cjwvc3ZnPgo=');
}
.aspect-info {
  width: 60%;
  white-space: nowrap;
  font-size: 0;
}
.aspect-info:before {
  content: '';
  display: inline-block;
  height: 44px;
  vertical-align: middle;
}
.chart-pie {
  position: relative;
  display: inline-block;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: #e4e4e4;
  vertical-align: middle;
}
.chart-pie:after {
  content: '';
  display: block;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  background-color: #fff;
}
.chart-pie-count {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
  color: #242a32;
  text-align: center;
  z-index: 1;
}
.chart-pie > div {
  clip: rect(0, 44px, 44px, 22px);
}
.chart-pie > div, .chart-pie.over50 .first-fill {
  position: absolute;
  height: 44px;
  width: 44px;
  border-radius: 50%;
}
.chart-pie.over50 > div {
  clip: rect(auto, auto, auto, auto);
}
.chart-pie.over50 .first-fill {
  clip: rect(0, 44px, 44px, 22px);
}
.chart-pie:not(.over50) .first-fill {
  display: none;
}
.second-fill {
  position: absolute;
  clip: rect(0, 22px, 44px, 0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  box-sizing: border-box;
}
.chart-pie.positive .first-fill {
  background-color: #82d428;
}
.chart-pie.positive .second-fill {
  border-color: #82d428;
}
.chart-pie.negative .first-fill {
  background-color: #ff6e00;
}
.chart-pie.negative .second-fill {
  border-color: #ff6e00;
}
.aspect-tab-content {
  background-color: #f9f9f9;
  font-size: 0;
  text-align: justify;
}
.sentiment-wrapper {
  padding: 24px 30px 30px;
}
.sentiment-wrapper > div {
  display: inline-block;
  width: 100%;
  /*max-width: 390px;*/
  padding: 0 5px;
  box-sizing: border-box;
  vertical-align: top;
  cursor: default;
}
.sentiment-wrapper > div > div {
  width: 100%;
  padding: 16px 24px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #ececec;
  text-align: left;
}
.opinion-header {
  position: relative;
  width: 100%;
  margin: 0 0 24px;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: #242a32;
  text-transform: capitalize;
}
.opinion-header > span:nth-child(2) {
  position: absolute;
  right: 0;
}
.opinion-header + div > span {
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  color: #363636;
}
@media screen and (max-width: 800px) {
  .aspect-label {
    max-height: 102px;
  }
  .aspect-content {
    height: auto;
    /*padding: 10px 87px 10px 30px;*/
  }
  .aspect-content:before {
    display: none;
  }
  .aspect-content:after {
    top: 0;
  }
  .aspect-content > div {
    display: block;
    width: 100%;
  }
  .aspect-stat {
    margin-top: 10px;
    text-align: left;
  }
}
@media screen and (max-width: 750px) {
  .sentiment-wrapper > div {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .sentiment-wrapper > div:not(:first-child) {
    margin-top: 10px;
  }
}
@media screen and (max-width: 500px) {
  .aspect-label {
    max-height: 140px;
  }
  .aspect-stat > div {
    display: block;
    width: 100%;
  }
  .all-opinions {
    margin-bottom: 10px;
  }
  .all-opinions + div > span:first-child {
    margin: 0;
  }
}

</style>
