<template>
  <div>
    <div class="mt-2">
      <div class="row">
        <div class="col mb--5">
          <card>
            <!-- Card header -->
            <!-- <h3 slot="header" class="mb-0">Assign Accounting Entry</h3> -->

            <!-- Card body -->

            <!-- <div class="form-body">-->
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <base-input
                  label="Slab Code"
                  name="Slab Code"
                  placeholder="SlabCode"
                  :readonly="
                    response_page === 'edit' || response_page === 'create'||response_page==='add'
                      ? true
                      : false
                  "
                  v-model="slabCode"
                >
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <base-input
                  label="Slab Maximum Amount"
                  name="Slab Maximum Amount"
                  type="number"
                  placeholder="Slab Maximum Amount"
                  required
                  v-model="slabMaxAmt"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="table-section">
                  <div class="m-2 top-area">
                    <div class="child-nav">
                      <div class="btn-nav">
                        <select class="sel-opt" v-model="perPage">
                          <option
                            v-for="option in [
                              { label: '10', value: 10 },
                              { label: '50', value: 50 },
                              { label: '75', value: 75 },
                              { label: '100', value: 100 },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                          ></option>
                        </select>
                        <span class="page">Per page</span>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-7 col-12">
                        <div class="text-right">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="items.length"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- User Interface controls -->
                  <!-- <b-row>
      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row> -->

                  <!-- Main table element -->
                  <div>
                    <b-table
                      striped
                      hover
                      :items="items"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      stacked="lg"
                      show-empty
                      large
                      head-variant="light"
                    >
                      <template #cell(entryDrCr)="row">
                        <div>
                          <div v-if="row.value === 'CR'">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              class="bi bi-arrow-up-circle-fill svg-green"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
                              />
                            </svg>
                          </div>
                          <div v-else>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              class="bi bi-arrow-down-circle-fill svg-red"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                              />
                            </svg>
                          </div>
                        </div>
                      </template>

                      <template #cell(action)="row">
                        <div
                          class="delete"
                          @click="deleteItem(row.item, row.index)"
                        >
                          <el-tooltip content="Delete" placement="left">
                            <!--<i class="ni ni-zoom-split-in"></i>
          <span class="ml-1">Info</span>-->
                            <i class="fas fa-trash-alt" style="color: red"></i>
                          </el-tooltip>
                        </div>
                      </template>

                      <template #row-details="row">
                        <b-card>
                          <ul>
                            <li v-for="(value, key) in row.item" :key="key">
                              {{ key }}: {{ value }}
                            </li>
                          </ul>
                        </b-card>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <form id="submitForm" @submit.prevent="submit">
                  <div class="row">
                    <div class="col-sm-12">
                      <base-input label="Type">
                        <el-select
                          class="select-danger"
                          clearable
                          placeholder="Type"
                          @change="onChangeType()"
                          v-model="type"
                        >
                          <el-option
                            v-for="option in [
                              { value: 'DR', label: 'Debit' },
                              { value: 'CR', label: 'Credit' },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                            required
                          >
                          </el-option>
                        </el-select>
                        <p v-show="typeCtrl" class="form-error-message">
                          The Type is required
                        </p>
                      </base-input>
                    </div>

                    <div class="col-sm-12">
                      <base-input label="Amount Type">
                        <el-select
                          class="select-danger"
                          clearable
                          placeholder="Amount Type"
                          @change="onChangeAmtType()"
                          v-model="amountType"
                        >
                          <el-option
                            v-for="option in [
                              { value: 'FLAT', label: 'FLAT' },
                              { value: 'PERCENT', label: 'PERCENT' },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                            required
                          >
                          </el-option>
                        </el-select>
                        <p v-show="amtCtrl" class="form-error-message">
                          The Amount Type is required
                        </p>
                      </base-input>
                    </div>

                    <div v-if="amountControl" class="col-sm-12 mb-3">
                      <div class="row">
                        <div class="col-sm-6 text-left">
                          <label></label>
                          <label for="Amount" class="form-control-label"
                          >Amount
                            {{ amountType === "FLAT" ? "(₦)" : "(%)" }}</label
                          >
                        </div>
                        <div class="col-sm-6">
                          <div class="checkbox-row">
                            <div class="checkbox-container">
                              <label for="save-bene" class="checkbox"
                              ><input
                                v-bind:value="false"
                                v-model="amtRadio"
                                type="checkbox"
                                id="save-bene"
                                @change="checkAmt()"
                              />
                                <div aria-hidden="true" class="checkbox-box">
                                  <div class="checkbox-outer">
                                    <div class="checkbox-inner"></div>
                                  </div>
                                </div>
                                <span class="checkbox-label"
                                >Principal Amount</span
                                ></label
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <input
                        v-if="!amtRadio"
                        type="number"
                        step="0.01"
                        v-model="amount"
                        placeholder="Amount"
                        class="form-control"
                        required
                      /><!---->
                      <input
                        v-if="amtRadio"
                        type="text"
                        v-model="amount"
                        placeholder="Amount"
                        class="form-control"
                        readonly
                      /><!---->
                    </div>

                    <div class="col-sm-12 mb-3">
                      <div class="row">
                        <div class="col-sm-6 text-left">
                          <label for="Account" class="form-control-label"
                          >Account No</label
                          >
                        </div>
                        <div class="col-sm-6">
                          <div class="checkbox-row">
                            <div class="checkbox-container">
                              <label for="save-bene1" class="checkbox"
                              ><input
                                v-bind:value="false"
                                v-model="accRadio"
                                type="checkbox"
                                id="save-bene1"
                                @change="checkAcc()"
                              />
                                <div aria-hidden="true" class="checkbox-box">
                                  <div class="checkbox-outer">
                                    <div class="checkbox-inner"></div>
                                  </div>
                                </div>
                                <span class="checkbox-label"
                                >Principal Account</span
                                ></label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        v-if="!accRadio"
                        type="number"
                        step="0.01"
                        v-model="account"
                        placeholder="Account"
                        class="form-control"
                        required
                      /><!---->
                      <input
                        v-if="accRadio"
                        type="text"
                        v-model="account"
                        placeholder="Account"
                        class="form-control"
                        readonly
                      /><!---->
                    </div>
                  </div>
                  <p class="text-right">
                    <span>
                      <button type="submit" class="btn btn-secondary mr-1">
                        Add Entry
                      </button></span
                    >
                  </p>
                </form>
              </div>

              <br />
              <br />
            </div>
            <div class="row">
              <div class="col-sm-8">
                <p class="text-right">
                  <span class="entry-total-title mr-1">Total</span>
                  <span class="entry-total"> ₦ {{ total_flat }} </span>
                  <span class="divider">~</span
                  ><span class="entry-total">{{ total_percent }}%</span>
                </p>
              </div>
              <div v-if="assignBtn" class="col-sm-4 text-right">
                <base-button
                  @click="submitAllEntry"
                  icon
                  type="primary"
                  :class="{ disabled: loading_product }"
                >
                  <span class="btn-inner--text">Assign Entry</span>
                  <span :class="{ 'spinner-border': loading_product }"></span>
                </base-button>
                <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
              </div>
            </div>
            <!--</div>-->

            <!--<b-container class="table-container">-->
            <!--class="over-flow">-->
            <!-- <b-collapse id="collapse-4" v-model="collapse_ctrl" class="mt-2">
  <card class="" body-classes="px-0 pb-1" footer-classes="pb-2">
  <div class="form-body">

  </div>
</card>
  </b-collapse>-->
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "AssignEntry",
  props:{
    slabCode:String,
    entryAeCode:String,
    entryDescription:String
  },

  data() {
    return {
      accRadio: false,
      amtRadio: false,

      typeCtrl: false,
      amtCtrl: false,
      amount: "",
      pAmount: "Principal Amount",
      amount_label: "",
      amountCtrl: false,
      amountControl: false,

      pAccount: "Principal Account",
      account: "",

      amountType: "",
      type: "",

      slabMaxAmt: "",
      //slabCode: "",

      flat: [],
      percent: [],

      total_flat: "0.00",
      total_percent: "0.00",

      assignBtn: true,
      entryId: 0,
      response_prod: {},

      items: [],
      csvContent: [],
      fields: [
        { key: "entryDrCr", label: "Type", sortable: true, class: "text-left" },
        {
          key: "entryAmount",
          label: "Amount",
          sortable: true,
          class: "text-left",
        },
        {
          key: "entryAccount",
          label: "Account Number",
          sortable: true,
          class: "text-left",
        },
        { key: "action", label: "Action" },
      ],
      sortOrders: {},
      sortKey: "",

      light: "light",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  methods: {
    onChangeType() {
      console.log(`Type >>>?? ${this.type}`);
    },
    submitAllEntry() {
      console.log("++++===="+this.response_page)
      let str =JSON.stringify(this.items);
      let item = JSON.parse(str);
      //Math.floor(Math.random()*101);
      if (this.response_page === "edit") {
        for (let i = 0; i < item.length; i++) {
          item[i].entryAccount =
            item[i].entryAccount === "Principal Account"
              ? "$ACCOUNT"
              : item[i].entryAccount;
          item[i].entryAmount =
            item[i].entryAmount === "Principal Amount"
              ? "$AMOUNT"
              : item[i].entryAmount.includes("%")
                ? item[i].entryAmount.split("%")[0]
                : item[i].entryAmount.split("₦")[1];
        }
        var payload = {
          entryAeCode: this.response_product.aeCode,
          entryBankCode: this.response_product.aeBankCode,
          entryMaker: this.response_product.aeMaker,
          slabCode: this.slabCode,
          slabMaxAmount: this.slabMaxAmt,
          entries: item,
        };
        this.$store.dispatch("submitEntries", payload, { root: false });
        console.log(`Items>>>>--- ${JSON.stringify(item)}`);
      } else if (this.response_page === "add") {
        for (let i = 0; i < item.length; i++) {
          item[i].entryAccount =
            item[i].entryAccount === "Principal Account"
              ? "$ACCOUNT"
              : item[i].entryAccount;
          item[i].entryAmount =
            item[i].entryAmount === "Principal Amount"
              ? "$AMOUNT"
              : item[i].entryAmount.includes("%")
                ? item[i].entryAmount.split("%")[0]
                : item[i].entryAmount.split("₦")[1];
        }
        if(this.entryAeCode!==''&&this.entryDescription!=='' && this.slabMaxAmt!=='') {
        let payload = {
          entryAeCode: this.entryAeCode,
          entryBankCode: this.userInformation.userBankCode,
          entryMaker: this.userInformation.userId,
          slabCode: this.slabCode,
          slabMaxAmount: this.slabMaxAmt,
          entries: item,
        };
          this.$emit('sendSlabEntry', payload);
        }
        else{
          this.notifyVue("danger","Enter Account Entry Code or Description");
        }
        console.log("Itemize???>>"+ JSON.stringify(item))
        //this.$store.dispatch("submitEntries", payload, { root: false });
      } else if (this.response_page === "create") {
        for (let i = 0; i < item.length; i++) {
          item[i].entryAccount =
            item[i].entryAccount === "Principal Account"
              ? "$ACCOUNT"
              : item[i].entryAccount;
          item[i].entryAmount =
            item[i].entryAmount === "Principal Amount"
              ? "$AMOUNT"
              : item[i].entryAmount.includes("%")
                ? item[i].entryAmount.split("%")[0]
                : item[i].entryAmount.split("₦")[1];
        }

        var payload = {
          entryAeCode: this.response_selectAcc.aeCode,
          entryBankCode: this.userInformation.userBankCode,
          entryMaker: this.userInformation.userId,
          slabCode: this.slabCode,
          slabMaxAmount: this.slabMaxAmt,
          entries: item,
        };

         // this.$emit('sendSlabEntry', payload);
        this.$store.dispatch("submitEntries", payload, { root: false });

      } else {
        this.notifyVue("danger", "An error as occurred, please try again");
      }
    },
    calculate() {},
    validate() {
      // this.imageValidator();
      if (this.amountType === "") {
        this.amtCtrl = true;
      } else {
        this.amtCtrl = false;
      }
      if (this.type === "") {
        this.typeCtrl = true;
      } else {
        this.typeCtrl = false;
      }
      return !this.amtCtrl && !this.typeCtrl;
    },
    submit() {
      if (this.validate()) {
        if (this.amountType === "FLAT") {
          if (this.amount === "Principal Amount") {
            this.items.push({
              entryDrCr: this.type,
              entryAmountType: this.amountType,
              entryAmount: `${this.amount}`,
              entryAccount: this.account,
              id: this.entryId++,
            });
            this.flat.push(this.amount);
          } else {
            this.flat.push(
              this.type === "CR" ? Number(this.amount) : -Number(this.amount)
            );
            this.items.push({
              entryDrCr: this.type,
              entryAmountType: this.amountType,
              entryAmount: `₦ ${this.amount}`,
              entryAccount: this.account,
              id: this.entryId++,
            });
            //this.flat.push(()?Number(this.amount):)
          }
        } else {
          if (this.amount === "Principal Amount") {
            this.items.push({
              entryDrCr: this.type,
              entryAmountType: this.amountType,
              entryAmount: `${this.amount}`,
              entryAccount: this.account,
              id: this.entryId++,
            });
            this.percent.push(this.amount);
          } else {
            this.percent.push(
              this.type === "CR" ? Number(this.amount) : -Number(this.amount)
            );
            this.items.push({
              entryDrCr: this.type,
              entryAmountType: this.amountType,
              entryAmount: `${this.amount} %`,
              entryAccount: this.account,
              id: this.entryId++,
            });
            //this.flat.push(()?Number(this.amount):)
          }
        }
        if (this.flat.length !== 0) {
          console.log("Flattttttttt");
          let not_num = (n) => (isNaN(n) ? 0 : n);
          this.total_flat = this.flat
            .reduce(function (a, b) {
              return not_num(a) + not_num(b);
            }, 0)
            .toFixed(2)
            .toString();
          console.log(`this.total_flat>>${this.total_flat}`);
        }
        if (this.percent.length !== 0) {
          console.log("Percentttttttt");
          let not_num = (n) => (isNaN(n) ? 0 : n);
          this.total_percent = this.percent
            .reduce(function (a, b) {
              return not_num(a) + not_num(b);
            }, 0)
            .toFixed(2)
            .toString();
          console.log(`this.total_percent>>${this.total_percent}`);
        }

        if (this.total_percent === "0.00" && this.total_flat === "0.00") {
          this.assignBtn = true;
        } else {
          this.assignBtn = false;
        }
        this.type = "";
        this.amountType = "";
        this.typeCtrl = false;
        this.amtCtrl = false;
        this.amountControl = false;
        this.amtRadio = false;
        this.amount = "";
        this.accRadio = false;
        this.account = "";
        document.getElementById("submitForm").reset();
      }
    },
    onChangeAmtType() {
      this.amountControl = this.amountType.length !== 0 ? true : false;
      console.log(`AmtType >>>?? ${this.type}`);
    },

    checkAcc() {
      this.account = this.accRadio ? "Principal Account" : "";
      console.log(`Acccount >>>?? ${this.account}`);
    },
    checkAmt() {
      this.amount = this.amtRadio ? "Principal Amount" : "";
      console.log(`Amount >>>** ${this.amount}`);
    },

    deleteItem(item, index) {
      console.log(`?????** Flat ${this.flat}`);
      console.log(`?????%% Percent ${this.percent}`);
      console.log(`?????>> ${JSON.stringify(item)}`);
      this.items.splice(index, 1);
      if (item.entryDrCr === "CR") {
        if (item.entryAmount.includes("%")) {
          console.log(
            `>>>>Percent Cr ${item.entryAmount.split("%")[0].trim()}`
          );
          var ind = this.percent.indexOf(
            Number(item.entryAmount.split("%")[0].trim())
          ); // <-- Not supported in <IE9
          console.log(`?????>>Index Percent Cr >> ${ind}`);
          if (ind !== -1) {
            this.percent.splice(ind, 1);
          }
          //.splice(index);
        } else {
          console.log(`>>>>Flat Cr ${item.entryAmount.split("₦")[1].trim()}`);
          var ind = this.flat.indexOf(
            Number(item.entryAmount.split("₦")[1].trim())
          );
          console.log(`?????>>Index Flat Cr >> ${ind}`); // <-- Not supported in <IE9
          if (ind !== -1) {
            this.flat.splice(ind, 1);
          }
        }
      } else {
        if (item.entryAmount.includes("%")) {
          console.log(
            `>>>>Percent Dr -${item.entryAmount.split("%")[0].trim()}`
          );
          var ind = this.percent.indexOf(
            Number(`-${item.entryAmount.split("%")[0].trim()}`)
          ); // <-- Not supported in <IE9
          console.log(`?????>>Index Percent Dr >> ${ind}`); // <-- Not supported in <IE9
          if (ind !== -1) {
            this.percent.splice(ind, 1);
          }
          //.splice(index);
        } else {
          console.log(`<<<<>Flat Dr -${item.entryAmount.split("₦")[1].trim()}`);
          var ind = this.flat.indexOf(
            Number(`-${item.entryAmount.split("₦")[1].trim()}`)
          ); // <-- Not supported in <IE9
          console.log(`?????>>Index Flat Dr >> ${ind}`); // <-- Not supported in <IE9
          if (ind !== -1) {
            this.flat.splice(ind, 1);
          }
        }
      }

      if (this.flat.length !== 0) {
        console.log("Flattttttttt");
        let not_num = (n) => (isNaN(n) ? 0 : n);
        this.total_flat = this.flat
          .reduce(function (a, b) {
            return not_num(a) + not_num(b);
          }, 0)
          .toFixed(2)
          .toString();
        console.log(`this.total_flat>>${this.total_flat}`);
      } else {
        this.total_flat = "0.00";
      }
      if (this.percent.length !== 0) {
        console.log("Percentttttttt");
        let not_num = (n) => (isNaN(n) ? 0 : n);
        this.total_percent = this.percent
          .reduce(function (a, b) {
            return not_num(a) + not_num(b);
          }, 0)
          .toFixed(2)
          .toString();
        console.log(`this.total_percent>>${this.total_percent}`);
      } else {
        this.total_percent = "0.00";
      }

      if (this.total_percent === "0.00" && this.total_flat === "0.00") {
        this.assignBtn = true;
      } else {
        this.assignBtn = false;
      }
      console.log(`?????** Flat ${this.flat}`);
      console.log(`?????%% Percent ${this.percent}`);
    },

    notifyVue(type = "default", msg) {
      console.log("testing>>>>>> "+ msg);
      //console.log(type);
      console.log("testing>>>>>> "+ msg);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
  },
  watch: {
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", newValue);
        this.$router.push({ name: "Select Product Type View" });
      }
    },
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    response_product() {
      if (Object.keys(this.response_product).length !== 0) {
        var product = [];
        product = this.response_product.entries;
        this.items = product.map((item) => {
          return {
            Type: `${item.entryDrCr}`,
            Amount:
              item.entryAmount === "$AMOUNT"
                ? "Principal Amount"
                : item.entryAmountType === "FLAT"
                  ? `₦ ${item.entryAmount}`
                  : `${item.entryAmount} %`,
            AccountNumber:
              item.entryAccount === "$ACCOUNT"
                ? "Principal Account"
                : item.entryAccount,
          };
        });
        console.log("//////////");

        for (let i = 0; i < product.length; i++) {
          if (product[i].entryAmount === "$AMOUNT") {
            continue;
          } else {
            if (product[i].entryAmountType === "FLAT") {
              this.flat.push(
                product[i].entryDrCr === "CR"
                  ? Number(product[i].entryAmount)
                  : -Number(product[i].entryAmount)
              );
            } else {
              this.percent.push(
                product[i].entryDrCr === "CR"
                  ? Number(product[i].entryAmount)
                  : -Number(product[i].entryAmount)
              );
            }
          }
        }
        if (this.flat.length !== 0) {
          console.log("Flattttttttt");
          let not_num = (n) => (isNaN(n) ? 0 : n);
          this.total_flat = this.flat
            .reduce(function (a, b) {
              return not_num(a) + not_num(b);
            }, 0)
            .toFixed(2)
            .toString();
          console.log(`this.total_flat>>${this.total_flat}`);
        }
        if (this.percent.length !== 0) {
          console.log("Percentttttttt");
          let not_num = (n) => (isNaN(n) ? 0 : n);
          this.total_percent = this.percent
            .reduce(function (a, b) {
              return not_num(a) + not_num(b);
            }, 0)
            .toFixed(2)
            .toString();
          console.log(`this.total_percent>>${this.total_percent}`);
        }

        if (this.total_percent === "0.00" && this.total_percent === "0.00") {
          this.assignBtn = true;
        } else {
          this.assignBtn = false;
        }
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.product.product_errors,
      loading_product: (state) => state.product.product_loading,
      success: (state) => state.product.product_success,
      response_product: (state) => state.product.assignResponse,
      response_slabSingle: (state) => state.product.singleSlab,
      response_page: (state) => state.product.page,
      response_selectAcc: (state) => state.product.selectAcc,
      userInformation: (state) => state.auth.userInfo,
    }),
    ...mapActions([""]),
    ...mapGetters([""]),
  },
  mounted() {
    if (this.response_page === "edit") {
      this.slabCode = this.response_slabSingle.slabCode;
      this.slabMaxAmt = this.response_slabSingle.slabMaxAmount;
      if (Object.keys(this.response_product).length !== 0) {
        //this.response_prod=this.response_product;
        var product = [];
        product = this.response_product.entries;
        this.entryId = Math.floor(Math.random() * 101);
        this.items = product.map((item) => {
          return {
            entryDrCr: `${item.entryDrCr}`,
            entryAmount:
              item.entryAmount === "$AMOUNT"
                ? "Principal Amount"
                : item.entryAmountType === "FLAT"
                  ? `₦ ${item.entryAmount}`
                  : `${item.entryAmount} %`,
            entryAccount:
              item.entryAccount === "$ACCOUNT"
                ? "Principal Account"
                : item.entryAccount,
            entryChecker: item.entryChecker,
            entryUpdatedAt: item.entryUpdatedAt,
            entryMaker: item.entryMaker,
            entryBankCode: item.entryBankCode,
            entryId: item.entryId,
            entryAmountType: item.entryAmountType,
            entryStatus: item.entryStatus,
            entrySlabCode: item.entrySlabCode,
            entryAeCode: item.entryAeCode,
            entryCreatedAt: item.entryCreatedAt,
            id: this.entryId++,
          };
        });

        console.log("//////////");

        for (let i = 0; i < product.length; i++) {
          if (product[i].entryAmount === "$AMOUNT") {
            continue;
          } else {
            if (product[i].entryAmountType === "FLAT") {
              this.flat.push(
                product[i].entryDrCr === "CR"
                  ? Number(product[i].entryAmount)
                  : -Number(product[i].entryAmount)
              );
            } else {
              this.percent.push(
                product[i].entryDrCr === "CR"
                  ? Number(product[i].entryAmount)
                  : -Number(product[i].entryAmount)
              );
            }
          }
        }
        if (this.flat.length !== 0) {
          console.log("Flattttttttt");
          let not_num = (n) => (isNaN(n) ? 0 : n);
          this.total_flat = this.flat
            .reduce(function (a, b) {
              return not_num(a) + not_num(b);
            }, 0)
            .toFixed(2)
            .toString();
          console.log(`this.total_flat>>${this.total_flat}`);
        }
        if (this.percent.length !== 0) {
          console.log("Percentttttttt");
          let not_num = (n) => (isNaN(n) ? 0 : n);
          this.total_percent = this.percent
            .reduce(function (a, b) {
              return not_num(a) + not_num(b);
            }, 0)
            .toFixed(2)
            .toString();
          console.log(`this.total_percent>>${this.total_percent}`);
        }

        if (this.total_percent === "0.00" && this.total_percent === "0.00") {
          this.assignBtn = true;
        } else {
          this.assignBtn = false;
        }
      }
    } else if (this.response_page === "add") {
      // this.slabCode =this.response_slabSingle.slabCode;
      //this.slabMaxAmt= this.response_slabSingle.slabMaxAmount;
      //if(Object.keys(this.response_product).length !== 0){
      //this.response_prod=this.response_product;
      //var product = [];
      //product = this.response_product.entries;
      this.entryId = Math.floor(Math.random() * 101);
      /*this.items = product.map((item) => {
            return {
                entryDrCr: `${item.entryDrCr}`,
                entryAmount: (item.entryAmount==='$AMOUNT')?'Principal Amount':(item.entryAmountType==='FLAT')?`₦ ${item.entryAmount}`:`${item.entryAmount} %`,
                entryAccount: (item.entryAccount==='$ACCOUNT')?'Principal Account':item.entryAccount,
                entryChecker:item.entryChecker,
                entryUpdatedAt:item.entryUpdatedAt,
                entryMaker:item.entryMaker,
                entryBankCode:item.entryBankCode,
                entryId:item.entryId,
                entryAmountType:item.entryAmountType,
                entryStatus:item.entryStatus,
                entrySlabCode:item.entrySlabCode,
                entryAeCode:item.entryAeCode,
                entryCreatedAt:item.entryCreatedAt,
                id:this.entryId++,
            };
        });


        console.log('//////////');

        for(let i =0;i<product.length;i++){
            if(product[i].entryAmount==='$AMOUNT'){
                continue;
            }
            else{
                if(product[i].entryAmountType==='FLAT'){
            this.flat.push((product[i].entryDrCr==='CR')?Number(product[i].entryAmount):-(Number(product[i].entryAmount)));
                }
                else{
               this.percent.push((product[i].entryDrCr==='CR')?Number(product[i].entryAmount):-(Number(product[i].entryAmount)));
                }
            }
        }
        */
      if (this.flat.length !== 0) {
        console.log("Flattttttttt");
        let not_num = (n) => (isNaN(n) ? 0 : n);
        this.total_flat = this.flat
          .reduce(function (a, b) {
            return not_num(a) + not_num(b);
          }, 0)
          .toFixed(2)
          .toString();
        console.log(`this.total_flat>>${this.total_flat}`);
      }
      if (this.percent.length !== 0) {
        console.log("Percentttttttt");
        let not_num = (n) => (isNaN(n) ? 0 : n);
        this.total_percent = this.percent
          .reduce(function (a, b) {
            return not_num(a) + not_num(b);
          }, 0)
          .toFixed(2)
          .toString();
        console.log(`this.total_percent>>${this.total_percent}`);
      }

      if (this.total_percent === "0.00" && this.total_percent === "0.00") {
        this.assignBtn = true;
      } else {
        this.assignBtn = false;
      }

      //}
    } else if (this.response_page === "create") {
      this.slabCode = this.response_slabSingle.slabCode;
      // this.slabMaxAmt= this.response_slabSingle.slabMaxAmount;
      //if(Object.keys(this.response_product).length !== 0){
      //this.response_prod=this.response_product;
      //var product = [];
      //product = this.response_product.entries;
      this.entryId = Math.floor(Math.random() * 101);
      /*this.items = product.map((item) => {
            return {
                entryDrCr: `${item.entryDrCr}`,
                entryAmount: (item.entryAmount==='$AMOUNT')?'Principal Amount':(item.entryAmountType==='FLAT')?`₦ ${item.entryAmount}`:`${item.entryAmount} %`,
                entryAccount: (item.entryAccount==='$ACCOUNT')?'Principal Account':item.entryAccount,
                entryChecker:item.entryChecker,
                entryUpdatedAt:item.entryUpdatedAt,
                entryMaker:item.entryMaker,
                entryBankCode:item.entryBankCode,
                entryId:item.entryId,
                entryAmountType:item.entryAmountType,
                entryStatus:item.entryStatus,
                entrySlabCode:item.entrySlabCode,
                entryAeCode:item.entryAeCode,
                entryCreatedAt:item.entryCreatedAt,
                id:this.entryId++,
            };
        });


        console.log('//////////');

        for(let i =0;i<product.length;i++){
            if(product[i].entryAmount==='$AMOUNT'){
                continue;
            }
            else{
                if(product[i].entryAmountType==='FLAT'){
            this.flat.push((product[i].entryDrCr==='CR')?Number(product[i].entryAmount):-(Number(product[i].entryAmount)));
                }
                else{
               this.percent.push((product[i].entryDrCr==='CR')?Number(product[i].entryAmount):-(Number(product[i].entryAmount)));
                }
            }
        }
        */
      if (this.flat.length !== 0) {
        console.log("Flattttttttt");
        let not_num = (n) => (isNaN(n) ? 0 : n);
        this.total_flat = this.flat
          .reduce(function (a, b) {
            return not_num(a) + not_num(b);
          }, 0)
          .toFixed(2)
          .toString();
        console.log(`this.total_flat>>${this.total_flat}`);
      }
      if (this.percent.length !== 0) {
        console.log("Percentttttttt");
        let not_num = (n) => (isNaN(n) ? 0 : n);
        this.total_percent = this.percent
          .reduce(function (a, b) {
            return not_num(a) + not_num(b);
          }, 0)
          .toFixed(2)
          .toString();
        console.log(`this.total_percent>>${this.total_percent}`);
      }

      if (this.total_percent === "0.00" && this.total_percent === "0.00") {
        this.assignBtn = true;
      } else {
        this.assignBtn = false;
      }

      // }
    } else {
      this.notifyVue(
        "danger",
        "An error as occurred, please go back to continue"
      );
    }
  },
};
</script>

<style lang="css" scoped>
td {
  height: 100px;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.table-section tbody {
  max-width: 500px;
  overflow-x: auto;
}
.table-section {
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 40px;
}
.form-error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.delete {
  cursor: pointer;
}

.svg-red {
  height: 20px;
  width: auto;
  fill: #f50e0e;
}
.svg-green {
  height: 20px;
  width: auto;
  fill: #0ef553;
}
.entry-total-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}
.entry-total {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
.divider {
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}
.save-bene {
  padding: 7px 0 0;
}
.save-bene1 {
  padding: 7px 0 0;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 17px;
  color: #525f7f;
}
.checkbox-container .checkbox {
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  align-items: center;
}
.checkbox-container input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.checkbox-container .checkbox-box {
  height: 20px;
  display: flex;
  align-items: center;
}
.checkbox-container
input[type="checkbox"]:checked
+ .checkbox-box
.checkbox-outer {
  background-color: #6d0605;
}
.checkbox-container .checkbox-outer {
  width: 20px;
  height: 20px;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  background-color: #e6ebf1;
  border-radius: 50%;
}
.checkbox-container
input[type="checkbox"]:checked
+ .checkbox-box
.checkbox-inner {
  opacity: 1;
  transform: scale(1);
}
.checkbox-container .checkbox-inner {
  width: 10px;
  height: 8px;
  opacity: 0;
  margin: 6px 0 0 5px;
  transform: scale(1.15);
  transition: all 0.2s ease-in-out;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABGdBTUEAALGPC/xhBQAAAcZJREFUSA3NlrtKA0EUhrOSwhQG8dIIsRHSiIWdhXaiGNBSU1n6AEJewDxBnkAfQEXriLXYipUo2HhpVEQEr+t3wo5MDrMTMbPigZ9zmXP+fzbs7CSX+2OLstKL47gf7mUQg+0oiu6z0sohNgTOgDGJZQPZGORbRsnya6LWE1oSgRk4Vx288tOGNcTy4MR6KhPeEQyEVYMN0ppRUL71cwYVRKAEnpSQpEcg/GmAdFfYlb2TTwZ9MiGDtKKETNrIQqwA+7lRsPwVcTELwbolYofVLMTKKLzYKknc7ChG4xxoggMw33GAhqQX12aygbJ3noZx8GqNfRCv+IZYr1r9dlj3zbXW6F63J5JYNrDgGqZeBPJSaJOXp+CaaavRtKgnk/wZP93WTEKtkdJf0b2pOQSugyu8D+D78EoM5EBr20kldy0w3QsONUuS3+LljYyAfKq0ySet5OL11hjqA8eaLckv8RspazUvsW8RwkFwmkLsKst1lPdxdlyDYARcuNhV7ZNcLtzuDaIxcK0EdLrZvZLFAPsEkNvaZVIfttrDhJBOAdfFGv4WN1tGcBY8AmN7BL+6xX88hMAoG1gCN2CfP7ZvZkP/2n8B0rR2l1wYbFEAAAAASUVORK5CYII=);
}
.checkbox-container .checkbox-label {
  margin: 0 0 0 0px;
  font-size: 11px;
  font-weight: 600;
  color: #171717;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  display: flex;
  user-select: none;
}
.checkbox-row {
  grid-gap: 14px 0;
  gap: 14px 0;
  margin: 0px 0 0px;
  padding: 0 0px;
}

.top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  display: flex;
  justify-content: center;
  width: 20%;
}
.nav-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.child-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
}
.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.nav-btn {
  width: 35px;
  height: 35px;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.num-dis {
  width: 35px;
  height: 35px;

  background: #175d85;
  border: 1px solid #175d85;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.sel-opt {
  width: 28%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.sel-opt:focus {
  outline: none;
}
</style>


