<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Add Entry To Batch</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Batch</a></li>
              <li class="breadcrumb-item active" aria-current="page">Add Entry To Batch</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
         
          
          <!--</div>-->
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <!--<div v-if="option_acc.length!==0">-->
          <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Add Entry To Batch</h3>

    <!-- Card body -->
    
    <form @click="resetForm" id="form1"
          @submit.prevent="submit">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <p class="queue-title">Batch Name</p><p class="queue-value"> {{response_journal.batchName}} </p></div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12"><p class="queue-title">Batch Reference</p>
            <p class="queue-value"> {{response_journal.batchReference}} </p></div></div>


<div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Type">
                      <el-select class="select-danger" clearable filterable
             placeholder="Type"
             v-model="model.type">
    <el-option v-for="option in [{value:'CR',label:'Credit'},{value:'DR',label:'Debit'}]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="accCtrl" class="form-error-message"> The Type is Required' </p>
                    </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Amount"
                      name="amount"
                      type="number"
                      placeholder="Amount"
                      :required="inputRequired"
                      v-model="model.amount">
          </base-input>
        </div>

      
      </div>
<div class="row">
     <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Account Number"
                      name="entryaccount"
                      type="number"
                      placeholder="Entry Account"
                      :required="inputRequired"
                      v-model="model.accountNumber">
          </base-input>
        </div>
         <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Narration"
                      name="Narration"
                      type="text"
                      placeholder="Narration"
                      :required="inputRequired"
                      v-model="model.narration">
          </base-input>
        </div>
      </div>
    <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="modals.modal3 = true" >
       <i class=""></i> Bulk Upload
     </base-button>
     <div class="mt-2">
      <a  href="https://assets-base.s3.amazonaws.com/csv/powerbank/sample-batch-entries.csv" target="_blank" class="sample-file-download ">
                <span  class="fas fa-file-download"></span> Download Sample Batch Entries </a>
     </div>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_journal}">
   <span class="btn-inner--text">Add Entry To Batch</span>
       <span :class="{'spinner-border': loading_journal}"></span>
     </base-button>
     
      </div>
      </div>
    </form>
  </card>
  <!---</div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>-->
          <modal :show.sync="modals.modal3"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
               <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal3 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
          <div class="view-more-top">
            <h4 class="card-title mb-4">Bulk Upload</h4>
            </div>
                        <div class="view-more-base">
                          <!--<div class="table-responsive">-->
                              <form @submit.prevent="loadData">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <base-input label="CSV File">
          <b-form-file  id="myfile"
       name="csv_file"  @change="loadCSV($event)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>
    <div class="output"></div>
    <p v-if="fileCtrl" class="form-error-message"> The CSV file is required </p>
                </base-input>
    
        </div>
      </div>
        <div class="row">
      <div class="col-12 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_journal}">
     <span class="btn-inner--text">Upload</span>
       <span :class="{'spinner-border': loading_journal}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>  
        </div>
    </form>

                            </div><!--</div>-->
                           <!-- <p class="text-right mt-2"><form<span>
                                              <span class="authorize-btn"><button  :class="{disabled:loading_journal}" @click="authorizeUser" class="btn btn-primary mr-1">Authorize
                                                <span :class="{'spinner-border': loading_journal}"></span> </button>
                                                </span></span></p>--->
                                            </div></div></div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>
            
        </modal>
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import country from '@/components/country';
//import UserInfo from './UserInfo.vue';
import vSelect from 'vue-select';
import { Select, Option } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
export default {

    components: {
        BaseHeader,
        //UserInfo,
        //LoadingPanel,
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          //selected_branchCode:{},
         fileCtrl:false,
          accCtrl:false,
          data:[],
          inputRequired:true,
          modals:{
              modal3:false
          },
            model: {
            accountNumber:'',
            amount:'',
            type:'',
            narration:''

            }
      }
    },
    methods: {
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
      submit: function(event) {
          if(this.model.type!==''){
          console.log(`>>>>>>> Model ${JSON.stringify(this.model)}`);
         
          this.$store.dispatch("submitBatchEntry",{
              accountNumber: this.model.accountNumber,
amount: this.model.amount,
bankCode:this.userInformation.userBankCode,
batchReference: this.response_journal.batchReference,
branchCode: this.userInformation.userBranchCode,
drCr: this.model.type,
maker: this.userInformation.userId,
makerBankCode: this.userInformation.userBankCode,
makerBranchCode: this.userInformation.userBranchCode,
narration: this.model.narration
          } , { root: false });
          this.inputRequired=false;

          this.model={
            accountNumber:'',
            amount:'',
            type:'',
            narration:''

            };
             this.accCtrl=false;
          //event.target.reset();

          }
          else{
            this.accCtrl=true;  
          }
      },
      loadData(){
        if( document.getElementById("myfile").files.length == 0 ){
                this.fileCtrl=true;
            }else{
                this.modals.modal3=false;
                this.fileCtrl=false;
                setTimeout(()=>{
              this.$store.dispatch("loadData",
            {entries:this.data
              }
            , { root: false });

                }, 2000);

                
          }
          
    },
     
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      loadCSV(e) {
      var vm = this
      if (window.FileReader) {
         
        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
         // vm.parse_csv = vm.csvJSON(csv);
         var lines = csv.split("\n");
        var file_header = lines[0].split(",")
        console.log(file_header);
        var required_header = ["accountNumber", "drCr", "amount", "narration"];

        if(vm.areEqual(required_header, file_header)){
                       //var lines = csv.split("\n");
var results = [];
//var split_objects =
lines.map(function(str,index) {
   if(index<1){return}
  var obj ={};
  var box = str.split(',');
  obj['accountNumber'] = box[0].trim();
  obj['drCr'] = box[1].trim();
  obj['amount'] = box[2].trim();
  obj['narration'] = box[3].trim();
  
   results.push(obj);
  return {}
});
vm.data=results;
//vm.callApi(results);
console.log(results);
        }
        else{
            vm.notifyVue('danger','CSV File Structure Not Supported ');
        }


 };
        reader.onerror = function(evt) {
          if(evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    },


areEqual (first, second){
console.log(first.length);
console.log(second.length);

  if(first.length !== second.length){
      return false;
   };
   return true;
  /* for(let i = 0; i < first.length; i++){
      if(!second.includes(first[i])){
         return false;
      };
   };
   return true;*/
  // return first.sort().toString() === second.sort().toString();
},

    },
    watch:{
     data(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      //if (newValue !== null) {
          console.log(JSON.stringify(newValue));
       // this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      //}
    },

        success_journal(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    
    error_journal(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
       this.modals.modal3=false;
    },
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    //response: state => state.role.role_response, response_read: state => state.role.role_read_response,
    //response_teller: state => state.teller.teller_response,
    //response_internalAcc: state => state.internalAccount.internal_acc_response,
    response_journal: state => state.journal.journal_response, loading_journal: state => state.journal.journal_loading,
    success_journal: state => state.journal.journal_success,error_journal: state => state.journal.journal_errors,
    error_user: state => state.user.user_error
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.queue-title {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    margin-top: 20px;
    color: #9e9e9e;
}
.queue-value {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 35px;
    color: #2a3042;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
</style>
