<template>
  <div>
    <notifications></notifications>
    <modal1
      :show="showCreateCustomer && showModal"
      body-classes="p-1"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <!--<div class="card">-->
      <!-- Card header -->
      <div class="card-head">
        <!-- Title -->
        <!--<div class="row">-->
        <!--<div class="col-12">-->
        <article class="item-right">
          <!-- @click="displayDetail()">-->
          <a
            ><span @click="closeModal()"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                style="fill: #171717"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg> </span
          ></a>
        </article>
        <!--</div>-->
        <div class="py-2">
          <h5 class="h3 mb-0 text-center">Create Customer</h5>
        </div>

        <!-- </div>-->
      </div>
      <!-- Card body -->
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col">
              <div v-if="option_user.length !== 0">
                <!-- Card body -->

                <form @click="resetForm" @submit.prevent="submit">
                  <!------------------------------Line1-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label="Customer Picture">
                        <b-form-file
                          accept="image/*"
                          id="myfile1"
                          @change="handleImage"
                          v-model="model.customerPicture"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <p v-show="picCtrl" class="form-error-message">
                          The Picture is required
                        </p>
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="First Name"
                        name="First Name"
                        placeholder="First Name"
                        :required="inputRequired"
                        v-model="model.customerFirstName"
                      >
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line1-------------------------------->

                  <!------------------------------Line2-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Middle Name"
                        name="Middle Name"
                        placeholder="Middle Name"
                        v-model="model.customerMiddleName"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Last Name"
                        name="Last Name"
                        placeholder="Last Name"
                        :required="inputRequired"
                        v-model="model.customerLastName"
                      >
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line2-------------------------------->

                  <!------------------------------Line3-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Phone Number"
                        name="Phone Number"
                        placeholder="Phone Number"
                        :required="inputRequired"
                        v-model="model.customerPhoneNumber"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label="Country">
                        <el-select
                          class="select-danger"
                          filterable
                          placeholder="Country"
                          v-model="model.customerCountry"
                        >
                          <el-option
                            v-for="option in option_country"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                          >
                          </el-option>
                        </el-select>
                        <p v-show="countryCtrl" class="form-error-message">
                          The Country is required
                        </p>
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line3-------------------------------->

                  <!------------------------------Line4-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Customer Address"
                        name="Customer Address"
                        placeholder="Customer Address"
                        :required="inputRequired"
                        v-model="model.customerAddress"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Customer BVN"
                        name="Customer BVN"
                        placeholder="Customer BVN"
                        :required="inputRequired"
                        v-model="model.customerBVN"
                      >
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line4-------------------------------->

                  <!------------------------------Line5-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Email"
                        name="Email"
                        placeholder="Email"
                        :required="inputRequired"
                        v-model="model.customerEmail"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label class="form-control-label"> Date of Birth</label>
                      <datetime
                        v-model="model.customerDob"
                        input-class="form-control"
                        class="theme-green"
                        placeholder="DOB"
                        zone="Africa/Lagos"
                        value-zone="Africa/Lagos"
                        :min-datetime="minDatetime"
                        :max-datetime="maxDatetime"
                      ></datetime>
                      <p v-show="dobCtrl" class="form-error-message">
                        The Date of Birth is required
                      </p>
                      <!--- <base-input label="Date of Birth">
                     <el-date-picker
      v-model="model.customerDob"
      type="date"
      placeholder="Pick a day">
    </el-date-picker>
    <p v-show="dobCtrl" class="form-error-message"> The Date of Birth is required </p>
          </base-input>-->
                    </div>
                  </div>
                  <!------------------------------End of Line5-------------------------------->

                  <!------------------------------Line6-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input
                        label="Customer State"
                        name="Customer State"
                        placeholder="Customer State"
                        :required="inputRequired"
                        v-model="model.customerState"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label="Account Class">
                        <el-select
                          class="select-danger"
                          filterable
                          placeholder="Account Class"
                          v-model="model.customerAccountClass"
                        >
                          <el-option
                            v-for="option in option_product"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                            required
                          >
                          </el-option>
                        </el-select>
                        <p v-show="classCtrl" class="form-error-message">
                          The Account Class is required
                        </p>
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line6-------------------------------->

                  <!------------------------------Line7-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label="Currency">
                        <el-select
                          class="select-danger"
                          filterable
                          placeholder="Currency"
                          v-model="model.customerCurrency"
                        >
                          <el-option
                            v-for="option in option_currency"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                          >
                          </el-option>
                        </el-select>
                        <p v-show="currencyCtrl" class="form-error-message">
                          The Currency is required
                        </p>
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label="Customer Mandate">
                        <b-form-file
                          accept="image/*"
                          id="myfile2"
                          @change="handleImage1"
                          v-model="model.customerMandate"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        ></b-form-file>
                        <p v-show="mandCtrl" class="form-error-message">
                          The Customer Mandate is required
                        </p>
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line7-------------------------------->

                  <!------------------------------Line8-------------------------------->
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label=" Account Type ">
                        <el-select
                          class="select-danger"
                          filterable
                          placeholder="Branch Manager"
                          v-model="model.customerAccountType"
                        >
                          <el-option
                            v-for="option in [
                              { value: 'SAVINGS', label: 'SAVINGS' },
                              { value: 'CURRENT', label: 'CURRENT' },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.label"
                          >
                          </el-option>
                        </el-select>
                        <p v-show="officerCtrl" class="form-error-message">
                          Account Officer is Required'
                        </p>
                      </base-input>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                      <base-input label=" Account Officer ">
                        <el-select
                          class="select-danger"
                          filterable
                          placeholder="Branch Manager"
                          v-model="model.customerAccountOfficer"
                        >
                          <el-option
                            v-for="option in option_user"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.label"
                          >
                          </el-option>
                        </el-select>
                        <p v-show="officerCtrl" class="form-error-message">
                          Account Officer is Required'
                        </p>
                      </base-input>
                    </div>
                  </div>
                  <!------------------------------End of Line8-------------------------------->
                  <!-------------------------------Line9-------------------------------------->

                  <!-------------------------------End of Line9------------------------------->

                  <div class="text-right">
                    <base-button
                      icon
                      type="primary"
                      native-type="submit"
                      :class="{ disabled: loading_customer }"
                    >
                      <span class="btn-inner--text">Submit</span>
                      <span
                        :class="{ 'spinner-border': loading_customer }"
                      ></span>
                    </base-button>
                    <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
                  </div>
                </form>
              </div>
              <div v-else>
                <LoadingPanel></LoadingPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal1>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import country from "@/components/country";
import vSelect from "vue-select";
import { Select, Option, DatePicker } from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from "@/components/LoadingPanel";
import { Datetime } from "vue-datetime";
export default {
  components: {
    //BaseHeader,
    LoadingPanel,
    Datetime,
    //vSelect,
    //[Select.name]: Select,
    //[Option.name]: Option
  },
  props: {
    showCreateCustomer: Boolean,
  },
  data() {
    return {
      //selected_branchCode:{}, customerCreateNew
      showModal: true,
      option_user: [],
      option_country: [],
      option_currency: [
        { value: "NGN", label: "NGN" },
        { value: "GBP", label: "GBP" },
        { value: "USD", label: "USD" },
      ],
      option_product: [],
      //option_branch:[{value:'00001',label:'VI'},{value:'00002',label:'Ikeja'}],
      picCtrl: false,
      mandCtrl: false,
      countryCtrl: false,
      officerCtrl: false,
      dobCtrl: false,
      currencyCtrl: false,
      classCtrl: false,
      inputRequired: true,
      minDatetime: "1970-01-01",
      maxDatetime: "",
      customerMan: "",
      customerPic: "",
      model: {
        customerFirstName: "",
        customerLastName: "",
        customerMiddleName: "",
        customerPicture: [],
        customerMandate: [],
        customerPhoneNumber: "",
        customerCountry: "",
        customerAddress: "",
        customerBVN: "",
        customerEmail: "",
        customerDob: "",
        customerState: "",
        customerAccountClass: "",
        customerCurrency: "",
        customerAccountOfficer: "",
        customerAccountType: "",
      },
    };
  },
  methods: {
    closeModal() {
      console.log("Closing Moddalslslslsl");
      this.showModal = false;
      this.$emit("closeCreateCustomer", false);
      this.showModal = true;
    },
    resetForm() {
      //console.log(`>>><<<${this.nameCtrl}`);
      this.inputRequired = true;
    },

    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.model.customerPicture[0] = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    handleImage1(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image1(selectedImage);
    },
    createBase64Image1(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.model.customerMandate[0] = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    submit: function (event) {
      console.log(`>>><<<${this.validateSelect()}`);
      if (this.validateSelect()) {
        let payload = {};
        console.log(`Picture>>>>>>??? ${this.model.customerPicture[0]}`);
        console.log(`Mandate>>>>>}}}} ${this.model.customerMandate[0]}`);

        payload.customerPicture = this.model.customerPicture[0];
        payload.customerMandate = this.model.customerMandate[0];
        payload.pictureFileName = "picture_" + this.model.customerPhoneNumber;
        payload.mandateFileName = "mandate_" + this.model.customerPhoneNumber;

        payload.accountClass = this.model.customerAccountClass;
        payload.accountCurrency = this.model.customerCurrency;
        payload.customerAccountOfficer = this.model.customerAccountOfficer;
        payload.customerAddress = this.model.customerAddress;
        payload.customerBankCode = this.userInformation.userBankCode;
        payload.customerBranchCode = this.userInformation.userBranchCode;
        payload.customerBvn = this.model.customerBVN;
        payload.customerCountry = this.model.customerCountry;
        payload.customerCountryCode = "234";
        payload.customerDob = this.formatDate(
          String(this.model.customerDob).split("T")[0]
        );
        payload.customerEmail = this.model.customerEmail;
        payload.customerFirstName = this.model.customerFirstName;
        payload.customerLastName = this.model.customerLastName;
        payload.customerMiddleName = this.model.customerMiddleName;
        payload.customerMaker = this.userInformation.userId;
        payload.accountType = this.model.customerAccountType;
        //payload.customerMandate= this.model.customerMandate;
        payload.customerPhoneNumber = this.model.customerPhoneNumber;
        //payload.customerPicture=this.model.customerPicture;
        payload.customerState = this.model.customerState;
        payload.customerType = "CUSTOMER";
        console.log(`>>>>>>> Model ${JSON.stringify(payload)}`);
        this.$store.dispatch("customerCreateNew", payload, { root: false });
        //https://lumbex-vue-s3-file-uploads.s3.us-west-2.amazonaws.com/StagingArea/08116913859_397577884.png
        ///this.$store.dispatch("customerCreate", this.model , { root: false });
        //Authorization: AWS4-HMAC-SHA256 Credential=ASIAQCMYLE2YAOZIK7SQ/20210303/us-west-2/s3/aws4_request, SignedHeaders=host;x-amz-acl;x-amz-content-sha256;x-amz-date;x-amz-security-token;x-amz-user-agent, Signature=246521e907b526349d807cf79f82f04f49b32f098eba6a5a77cc674607c7b8fe
        //X-Amz-Date: 20210303T093405Z
        //x-amz-security-token: IQoJb3JpZ2luX2VjEKL//////////wEaCXVzLXdlc3QtMiJHMEUCICk9W4KqPvC8UqcJdx31ZOaYBw9jIyx4WYBUPxlk4QgqAiEAstGQhNf6sad12RX0PelRrv4GhTSngrTvoevfHzAyQx4qmgYIu///////////ARACGgwwMDUxNTEzMzYxMTIiDGvI8wMbat5TX62mNSruBUDmVrrFDUxdVJ8g0JkJ7S1bssS1bXUQL7+5g9xF+Sfh2SpJPd3BOYGPXv0kbUQ0mhsY/ZQ9ncppVfT4HE3MMx03chfUzwQnWGP4G6YUp+7GqcnzKj2unxQM6ZiO/v42jOQzFxEjYGaFlS38Agv5sNdFQ3zicCE3ZPnZF5WCkCpDvaiaxtthftj6GbkLl8xgjZIY3OK8A1/iPMbmGGAwB3YJ5w2hwzQAZKe/MHesI3b5F6weVHX1IVsDBWEfsrzJdew7/KvEY7+LWFYbTgZoQcQSkLF3b/Q1ET227ycVPKcTBAsMMOnP0lx+RC8F5rbkO1w7lxXoYWrICYjllMobkZbC6nv0xDRsvS1AkGPTtKspdjQBIu/fqun8DgZNVAABHePkqk2I+5xK8aJwrKwbZIfKozq9LK9sb54U25acl2zWXuPo983VCGlXGj0PG58rvnbRlk10LvnRUtaLM+xyaOgGQzCKD8mDPWDbTa2aXAFrJUzGmH5Cr4yF1TvkA6Pzgrx7Zl2hzgBvV2JmfjPazsj18tC+f6o/nEI6SmCqNr3fSF+wCN5E//KmU992A0n0xEfNQzTW8fi2NjyRDPVJ0g5KRKn25Sm8mNriHQGb7pT2UfbbvsuJ7E0R8BWmpI1S5guL8+rQWQVout5+4RNe1/7yB+LybJRFYJwCYBDBiY6/hoa+Jxo8E/LyYVGxhOo42z6tUxLQitH32J7xrc8pfM/VqT47W1B4MkWjOl38mzA7WV19wp0wYnaNgewokwrkFRuX4Wd9WMpa/sU+FuwPPOod4mNnN2+076UlUvRuBU0iUPPfUQcJQhemhOAIuJfUs7N2/4aGMDzoK2Lhh1Ed7u//GLhurrwaQ1Ah9weKTo85+z4ADnLfc9xxttIOZbCvhf4FVjvDbulW9lqlkobpF3NkirrI3hdqPxa7TW9xJtdcp6xf4xuWVdj+oN0/qvqSuUeYLSTtEfi4oDkyHhHgM09fXMPXtM+4HMlrQExpUTCRsP2BBjqHAiEy6ngMponJbhc9DQM8U/UpLYZkYvgdlfEfmBX3/qMP7NBzjehjtX7mjl/b5IbmYxGGQAIAitSI35qpXNCt75Xa0hUkVYvWS0Fk/2pkAix0QCj+KkqbPS6zqKoKRhAGFrWO1xLM7QZDo0Ifbyc70z1Z/0IALJPOAopfEhsisHUYeuiaptB6KvYna9h4IgA5YS8H0lOjyKiYgqNg4v8HWC/26W2zZ0JnKgKBH2BRhBz9RbwKKSzhrsTK+tDcz51TGDmm3Pvy7OJlc4CTHYYj6O/CUBuKspuxRmDsrsTgLNP+qKWEmNdujxp5YFOzcNUD/0g3ty1SNE66rOv2AR+yIZ8icXA7nngE
        this.inputRequired = false;

        //event.target.reset();
      }
    },

    formatDate(date) {
      return (
        date.split("-")[2] +
        "-" +
        this.convert(date.split("-")[1]) +
        "-" +
        date.split("-")[0]
      );
    },
    convert(str) {
      var res = "";
      switch (str) {
        case "01":
        case "1":
          res = "JAN";
          break;
        case "02":
        case "2":
          res = "FEB";
          break;
        case "03":
        case "3":
          res = "MAR";
          break;
        case "04":
        case "4":
          res = "APR";
          break;
        case "05":
        case "5":
          res = "MAY";
          break;
        case "06":
        case "6":
          res = "JUN";
          break;
        case "07":
        case "7":
          res = "JUL";
          break;
        case "08":
        case "8":
          res = "AUG";
          break;
        case "09":
        case "9":
          res = "SEP";
          break;
        case "10":
          res = "OCT";
          break;
        case "11":
          res = "NOV";
          break;
        case "12":
          res = "DEC";
          break;
        default:
          res = str;
          break;
      }
      return res;
    },

    validateSelect() {
      // this.imageValidator();
      if (document.getElementById("myfile1").files.length == 0) {
        this.picCtrl = true;
      } else {
        this.picCtrl = false;
      }
      if (document.getElementById("myfile2").files.length == 0) {
        this.mandCtrl = true;
      } else {
        this.mandCtrl = false;
      }
      if (this.model.customerAccountOfficer === "") {
        this.officerCtrl = true;
      } else {
        this.officerCtrl = false;
      }
      if (this.model.customerCountry === "") {
        this.countryCtrl = true;
      } else {
        this.countryCtrl = false;
      }
      if (this.model.customerDob.length === 0) {
        this.dobCtrl = true;
        console.log("true>>>>>");
      } else {
        this.dobCtrl = false;
        console.log("False>>>>>");
      }
      if (this.model.customerCurrency === "") {
        this.currencyCtrl = true;
      } else {
        this.currencyCtrl = false;
      }
      if (this.model.customerAccountClass === "") {
        this.classCtrl = true;
      } else {
        this.classCtrl = false;
      }

      return (
        !this.countryCtrl &&
        !this.picCtrl &&
        !this.mandCtrl &&
        !this.model.officerCtrl &&
        !this.model.countryCtrl &&
        !this.model.dobCtrl &&
        !this.model.currencyCtrl
      );
    },
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
  },
  watch: {
    response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if (Object.keys(this.response_product).length !== 0) {
        var product = [];
        product = this.response_product.data;
        this.option_product = product.map((item) => {
          return {
            value: `${item.Code}`,
            label: `${item.Code} (${item.Description})`,
          };
        });
        console.log("//////////");
        //console.log("&$$$$$&"+this.option_branch);
      }
    },
    response_user(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if (Object.keys(this.response_user).length !== 0) {
        var user = [];
        user = this.response_user.data;
        this.option_user = user.map((item) => {
          return {
            value: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
            label: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
          };
        });
        console.log("//////////");
        //console.log("&$$$$$&"+this.option_branch);
      }
    },
    success_customer(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        // this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
        this.model = {
          customerFirstName: "",
          customerLastName: "",
          customerMiddleName: "",
          customerPicture: [],
          customerMandate: [],
          customerPhoneNumber: "",
          customerCountry: "",
          customerAddress: "",
          customerBVN: "",
          customerEmail: "",
          customerDob: "",
          customerState: "",
          customerAccountClass: "",
          customerCurrency: "",
          customerAccountOfficer: "",
        };
      }
    },
    /*error_customer(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }*/

    error_branch(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
  },
  mounted: function () {
    let countries = country;
    this.option_country = countries.map((item) => {
      return {
        value: `${item.name}`,
        label: `${item.name}`,
      };
    });
    this.$store.dispatch(
      "userRead",
      {
        readAll: "YES",
        source: "CBA",
        userBankCode: this.userInformation.userBankCode,
      },
      { root: false }
    );

    let payload = {
      product: {
        productBankCode: this.userInformation.userBankCode,
        productType: "AC",
      },
      gl: {
        glLevel2BankCode: this.userInformation.userBankCode,
        readAll: "NO",
      },
    };

    this.$store.dispatch("productRead", payload, { root: false });
    var dateObj = new Date();
    var month =
      String(dateObj.getUTCMonth() + 1).length === 1
        ? "0" + (dateObj.getUTCMonth() + 1)
        : dateObj.getUTCMonth() + 1; //months from 1-12
    var day = String(dateObj.getUTCDate().length === 1)
      ? "0" + dateObj.getUTCDate()
      : dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var newdate = year + "-" + month + "-" + day;
    this.maxDatetime = newdate;
  },
  computed: {
    ...mapState({
      //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
      //success: state => state.role.role_success,
      userInformation: (state) => state.auth.userInfo,
      response_product: (state) => state.product.product_response,
      response_user: (state) => state.user.user_response,
      response_branch: (state) => state.customer.customer_response,
      loading_customer: (state) => state.customer.customer_loading,
      success_customer: (state) => state.customer.customer_success,
      error_customer: (state) => state.customer.customer_errors,
      error_user: (state) => state.user.user_error,
    }),
    ...mapActions(["userRead", "productRead", "customerCreate"]),
    ...mapGetters([""]),
  },
};
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.form-error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  height: calc(1.5em + 1.25rem + 5px);
  line-height: 1.5;
  width: 100%;
}
.item-right {
  padding-left: 100%;
  cursor: pointer;
  margin: 0px 0% -10px 0px;
  float: right !important;
}
.card-head:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-head {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
</style>
