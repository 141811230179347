<template>
  <div>
    <notifications></notifications>

    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Products</h6>
        </div>
        <div class="col-lg-6 col-5 text-right">
        
          <!--          <base-button size="sm" type="neutral">New</base-button>-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <card>
            <!-- Card header -->
            <!--    <h3 slot="header" class="mb-0">Products</h3>-->

            <!-- Card body -->
            <div>
              <b-tabs
                content-class="mt-3"
                tabs
                pills
                justified
                active-nav-item-class="font-weight-bold"
              >
                <!------------------------------Start of tab one----------------------->
                <b-tab @click="viewProduct('AC')" title="Account" active>
                  <ProductViewTableAC></ProductViewTableAC>
                </b-tab>
                <!---------------------------------End of Tab one---------------------------->

                <!---------------------------------Start of Tab Two---------------------------->
                <!------------------------------Start of tab one----------------------->
                <b-tab @click="viewProduct('LO')" title="Loan">
                  <ProductViewTableLO></ProductViewTableLO>
                </b-tab>
                <!---------------------------------End of Tab one---------------------------->
                <!---------------------------------End of Tab two---------------------------->
              </b-tabs>
            </div>
            <!--<div v-else>
    <LoadingPanel></LoadingPanel>
</div>-->
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import TabPane from "@/components/Tabs/Tab";
import Tabs from "@/components/Tabs/Tabs";
import LoadingPanel from "@/components/LoadingPanel";

import ProductViewTable from "./ProductViewTable";
import ProductViewTableAC from "./ProductViewTableAC";
import ProductViewTableLO from "./ProductViewTableLO";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    BaseHeader,
    ProductViewTableLO,
    ProductViewTableAC,
    //ProductViewTable1
    //tab,
    //TabPane,
    //Tabs,
  },
  data() {
    return {
      light: "light",
      initialLoading: false,
      show: false,
      modals: {
        modal1: false,
      },
      items: [],
      fields: [
        { key: "sn", label: "S/N", sortable: true, sortDirection: "desc" },
        {
          key: "accountBankCode",
          label: "Bank Code",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountName",
          label: "Account Name",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountInterestRate",
          label: "Interest Rate",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountClass",
          label: "Account Class",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountCurrency",
          label: "Currency",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountBalance",
          label: "Account Balance",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountNumber",
          label: "Account Number  ",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountCustomerId",
          label: "CustomerId",
          sortable: true,
          class: "text-left",
        },
        {
          key: "accountStatus",
          label: "Status",
          sortable: true,
          class: "text-left",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  methods: {
    viewProduct(prdTemplateCode) {
      this.$store.dispatch(
        "readProductView",
        {
          productBankCode: this.userInformation.userBankCode,
          productType: prdTemplateCode,
        },
        { root: false }
      );
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    queryAccount() {
      if (this.filter !== null || this.filter !== "") {
        this.$store.dispatch(
          "internalAccountReadSingle",
          {
            accountBankCode: this.userInformation.userBankCode,
            accountNumber: this.filter,
          },
          { root: false }
        );
      } else {
        this.notifyVue("danger", "Enter an account number");
      }
    },

    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    updateValue(value) {
      this.show = value;
      //console.log("working&&&&&&&&&&&&"+this.show);
    },
  },
  watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },

    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", ` Success`);

        //this.showDismissibleAlert=true;
      }
    },

    success_interanl_acc(newValue, oldValue) {
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
      }
    },
    error_interanl_acc(newValue, oldValue) {
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
      }
    },
  },
  mounted: function () {
    this.viewProduct("AC");
  },
  computed: {
    ...mapState({
      loading_product: (state) => state.product.product_loading1,
      userInformation: (state) => state.auth.userInfo,
    }),
    ...mapActions(["internalAccountRead"]),
    ...mapGetters([""]),
  },
};
</script>
<style lang="css" scoped>
.hand {
  cursor: pointer;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #495057;
}
.table-section {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 40px;
}
.table-section tbody {
  max-width: 500px;
  overflow-x: scroll;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.text-title {
  font-size: 0.875rem;
  font-weight: 600;
}
.table-btn {
  margin-top: 2rem !important;
}
</style>
