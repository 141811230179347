<template>
  <div>
    <modal1
      :show="showCreateRole && showModal"
      body-classes="p-1"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <!--<div class="card">-->
      <!-- Card header -->
      <div class="card-head">
        <!-- Title -->
        <!--<div class="row">-->
        <!--<div class="col-12">-->
        <article class="item-right">
          <!-- @click="displayDetail()">-->
          <a
            ><span @click="closeModal()"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                style="fill: #171717"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg> </span
          ></a>
        </article>
        <!--</div>-->
        <div class="py-2">
          <h5 class="h3 mb-0 text-center">Create Role</h5>
        </div>

        <!-- </div>-->
      </div>
      <!-- Card body -->
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col">
              <!-- Card body -->

              <form class="needs-validation" @submit.prevent="submit">
                <div class="form-row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <base-input
                      label="Role Name"
                      name="Role Name"
                      placeholder=""
                      required
                      v-model="model.roleName"
                      :class="{ disabled: Btn_display }"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <base-input
                      label="Limit"
                      name="limit"
                      type="number"
                      placeholder=""
                      required
                      v-model="model.limit"
                      :class="{ disabled: Btn_display }"
                    >
                    </base-input>
                  </div>
                </div>
                <div class="text-right">
                  <!--<input type="submit" value="Submit">-->
                  <base-button
                    id="submit1"
                    icon
                    type="primary"
                    native-type="submit"
                    :class="{ disabled: loading, display: Btn_display }"
                  >
                    <span class="btn-inner--text">Submit</span>
                    <span :class="{ 'spinner-border': loading }"></span>
                  </base-button>
                </div>
                <b-collapse id="collapse-4" v-model="visible" class="mt-2">
                  <!--<b-card>-->
                  <p class="mt-1 mb-1">
                    <code>Assign privileges to role</code>
                  </p>
                  <div class="form-row">
                    <!-- <div v-if="priArray.length !== 0">-->
                    <!-----------------Branch------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Branch</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000001"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Branch</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000002"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Branch</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000003"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Branch</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000004"
                          v-model="privilegeId"
                        />
                        <label for="mike">Branch Checker</label>
                      </div>
                    </div>

                    <!-----------------GL------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">GL</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000005"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create GL</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000006"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read GL</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000007"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update GL</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000008"
                          v-model="privilegeId"
                        />
                        <label for="mike">GL Checker</label>
                      </div>
                    </div>

                    <!-----------------Customer------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Customer</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000009"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Customer</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000010"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Customer</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000011"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Customer</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000012"
                          v-model="privilegeId"
                        />
                        <label for="mike">Customer Checker</label>
                      </div>
                    </div>

                    <!-----------------Internalaccount------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Internalaccount</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000013"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Internalaccount</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000014"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Internalaccount</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000015"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Internalaccount</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000016"
                          v-model="privilegeId"
                        />
                        <label for="mike">Internalaccount Checker</label>
                      </div>
                    </div>

                    <!-----------------Teller------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Teller</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000017"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Teller</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000018"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Teller</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000019"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Teller</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000020"
                          v-model="privilegeId"
                        />
                        <label for="mike">Teller Checker</label>
                      </div>
                    </div>

                    <!-----------------Till------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Till</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000021"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Till</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000022"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Till</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000023"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Till</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000024"
                          v-model="privilegeId"
                        />
                        <label for="mike">Till Checker</label>
                      </div>
                    </div>

                    <!-----------------Vault------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Vault</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000025"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Vault</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000026"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Vault</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000027"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Vault</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000028"
                          v-model="privilegeId"
                        />
                        <label for="mike">Vault Checker</label>
                      </div>
                    </div>

                    <!-----------------Report------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Report</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000029"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Report</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000030"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Report</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000031"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Report</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000032"
                          v-model="privilegeId"
                        />
                        <label for="mike">Report Checker</label>
                      </div>
                    </div>

                    <!-----------------User------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">User</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000033"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create User</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000034"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read User</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000035"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update User</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000036"
                          v-model="privilegeId"
                        />
                        <label for="mike">User Checker</label>
                      </div>
                    </div>

                    <!-----------------Role------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Role</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000037"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Role</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000038"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Role</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000039"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Role</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000040"
                          v-model="privilegeId"
                        />
                        <label for="mike">Role Checker</label>
                      </div>
                    </div>

                    <!-----------------Privilege------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Privilege</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000041"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Privilege</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000042"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Privilege</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000043"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Privilege</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000044"
                          v-model="privilegeId"
                        />
                        <label for="mike">Privilege Checker</label>
                      </div>
                    </div>

                    <!-----------------Journal------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Journal</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000045"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Journal</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000046"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Journal</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000047"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Journal</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000048"
                          v-model="privilegeId"
                        />
                        <label for="mike">Journal Checker</label>
                      </div>
                    </div>

                    <!-----------------Trncode------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Trncode</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000049"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Trncode</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000050"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Trncode</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000051"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Trncode</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000052"
                          v-model="privilegeId"
                        />
                        <label for="mike">Trncode Checker</label>
                      </div>
                    </div>

                    <!-----------------Product------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Product</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000053"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Product</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000054"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Product</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000055"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Product</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000056"
                          v-model="privilegeId"
                        />
                        <label for="mike">Product Checker</label>
                      </div>
                    </div>

                    <!-----------------Fundtransfer------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Fundtransfer</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000057"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Fundtransfer</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000058"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Fundtransfer</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000059"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Fundtransfer</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000060"
                          v-model="privilegeId"
                        />
                        <label for="mike">Fundtransfer Checker</label>
                      </div>
                    </div>

                    <!-----------------Cashtransaction------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Cashtransaction</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000061"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Cashtransaction</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000062"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Cashtransaction</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000063"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Cashtransaction</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000064"
                          v-model="privilegeId"
                        />
                        <label for="mike">Cashtransaction Checker</label>
                      </div>
                    </div>
                    <!-----------------Deposit------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Deposit</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000065"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Deposite</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000066"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Deposite</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000067"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Deposite</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000068"
                          v-model="privilegeId"
                        />
                        <label for="mike">Deposite Checker</label>
                      </div>
                    </div>
                    <!-----------------Withdrawal------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Withdrawal</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000069"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Withdrawal</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000070"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Withdrawal</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000071"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Withdrawal</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000072"
                          v-model="privilegeId"
                        />
                        <label for="mike">Withdrawal Checker</label>
                      </div>
                    </div>

                    <!-----------------Transaction------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Transaction</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000073"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Transaction</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000074"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Transaction</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000075"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Transaction</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000076"
                          v-model="privilegeId"
                        />
                        <label for="mike">Transaction Checker</label>
                      </div>
                    </div>
                    <!-----------------Loan------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Loan</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000077"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Loan</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000078"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Loan</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000079"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Loan</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000080"
                          v-model="privilegeId"
                        />
                        <label for="mike">Loan Checker</label>
                      </div>
                    </div>

                    <!-----------------Sms------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Sms</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000081"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Sms</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000082"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Sms</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000083"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Sms</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000084"
                          v-model="privilegeId"
                        />
                        <label for="mike">Sms Checker</label>
                      </div>
                    </div>

                    <!-----------------Email------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Email</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000085"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Email</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000086"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Email</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000087"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Email</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000088"
                          v-model="privilegeId"
                        />
                        <label for="mike">Email Checker</label>
                      </div>
                    </div>

                    <!-----------------Notification------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Notification</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000089"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Notification</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000090"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Notification</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000091"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Notification</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000092"
                          v-model="privilegeId"
                        />
                        <label for="mike">Notification Checker</label>
                      </div>
                    </div>

                    <!-----------------Eod------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Eod</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000093"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Eod</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000094"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Eod</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000095"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Eod</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000096"
                          v-model="privilegeId"
                        />
                        <label for="mike">Eod Checker</label>
                      </div>
                    </div>

                    <!-----------------Fixedassets------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Fixedassets</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000097"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Fixedassets</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000098"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Fixedassets</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000099"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Fixedassets</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000100"
                          v-model="privilegeId"
                        />
                        <label for="mike">Fixedassets Checker</label>
                      </div>
                    </div>

                    <!-----------------Monthlyfee------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Monthlyfee</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000101"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Monthlyfee</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000102"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Monthlyfee</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000103"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Monthlyfee</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000104"
                          v-model="privilegeId"
                        />
                        <label for="mike">Monthlyfee Checker</label>
                      </div>
                    </div>

                    <!-----------------Reversal------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Reversal</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000105"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Reversal</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000106"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Reversal</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000107"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Reversal</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000108"
                          v-model="privilegeId"
                        />
                        <label for="mike">Reversal Checker</label>
                      </div>
                    </div>

                    <!-----------------Apiconfiguration------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Apiconfiguration</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000109"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Apiconfiguration</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000110"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Apiconfiguration</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000111"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Apiconfiguration</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000112"
                          v-model="privilegeId"
                        />
                        <label for="mike">Apiconfiguration Checker</label>
                      </div>
                    </div>

                    <!-----------------Cards------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Cards</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000113"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Cards</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000114"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Cards</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000115"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Cards</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000116"
                          v-model="privilegeId"
                        />
                        <label for="mike">Cards Checker</label>
                      </div>
                    </div>

                    <!-----------------Upload_customer------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Upload_customer</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000121"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Upload_customer</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000122"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Upload_customer</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000123"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Upload_customer</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000124"
                          v-model="privilegeId"
                        />
                        <label for="mike">Upload_customer Checker</label>
                      </div>
                    </div>

                    <!-----------------Settings------------------------------>
                    <div class="col-sm-12">
                      <p class="module-title mt-1 mb-1">Settings</p>
                    </div>
                    <div class="col-sm-12">
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000117"
                          v-model="privilegeId"
                        />
                        <label for="mike">Create Settings</label>
                      </div>
                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000118"
                          v-model="privilegeId"
                        />
                        <label for="mike">Read Settings</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000119"
                          v-model="privilegeId"
                        />
                        <label for="mike">Update Settings</label>
                      </div>

                      <div class="inline">
                        <input
                          type="checkbox"
                          value="0000000120"
                          v-model="privilegeId"
                        />
                        <label for="mike">Settings Checker</label>
                      </div>
                    </div>
                    <!--<span>Checked names: {{ privilegeId }}</span>-->

                    <!--<div v-if="priArray.length !== 0">-->

                    <!-----------------Branch------------------------------>

                    <!--<div v-for="privilege in priObj">
          <div class="col-sm-12">
              <p class="module-title mt-1 mb-1"> {{privilege.privilegeModuleName}} </p>
          </div>
        <div class="col-sm-12">-->
                    <!-- <input type="checkbox" id="mike" value="Mike" v-model="privilegeId">
<label for="mike"> {{privilege.privilegeType +' '+privilege.privilegeModuleName}}</label>-->
                    <!--  <base-input group>
    <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="test">
     {{privilege.privilegeType +' '+privilege.privilegeModuleName}}
  </base-checkbox>
    <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="privilegeId">
      {{`${privilege.privilegeType}`}} {{`${privilege.privilegeModuleName}`}}
  </base-checkbox>
  <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="privilegeId">
     {{`${privilege.privilegeType}`}} {{`${privilege.privilegeModuleName}`}}
  </base-checkbox>
    <base-checkbox class="checkbox-inline custom-control-inline custom-control" v-model="privilegeId">
      {{`${privilege.privilegeType}`}} {{`${privilege.privilegeModuleName}`}}
  </base-checkbox>
            </base-input>
            </div>
        </div>-->
                  </div>
                  <!--</b-card>-->
                </b-collapse>

                <div class="text-right">
                  <!--<input type="submit" value="Submit">-->
                  <base-button
                    id="submit2"
                    icon
                    type="primary"
                    @click="submitPrivilege"
                    :class="{ disabled: loading, display: !Btn_display }"
                  >
                    <span class="btn-inner--text">Submit</span>
                    <span :class="{ 'spinner-border': loading }"></span>
                  </base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal1>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import Loader from "@/components/Loader";
import { mapState, mapActions, mapGetters } from "vuex";
import Loaders from "../../components/Loader.vue";
export default {
  components: {
    //BaseHeader,
    // Loaders
  },
  props: {
    showCreateRole: Boolean,
  },

  data() {
    return {
      data: {},
      showModal: true,
      privilegeId: [],
      visible: false,
      //Btn_display:false,
      model: {
        roleName: "",
        limit: "",
      },
    };
  },
  methods: {
    async submitPrivilege() {
      if (this.privilegeId.length !== 0) {
        var payload = {
          rpPrivilegeId: [],
        };
        var privilegeObj = {};
        var newArr = [];
        for (let i = 0; i < this.privilegeId.length; i++) {
          privilegeObj = {
            privilegeId: this.privilegeId[i],
          };
          payload["rpPrivilegeId"].push(privilegeObj);
          // newArr.push(privilegeObj);
          console.log(payload)
          //console.log(`>>>>>>>>>>>>>>>${JSON.stringify(newArr[i])}`);
        }
        // payload['rpPrivilegeId'].push(newArr);
        payload.rpRoleId =this.response.roleId;
        payload.rpMaker = this.userInformation.role.roleMaker;
        payload.rpBankCode = this.userInformation.role.roleBankCode;
        payload.action = "create";
        console.log("Payload ", JSON.stringify)
        console.log(`this is ${this.response}`)
        this.$store.dispatch("userRolePrivilegeCreate", payload, {
         root: false,
        });
        this.model.roleName = "";
        this.model.limit = "";
        this.privilegeId = [];
      } else {
        this.notifyVue("danger", "Please select at least one privilege");
      }
    },
    async submit() {
      var payload = {};
      payload.roleName = this.model.roleName;
      payload.roleMaker = this.userInformation.role.roleMaker;
      payload.roleBankCode = this.userInformation.role.roleBankCode;
      payload.roleTrnLimit = this.model.limit;
      //console.log(`>>>>>>>>>>>>>>>${JSON.stringify(newArr)}`);
      // console.log(`???????????${JSON.stringify(this.userInformation.role)}`);
      // console.log(`All length ${JSON.stringify(this.priObj.responseCode)}`);
      // console.log(`///////Payload ${JSON.stringify(payload)}`);
      this.$store.dispatch("roleCreate", payload, { root: false });
    },
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    async callapi() {
      this.$store.dispatch(
        "privilageRead",
        {
          readAll: "YES",
        },
        { root: false }
      );
    },
    getUserInformation() {
      this.data = this.$store.getters.getPrivilageResponse;
    },
    setValue() {
      this.data = this.$store.getters.getPrivilageResponse;
      console.log("&$$$$$&" + this.data.data[0].privilageModuleName);
    },
    closeModal() {
      console.log("Closing Moddalslslslsl");
      this.showModal = false;
      this.$emit("closeCreateRole", false);
      this.showModal = true;
    },
  },
  computed: {
    ...mapState({
      priObj: (state) => state.privilages.privilage,
      userInformation: (state) => state.auth.userInfo,
      errors: (state) => state.role.role_errors,
      loading: (state) => state.role.role_loading,
      success: (state) => state.role.role_success,
      success1: (state) => state.role.role_success1,
      Btn_display: (state) => state.role.role_btn_display,
      response: (state) => state.role.role_response1,
    }),
    ...mapActions(["privilageRead", "userRolePrivilegeCreate", "roleCreate"]),
    ...mapGetters(["getPrivilageResponse"]),
    /*btn_Style2: function(){
        return{role_btn_display
            disabled:loading,display:none
        }
    },*/
  },
  watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success1(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        this.closeModal();
      }
    },
    Btn_display(newValue, oldValue) {
      this.visible = newValue === true ? true : false;
      /*
        if (newValue === true) {
        this.visible=true;
      }*/
    },
  },
  updated() {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
  },
  created: function () {
    //this.$store.dispatch("privilageRead", {
    //     readAll: "YES"} , { root: false });
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data[0].privilageModuleName);
  },

  mounted: function () {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data);
    this.$store.dispatch(
      "privilageRead",
      {
        readAll: "YES",
      },
      { root: false }
    );
    this.data = this.priObj;
    console.log(`All length ${JSON.stringify(this.data)}`);
  },
  beforeCreate: function () {
    //console.log("Hello world");
    ///this.$nextTick(function () {
    //this.callapi();
    //})
  },
  /*beforeRouteEnter(to, from, next) {
    //ledgerBalance((err, balance) => {
     // next(vm => vm.callapi());

  },
  beforeRouteUpdate (to, from, next) {
    //this.post = null
    //getPost(to.params.id, (err, post) => {
     // setValue();
     // next()
   // })
  },*/
};
</script>
<style scoped>
.display {
  display: none;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

label {
  font-weight: 300 !important;
  color: black;
}
.inline {
  display: inline !important;
  margin-right: 1.5rem;
}
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.21875rem;
  padding-right: 2.5rem;
}
.module-title {
  font-size: 12px !important;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 5px !important;
  margin-top: 10px !important;
  color: #556ee6;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

.item-right {
  padding-left: 100%;
  cursor: pointer;
  margin: 0px 0% -10px 0px;
  float: right !important;
}
.card-head:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.card-head {
  padding: 0.25rem 0.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
</style>

