<template>
  <div>
    <notifications></notifications>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Create Account Entry</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <router-link to="/"><i class="fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item"><a href="#">Product</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Create Account Entry
              </li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div v-if="option_product.length !== 0">
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Create Account Entry</h3>

              <!-- Card body -->

              <form
                id="createAccountEntry"
                @submit.prevent="createAccountingEntry"
              >
                <!------------------------------Line1-------------------------------->
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <base-input
                      label="Account Entry Code"
                      name="Account Entry Code"
                      placeholder="Account Entry Code"
                      required
                      v-model="model.entryCode"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <base-input
                      label="Account Entry Description"
                      name="Account Entry Description"
                      placeholder="Account Entry Description"
                      required
                      v-model="model.entryDescription"
                    >
                    </base-input>
                  </div>
                </div>
                <!------------------------------End of Line1-------------------------------->

                <!------------------------------Line6-------------------------------->
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <base-input label="Charge Product">
                      <el-select
                        class="select-danger"
                        clearable
                        filterable
                        placeholder="Charge Product"
                        v-model="model.chargeProduct"
                      >
                        <el-option
                          v-for="option in option_product"
                          class="select-danger"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value"
                          required
                        >
                        </el-option>
                      </el-select>
                      <p v-show="chargeCtrl" class="form-error-message">
                        The Charge Product is required
                      </p>
                    </base-input>
                  </div>
                </div>
                <!------------------------------End of Line6-------------------------------->

                <div class="text-right">
                  <base-button
                    icon
                    type="primary"
                    native-type="submit"
                    :class="{ disabled: loading_product }"
                  >
                    <span class="btn-inner--text">Create Account Entry</span>
                    <span :class="{ 'spinner-border': loading_product }"></span>
                  </base-button>
                  <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
                </div>
              </form>
            </card>
          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import country from "@/components/country";
import vSelect from "vue-select";
import { Select, Option, DatePicker } from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from "@/components/LoadingPanel";
export default {
  components: {
    BaseHeader,
    LoadingPanel,
    //vSelect,
    //[Select.name]: Select,
    //[Option.name]: Option
  },
  data() {
    return {
      //selected_branchCode:{}, productCreateNew
      option_product: [],
      chargeCtrl: false,
      inputRequired: true,
      model: {
        chargeProduct: "",
        entryCode: "",
        entryDescription: "",
      },
    };
  },
  methods: {
    resetForm() {
      //console.log(`>>><<<${this.nameCtrl}`);
      this.inputRequired = true;
    },
    createAccountingEntry() {
      if (this.validateSelect) {
        this.$store.dispatch(
          "createAccountingEntry",
          {
            aeBankCode: this.userInformation.userBankCode,
            aeChargeCode: this.model.chargeProduct,
            aeCode: this.model.entryCode,
            aeDescription: this.model.entryDescription,
            aeMaker: this.userInformation.userId,
            source: "web",
          },
          { root: false }
        );
        document.getElementById("createAccountEntry").reset();
      }
    },

    validateSelect() {
      // this.imageValidator();
      if (this.model.chargeProduct === "") {
        this.chargeCtrl = true;
      } else {
        this.chargeCtrl = false;
      }

      return !this.chargeCtrl;
    },
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
  },
  watch: {
    response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if (Object.keys(this.response_product).length !== 0) {
        var product = [];
        product = this.response_product.data;
        this.option_product = product.map((item) => {
          return {
            value: `${item.Code}`,
            label: `${item.Code} (${item.Description})`,
          };
        });
        console.log("//////////");
        //console.log("&$$$$$&"+this.option_branch);
      }
    },

    error_product(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    success_product(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        this.$router.push({ name: "AssignEntries" });
        //this.$route.push({name:'AssignEntries'});
        //this.showDismissibleAlert=true;
      }
    },
  },
  mounted: function () {
    this.$store.dispatch(
      "readProduct",
      {
        productBankCode: this.userInformation.userBankCode,
        productType: "CH",
      },
      { root: false }
    );
  },
  computed: {
    ...mapState({
      //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
      //success: state => state.role.role_success,
      userInformation: (state) => state.auth.userInfo,
      response_product: (state) => state.product.product_response,
      loading_product: (state) => state.product.product_loading,
      success_product: (state) => state.product.product_success,
      error_product: (state) => state.product.product_errors,
    }),
    ...mapActions(["readProduct"]),
    ...mapGetters([""]),
  },
};
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.form-error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  height: calc(1.5em + 1.25rem + 5px);
  line-height: 1.5;
  width: 100%;
}
</style>
