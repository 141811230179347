<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Create Account</h6>
<!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
<!--            <ol class="breadcrumb breadcrumb-links">-->
<!--              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>-->
<!--              <li class="breadcrumb-item"><a href="#">customer</a></li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">Create Account</li>-->
<!--            </ol>-->
<!--          </nav>-->
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
            <div v-if="option_product.length!==0">
          <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Create Customer</h3>

    <!-- Card body -->
    <!-------------------Form1---------------------->
    <form v-if="form==='form1'" @click="resetForm"
          @submit.prevent="submitForm1">

<div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label=" Customer Type ">
                      <el-select class="select-danger" filterable
             placeholder="Customer Type"
             v-model="form1.customerType">
    <el-option v-for="option in [{value:'BUSINESS',label:'BUSINESS'},{value:'CUSTOMER',label:'PERSONAL'}]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="cusTypeCtrl" class="form-error-message"> Customer Type is Required' </p>
                    </base-input>
        </div>
             <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Account Class">
                      <el-select class="select-danger" filterable
             placeholder="Account Class"
             v-model="form1.accountClass">
    <el-option v-for="option in option_product"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value"
               required
               >
    </el-option>
  </el-select>
  <p v-show="accClassCtrl" class="form-error-message"> The Account Class is required </p>
                    </base-input>
        </div>
</div>
<!---------------------------------------------------------------->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label=" Account Type ">
                      <el-select class="select-danger" filterable
             placeholder="Account Type"
             v-model="form1.accountType">
    <el-option v-for="option in [{value:'SAVINGS',label:'SAVINGS'},{value:'CURRENT',label:'CURRENT'}]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="accTypeCtrl" class="form-error-message"> Account Type is Required' </p>
                    </base-input>
        </div>
        </div>

<div class="row">
      <div class="col-12 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_customer}">
   <span class="btn-inner--text">Next</span>
       <span :class="{'spinner-border': loading_customer}"></span>
     </base-button>

      </div>
</div>

  </form>
    <!-------------------End of Form1---------------------->
    <!---------------------Account Type=Personal------------>
    <!-------------------Form2---------------------->
    <form v-else-if="form==='form2'" @click="resetForm"
          @submit.prevent="submitForm2">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 col-12">
          <base-input label="First Name"
                      name="First Name"
                      placeholder="First Name"
                      :required='inputRequired'
                      v-model="form2.customerFirstName">
          </base-input>
        </div>
         <div class="col-lg-4 col-md-4 col-sm-4 col-12">
          <base-input label="Middle Name"
                      name="Middle Name"
                      placeholder="Middle Name"
                      :required='inputRequired'
                      v-model="form2.customerMiddleName">
          </base-input>
        </div>
         <div class="col-lg-4 col-md-4 col-sm-4 col-12">
          <base-input label="Last Name"
                      name="Last Name"
                      placeholder="Last Name"
                      :required='inputRequired'
                      v-model="form2.customerLastName">
          </base-input>
        </div>

            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Customer BVN"
                      name="Customer BVN"
                      type ="number"
                      placeholder="Customer BVN"
                      :required='inputRequired'
                      v-model="form2.customerBVN">
          </base-input>
        </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                 <label class="form-control-label"> Date of Birth</label>
  <datetime
        v-model="form2.customerDob" input-class="form-control" class="theme-green"
        placeholder="DOB"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="minDatetime" :max-datetime="maxDatetime"></datetime>
        <p v-show="dobCtrl" class="form-error-message"> The Date of Birth is required </p>
            </div>
            </div>
            <!---------------------------------------->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Email"
                      name="Email"
                      placeholder="Email"
                      :required='inputRequired'
                      v-model="form2.customerEmail">
          </base-input>
        </div>
             <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Currency">
                      <el-select class="select-danger" filterable
             placeholder="Currency"
             v-model="form2.customerCurrency">
    <el-option v-for="option in option_currency"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="currencyCtrl" class="form-error-message"> The Currency is required </p>
                    </base-input>
        </div>
        </div>
        <!---------------------------------------->
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <base-input label="Customer Picture">
          <b-form-file accept="image/*" id="myfile1" @change="handleImage"
      v-model="form2.customerPicture"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>
    <p v-show="picCtrl" class="form-error-message"> The Customer Picture is required </p>
                </base-input>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <base-input label="Customer Mandate">
          <b-form-file accept="image/*" id="myfile2" @change="handleImage1"
      v-model="form2.customerMandate"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>
    <p v-show="manCtrl" class="form-error-message"> The Customer Mandate is required </p>
                </base-input>

        </div>
        </div>

<div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="backTo('form1')" >
            Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_customer}">
   <span class="btn-inner--text">Next</span>
       <span :class="{'spinner-border': loading_customer}"></span>
     </base-button>

      </div>
</div>

    </form>
    <!-------------------End of Form2---------------------->
    <!-------------------Form3---------------------->
    <form v-else-if="form==='form3'" @click="resetForm"
          @submit.prevent="submitForm3">

         <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Phone Number"
                      name="Phone Number"
                      placeholder="Phone Number"
                      type="number"
                      :required='inputRequired'
                      v-model="form3.customerPhoneNumber">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Country">
                      <el-select class="select-danger" filterable
             placeholder="Country"
             v-model="form3.customerCountry">
    <el-option v-for="option in option_country"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="countryCtrl" class="form-error-message"> The Country is required </p>
                    </base-input>
        </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Customer State"
                      name="Customer State"
                      placeholder="Customer State"
                      :required='inputRequired'
                      v-model="form3.customerState">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Customer City"
                      name="Customer City"
                      placeholder="Customer City"
                      :required='inputRequired'
                      v-model="form3.customerCity">
          </base-input>
        </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Customer Address"
                      name="Customer Address"
                      placeholder="Customer Address"
                      :required='inputRequired'
                      v-model="form3.customerAddress">
          </base-input>
        </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Alternative Phone Number"
                      name="Alternative Phone Number"
                      type="number"
                      placeholder="Alternative Phone Number"
                      v-model="form3.customerAlternatePhone">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Next of Kin"
                      name="Next of Kin"
                      placeholder="Next of Kin"
                      :required='inputRequired'
                      v-model="form3.customerNextKin">
          </base-input>
        </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Phone Number of Next of Kin"
                      name="Phone Number of Next of Kin"
                      type="number"
                      placeholder="Phone Number of Next of Kin"
                      v-model="form3.customerKinPhone">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Address of Next of Kin"
                      name="Address of Next of Kin"
                      placeholder="Address of Next of Kin"
                      :required='inputRequired'
                      v-model="form3.customerKinAddress">
          </base-input>
        </div>
        </div>

        <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="backTo('form2')" >
            Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_customer}">
   <span class="btn-inner--text">Submit</span>
       <span :class="{'spinner-border': loading_customer}"></span>
     </base-button>

      </div>
</div>
        </form>

    <!-------------------End of Form3---------------------->


    <!---------------------End of Account Type=Personal--->


    <!---------------------Account Type=Business------------>
    <!-------------------Form4---------------------->
    <form v-else-if="form==='form4'" @click="resetForm"
          @submit.prevent="submitForm4">
          <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Business Name"
                      name="Business Name"
                      placeholder="Business Name"
                      :required='inputRequired'
                      v-model="form4.businessName">
          </base-input>
        </div>
          </div>
            <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label=" Company Type ">
                      <el-select class="select-danger" filterable
             placeholder="Company Type"
             v-model="form4.companyType">
    <el-option v-for="option in [{value:'FINTECH',label:'FINTECH'},{value:'OTHERS',label:'OTHERS'}]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  <p v-show="comTypeCtrl" class="form-error-message"> Company Type is Required' </p>
                    </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                 <label class="form-control-label"> Date of Incorporation</label>
  <datetime
        v-model="form4.businessDOI" input-class="form-control" class="theme-green"
        placeholder="Date of Incorporation"  zone="Africa/Lagos" value-zone="Africa/Lagos" :min-datetime="minDatetime" :max-datetime="maxDatetime"></datetime>
        <p v-show="doiCtrl" class="form-error-message"> The Date of Incorporation is required </p>
            </div>

          </div>

          <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Incorporation Number"
                      name="Incorporation Number"
                      placeholder="Incorporation Number"
                      :required='inputRequired'
                      v-model="form4.incorpNumber">
          </base-input>
        </div>
          </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Email"
                      name="Email"
                      placeholder="Email"
                      :required='inputRequired'
                      v-model="form4.email">
          </base-input>
        </div>
             <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Currency">
                      <el-select class="select-danger" filterable
             placeholder="Currency"
             v-model="form4.currency">
    <el-option v-for="option in option_currency"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="currCtrl" class="form-error-message"> The Currency is required </p>
                    </base-input>
        </div>
    </div>

<div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="backTo('form1')" >
            Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button  icon type="primary" native-type="submit" :class="{disabled:loading_customer}">
   <span class="btn-inner--text">Next</span>
       <span :class="{'spinner-border': loading_customer}"></span>
     </base-button>

      </div>
</div>


    </form>
    <!-------------------End of Form4---------------------->
    <form v-else-if="form==='form5'" @click="resetForm"
          @submit.prevent="submitForm5">

         <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Phone Number"
                      name="Phone Number"
                      placeholder="Phone Number"
                      type="number"
                      :required='inputRequired'
                      v-model="form5.phoneNumber">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Country">
                      <el-select class="select-danger" filterable
             placeholder="Country"
             v-model="form5.country">
    <el-option v-for="option in option_country"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <p v-show="couCtrl" class="form-error-message"> The Country is required </p>
                    </base-input>
        </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Customer State"
                      name="Customer State"
                      placeholder="Customer State"
                      :required='inputRequired'
                      v-model="form5.state">
          </base-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <base-input label="Customer City"
                      name="Customer City"
                      placeholder="Customer City"
                      :required='inputRequired'
                      v-model="form5.city">
          </base-input>
        </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="Customer Address"
                      name="Customer Address"
                      placeholder="Customer Address"
                      :required='inputRequired'
                      v-model="form5.address">
          </base-input>
        </div>
        </div>
        <div class="row">
        <div class="col-6 text-left">
          <base-button type="default" @click="backTo('form4')" >
            Back
     </base-button>
      </div>
      <div class="col-6 text-right">
          <base-button  icon type="primary" native-type="submit" :class="{disabled:loading_customer}">
   <span class="btn-inner--text">Submit</span>
       <span :class="{'spinner-border': loading_customer}"></span>
     </base-button>

      </div>
</div>

    </form>

    <!---------------------End of Account Type=Business--->

  </card>
  </div>
    <div v-else>
              <LoadingPanel></LoadingPanel>
          </div>
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import country from '@/components/country';
import vSelect from 'vue-select';
import { Select, Option, DatePicker } from 'element-ui';
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import { Datetime } from 'vue-datetime';
export default {

    components: {
        BaseHeader,
       LoadingPanel,
       Datetime
        //vSelect,
        //[Select.name]: Select,
        //[Option.name]: Option
    },
    data() {
      return {
          //selected_branchCode:{}, customerCreateNew
          option_user:[],
          option_country:[],
          option_currency:[{value:'NGN',label:'NGN'},{value:'GBP',label:'GBP'},{value:'USD',label:'USD'}],
          option_product:[],
          //option_branch:[{value:'00001',label:'VI'},{value:'00002',label:'Ikeja'}],
          //form1
          cusTypeCtrl:false,
          accClassCtrl:false,
          accTypeCtrl:false,
          //form2
          dobCtrl:false,
          currencyCtrl:false,
          picCtrl:false,
          manCtrl:false,
          //form3
          countryCtrl:false,
          //form4
          comTypeCtrl:false,
          doiCtrl:false,
          //form5
          couCtrl:false,

          form:'form1',

        inputRequired:true,
          minDatetime:'1950-01-01',
      maxDatetime:'',
        form1:{
            customerType:'',
            accountType:'',
            accountClass:''
        },
        form2:{
            customerFirstName:'',
            customerMiddleName:'',
            customerLastName:'',
            customerBVN:'',
            customerDob:'',
            customerEmail:'',
            customerCurrency:'',
            customerPicture:[],
            customerMandate:[]
        },
        form3:{
            customerPhoneNumber:'',
            customerCountry:'',
            customerState:'',
            customerCity:'',
            customerAddress:'',
            customerAlternatePhone:'',
            customerNextKin:'',
            customerKinPhone:'',
            customerKinAddress:''
        },
        form4:{
            businessName:'',
            companyType:'',
            businessDOI:'',
            incorpNumber:'',
            email:'',
            currency:''
        },
        form5:{
            phoneNumber:'',
            country:'',
            state:'',
            city:'',
            address:''
        },

      }
    },
    methods: {
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },

handleImage(e){
    const selectedImage = e.target.files[0];
    this.createBase64Image(selectedImage);
},
createBase64Image(fileObject){
    const reader = new FileReader();
    reader.onload=(e)=>{
        this.form2.customerPicture[0]=e.target.result;
    }
    reader.readAsDataURL(fileObject);
},

handleImage1(e){
    const selectedImage = e.target.files[0];
    this.createBase64Image1(selectedImage);
},
createBase64Image1(fileObject){
    const reader = new FileReader();
    reader.onload=(e)=>{
        this.form2.customerMandate[0]=e.target.result;
    }
    reader.readAsDataURL(fileObject);
},

formatDate(date){
          return date.split('-')[2]+'-'+this.convert(date.split('-')[1])+'-'+date.split('-')[0];
      },
      convert(str){
          var res ='';
          switch (str) {
              case '01':
              case '1':
                  res = 'JAN';
                  break;
              case '02':
              case '2':
                  res = 'FEB';
                  break;
              case '03':
              case '3':
                  res = 'MAR';
                  break;
              case '04':
              case '4':
                  res = 'APR';
                  break;
              case '05':
              case '5':
                  res = 'MAY';
                  break;
             case '06':
            case '6':
                  res = 'JUN';
                  break;
            case '07':
              case '7':
                  res = 'JUL';
                  break;
            case '08':
            case '8':
                  res = 'AUG';
                  break;
            case '09':
            case '9':
                  res = 'SEP';
                  break;
            case '10':
                  res = 'OCT';
                  break;
            case '11':
                  res = 'NOV';
                  break;
            case '12':
                  res = 'DEC';
                  break;
              default:
                  res=str;
                  break;
          }
          return res;
      },

validateForm1(){
    if(this.form1.customerType===""){
              this.cusTypeCtrl=true;
          }
          else{
              this.cusTypeCtrl=false;
    }
    if(this.form1.accountType===""){
              this.accTypeCtrl=true;
          }
          else{
              this.accTypeCtrl=false;
    }
     if(this.form1.accountClass===""){
              this.accClassCtrl=true;
          }
          else{
              this.accClassCtrl=false;
    }
    return !this.cusTypeCtrl && !this.accTypeCtrl && !this.accClassCtrl
},

validateForm2(){
    if(this.form2.customerDob===""){
              this.dobCtrl=true;
          }
          else{
              this.dobCtrl=false;
    }
    if(this.form2.customerCurrency===""){
              this.currencyCtrl=true;
          }
          else{
              this.currencyCtrl=false;
    }
     if( document.getElementById("myfile1").files.length == 0 ){
   this.picCtrl=true;
}
else{
              this.picCtrl=false;
          }
           if( document.getElementById("myfile2").files.length == 0 ){
   this.mandCtrl=true;
}
else{
              this.mandCtrl=false;
          }
    return !this.dobCtrl && !this.currencyCtrl && !this.picCtrl && !this.manCtrl
},

validateForm3(){
    if(this.form3.customerCountry===""){
              this.countryCtrl=true;
          }
          else{
              this.countryCtrl=false;
    }
    return !this.countryCtrl;
},
validateForm4(){
    if(this.form4.companyType===""){
              this.comTypeCtrl=true;
          }
          else{
              this.comTypeCtrl=false;
    }
     if(this.form4.businessDOI===""){
              this.doiCtrl=true;
          }
          else{
              this.doiCtrl=false;
    }
    return !this.comTypeCtrl && !this.doiCtrl;
},

validateForm5(){
    if(this.form4.country===""){
              this.couCtrl=true;
          }
          else{
              this.couCtrl=false;
    }

    return !this.couCtrl;
},
dateFormating(date){
    let mon = date.split("-")[1]
    let day = date.split("-")[0]
    let year = date.split("-")[2]
    return mon+','+day+','+year;
},
inMonths(d1, d2) {
        var d1Y = d1.getFullYear();
        var d2Y = d2.getFullYear();
        var d1M = d1.getMonth();
        var d2M = d2.getMonth();

        return (d2M+12*d2Y)-(d1M+12*d1Y);
    },
calculateAge(str){
    var d1 = new Date(str);
    var d2 = new Date();
    let age=this.inMonths(d1,d2);
    return (age<12)?parseInt(age):parseInt(age/12);

},

backTo(str){
    this.form=str;
    this.inputRequired=false;
},

submitForm1(){
    if(this.validateForm1()){
        this.form=(this.form1.customerType==='CUSTOMER')?'form2':'form4';
        this.inputRequired=false;
    }
},
submitForm2(){
    if(this.validateForm2()){
        this.form='form3';
        this.inputRequired=false;
    }
},
submitForm3(){
    if(this.validateForm3()){
        this.$store.dispatch("customerCreateNew",{
accountClass: this.form1.accountClass,
accountCurrency: this.form2.customerCurrency,
accountType: this.form1.accountType,
customerAddress: this.form3.customerAddress,
customerAge: this.calculateAge(this.formatDate(String(this.form2.customerDob).split("T")[0])),
customerBankCode: this.userInformation.userBankCode,
customerBranchCode:  this.userInformation.userBranchCode,
customerBusinessName:"",
customerBvn: this.form2.customerBVN,
customerCity: this.form3.customerCity,
customerCompanyType: "",
customerCountry:this.form3.customerCountry,
customerCountryCode: "234",
customerDob: this.formatDate(String(this.form2.customerDob).split("T")[0]),
customerEmail: this.form2.customerEmail,
customerFirstName: this.form2.customerFirstName,
customerIncorporationDate: "",
customerIncorporationNumber: "",
customerLastName: this.form2.customerLastName,
customerMaker: this.userInformation.userId,
customerMiddleName: this.form2.customerMiddleName,
customerNextOfKinAddress: this.form3.customerKinAddress,
customerNextOfKinName: this.form3.customerNextKin,
customerNextOfKinPhoneNumber: this.form3.customerKinPhone,
customerOtherPhoneNumber: this.form3.customerAlternatePhone,
customerPhoneNumber: this.form3.customerPhoneNumber,
customerState: this.form3.customerState,
customerType: this.form1.customerType,
customerPicture : this.form2.customerPicture[0],
customerMandate : this.form2.customerMandate[0],
pictureFileName : "picture_"+this.form3.customerPhoneNumber,
mandateFileName : "mandate_"+this.form3.customerPhoneNumber,

        }, { root: false });
        this.inputRequired=false;
    }
},
submitForm4(){
    if(this.validateForm4()){
        this.form='form5';
        this.inputRequired=false;
    }
},
submitForm5(){
    if(this.validateForm5()){
        this.$store.dispatch("customerCreate",{
accountClass: this.form1.accountClass,
accountCurrency: this.form4.currency,
accountType: this.form1.accountType,
customerAddress: this.form5.address,
customerAge: null,
customerBankCode: this.userInformation.userBankCode,
customerBranchCode:  this.userInformation.userBranchCode,
customerBusinessName:this.form4.businessName,
customerBvn: "",
customerCity: this.form5.city,
customerCompanyType: this.form4.companyType,
customerCountry:this.form5.country,
customerCountryCode: "234",
customerDob: "",
customerEmail: this.form4.email,
customerFirstName: "",
customerIncorporationDate: this.formatDate(String(this.form4.businessDOI).split("T")[0]),
customerIncorporationNumber: this.form4.incorpNumber,
customerLastName: "",
customerMaker: this.userInformation.userId,
customerMiddleName: "",
customerNextOfKinAddress: "",
customerNextOfKinName: "",
customerNextOfKinPhoneNumber:"",
customerOtherPhoneNumber: this.form5.phoneNumber,
customerPhoneNumber: this.form5.phoneNumber,
customerState: this.form5.state,
customerType: this.form1.customerType,


        }, { root: false });
        this.inputRequired=false;
    }
},


notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{

        response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code} (${item.Description})`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);

    }
        },
        response_user(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_user).length !== 0){
    var user = [];
    user = this.response_user.data;
    this.option_user = user.map((item) => {
            return {
                value: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`,
                label: `(${item.userId}) ${item.userFirstName} ${item.userMiddleName} ${item.userLastName}`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);

    }
        },
        success_customer(newValue, oldValue) {
     console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        this.form='form1';
        this.form1={
            customerType:'',
            accountType:'',
            accountClass:''
        };
        this.form2={
            customerFirstName:'',
            customerMiddleName:'',
            customerLastName:'',
            customerBVN:'',
            customerDob:'',
            customerEmail:'',
            customerCurrency:'',
            customerPicture:[],
            customerMandate:[]
        },
        this.form3={
            customerPhoneNumber:'',
            customerCountry:'',
            customerState:'',
            customerCity:'',
            customerAddress:'',
            customerAlternatePhone:'',
            customerNextKin:'',
            customerKinPhone:'',
            customerKinAddress:''
        };
        this.form4={
            businessName:'',
            companyType:'',
            businessDOI:'',
            incorpNumber:'',
            email:'',
            currency:''
        };
        this.form5={
            phoneNumber:'',
            country:'',
            state:'',
            city:'',
            address:''
        };
        //this.showDismissibleAlert=true;

      }
    },
    error_customer(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    ,
    error_branch(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
    let countries = country;
    this.option_country = countries.map((item) => {
            return {
                value: `${item.name}`,
                label: `${item.name}`
            };
        });
    this.$store.dispatch("userRead", {
              readAll: "YES",
              source:'CBA',
              userBankCode:this.userInformation.userBankCode,
              } , { root: false });

let payload = {
            product:{
                productBankCode: this.userInformation.userBankCode,
                productType: "AC"
            },
            gl:{
                glLevel2BankCode: this.userInformation.userBankCode,
                readAll: "NO"
            }
        };

    this.$store.dispatch("productRead",payload, { root: false });
    var dateObj = new Date();
var month = (String(dateObj.getUTCMonth() + 1).length===1)?'0'+(dateObj.getUTCMonth() + 1):(dateObj.getUTCMonth() + 1); //months from 1-12
var day = String(dateObj.getUTCDate().length===1)?'0'+dateObj.getUTCDate():dateObj.getUTCDate();
var year = dateObj.getUTCFullYear();
var newdate = year + "-" + month + "-" + day;
this.maxDatetime=newdate;

    },
    computed:{
           ...mapState({
               //errors: state => state.role.role_errors,loading: state => state.role.role_loading,
    //success: state => state.role.role_success,
    userInformation: state => state.auth.userInfo,
    response_product: state => state.product.product_response,
    response_user: state => state.user.user_response,
    response_branch: state => state.customer.customer_response, loading_customer: state => state.customer.customer_loading,
    success_customer: state => state.customer.customer_success,error_customer: state => state.customer.customer_errors,
    error_user: state => state.user.user_error

    }),
    ...mapActions(['userRead','productRead','customerCreate']),
    ...mapGetters([""]),

    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}
</style>
