<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">All Roles</h6>
          <!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
          <!--            <ol class="breadcrumb breadcrumb-links">-->
          <!--              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>-->
          <!--              <li class="breadcrumb-item"><a href="#">Role</a></li>-->
          <!--              <li class="breadcrumb-item active" aria-current="page">All Roles</li>-->
          <!--            </ol>-->
          <!--          </nav>nav-->
        </div>
        <div class="col-lg-6 col-5 text-right">
          <button size="md" outline @click="show = true" class="btn">
            <i class="fas fa-plus"></i> New Role
          </button>
          <!--<base-button size="sm" type="neutral">New Roles</base-button>
         <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <span :class="{ 'spinner-border': loading }"></span>

      <PageinatedTables></PageinatedTables>
      <CreateRole
        @closeCreateRole="updateCreateRole"
        :showCreateRole="show"
      ></CreateRole>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader";
import PageinatedTables from "./PaginatedTables";
import CreateRole from "./CreateRole";
import { mapState } from "vuex";
export default {
  components: {
    BaseHeader,
    CreateRole,
    PageinatedTables,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    updateCreateRole(value) {
      this.show = value;
    },

    computed: {
      ...mapState({
        loading: (state) => state.role.role_loading,
      }),
    },
  },
};
</script>
<style scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.btn {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  color: #175d85 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 21px !important;
}

.btn:hover {
  color: #000 !important;
}
</style>
