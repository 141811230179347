<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Edit Batch Entries</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Batch</a></li>
              <li class="breadcrumb-item active" aria-current="page">Edit Batch Entries</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
          
        <div class="col">
            <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Edit Batch Entries</h3>

    <!-- Card body -->
    
   <!-- <div class="form-body">-->
        <div class="row">
            <div class="col-sm-8">
                      <div class="table-section">
    <!-- User Interface controls -->
    <b-row>
      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table  striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      head-variant="light"
     
    >
      
      <template #cell(drCr)="row">
        <div> 
            <div v-if="row.value==='CR'">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-arrow-up-circle-fill svg-green" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
</svg>
</div>
<div v-else>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"  class="bi bi-arrow-down-circle-fill svg-red" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg>
</div>
        </div>
      </template>

   <template #cell(action)="row">
        <div class="delete" @click="deleteItem(row.item,row.index)">
        <el-tooltip content="Delete" placement="left">

          <!--<i class="ni ni-zoom-split-in"></i>
          <span class="ml-1">Info</span>-->
        <i class="fas fa-trash-alt" style="color:red"></i>
        </el-tooltip>
        </div>
      </template>  

      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>
        
</div>
            </div>
            <div class="col-sm-4">
                <form id="submitForm" @submit.prevent="submit">
                    <div class="row">
                        <div class="col-sm-12">
                            <base-input label="Type">
                      <el-select class="select-danger" clearable
             placeholder="Type"  @change="onChangeType()"
             v-model="model.type">
    <el-option v-for="option in [{value:'DR',label:'Debit'},{value:'CR',label:'Credit'}]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value"
               required
               >
    </el-option>
  </el-select>
  <p v-show="typeCtrl" class="form-error-message"> The Type is required </p>
                    </base-input>
                        </div>

              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <label for="Amount" class="form-control-label">Account Number</label>
          <input type="number" v-model="model.accountNumber" placeholder="Entry Account Number" class="form-control" required>

        </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <label for="Amount" class="form-control-label">Amount</label>
          <input  type="number" step=0.01 v-model="model.amount" placeholder="Entry Amount" class="form-control" required>
        </div>
     <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <label for="Amount" class="form-control-label">Narration</label>
          <textarea class="form-control" v-model="model.narration" rows="3" required></textarea>
        </div>

                    </div>
                    <p class="text-right mt-3">
                        <span>
                            <button type="submit" class="btn btn-secondary mr-1"> Add Entry </button></span></p>
                </form>
            </div>

<br>
<br>
        
        </div>
        <div class="row">
            <div class="col-sm-8">
                <p class="text-right">
                    <span class="entry-total-title mr-1">Total</span>
                    <span class="entry-total"> ₦ {{total_flat}} </span>
                    </p></div>
                    <div v-if="assignBtn"  class="col-sm-4 text-right">
                       <base-button @click="submitAllEntry" icon type="primary" :class="{disabled:loading_journal}">
     <span class="btn-inner--text">Post Entry</span>
       <span :class="{'spinner-border': loading_journal}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      
                        </div></div>
    <!--</div>-->

             
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
 <!-- <b-collapse id="collapse-4" v-model="collapse_ctrl" class="mt-2">
  <card class="" body-classes="px-0 pb-1" footer-classes="pb-2">
  <div class="form-body">

  </div>
</card>
  </b-collapse>-->
  </card>
  
        </div>
      </div>
</div>
</div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    name:'AssignEntries',
    data() {
    return {
       
        typeCtrl:false,
        
       model:{
           type:'',
           accountNumber:'',
           amount:'',
           narration:''
       },

        flat:[],
       
        total_flat:'0.00',
        

        assignBtn:true,
        entryId:0,
        response_prod:{},

     items:[],
      csvContent:[],
      fields: [		
          { key: 'drCr', label: 'Type', sortable: true, class: 'text-left' },
          { key: 'accountNumber', label: 'Account Number', sortable: true, class: 'text-left' },
          { key: 'amount', label: 'Amount', sortable: true, class: 'text-left' },
          { key: 'narration', label: 'Narration', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action'}
        ],
      sortOrders:{},
      sortKey: '',

light:'light',
      totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
    };
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  methods: {
      onChangeType(){
          console.log(`Type >>>?? ${this.type}`);
      },
      submitAllEntry(){
          //Math.floor(Math.random()*101);

      if(this.items.length!==0){  
          var entries = this.items;
          for(let i=0;i<entries.length;i++){
              entries[i].amount=entries[i].amount.split('₦')[1].trim();
          }
          

          var payload ={
              branchCode: this.userInformation.userBranchCode,
              bankCode:this.userInformation.userBankCode,
   maker:this.userInformation.userId,
   batchReference: this.response_journal.batchReference,
   data:entries,
   requestType: "submitBulkBatchEntry"
          }
          
 this.$store.dispatch("submitBulkBatchEntry",payload, { root: false });
          console.log(`Items>>>>--- ${JSON.stringify(this.items)}`);
      }
      
      else{
        this.notifyVue('danger',"An error as occurred, please try again");
      }
      },
      validate(){
         
           if( this.model.type === ''){
   this.typeCtrl=true;
} 
else{ this.typeCtrl=false;
          }
          return !this.typeCtrl 
       },
      submit(){
        if(this.validate()){
        
                this.flat.push((this.model.type==='CR')?Number(this.model.amount):-(Number(this.model.amount)));
                this.items.push({
                    drCr:this.model.type,
                   narration:this.model.narration,
                   amount:`₦ ${this.model.amount}`,
                   accountNumber:this.model.accountNumber,
                   id:this.entryId++
               });
                //this.flat.push(()?Number(this.amount):)
       
        if(this.flat.length!==0){
                console.log("Flattttttttt");
                let not_num = n => isNaN(n)?0:n;
                this.total_flat=(this.flat.reduce(function(a,b) {return not_num(a)+not_num(b);},0)).toFixed(2).toString();
                console.log(`this.total_flat>>${this.total_flat}`)
                
            }
    
            if(this.total_flat==='0.00'){
                this.assignBtn = true;
            }
            else{
                this.assignBtn = false;
            }
             this.model={
           type:'',
           accountNumber:'',
           amount:'',
           narration:''
       };

            this.typeCtrl=false;
            
            document.getElementById("submitForm").reset();
            

        }
      },
      onChangeAmtType(){ 
          this.amountControl=(this.amountType.length!==0)?true:false;
          console.log(`AmtType >>>?? ${this.type}`);
      },

deleteItem(item,index){
    console.log(`?????** Flat ${this.flat}`)
    console.log(`?????%% Percent ${this.percent}`)
    console.log(`?????>> ${JSON.stringify(item)}`);
    this.items.splice(index,1);
    if(item.drCr==='CR'){
    console.log(`>>>>Flat Cr ${item.amount.split('₦')[1].trim()}`);
         var ind = this.flat.indexOf(Number(item.amount.split('₦')[1].trim()));
         console.log(`?????>>Index Flat Cr >> ${ind}`);    // <-- Not supported in <IE9
         if (ind !== -1) {
            this.flat.splice(ind, 1);
    }
    }
    else{
console.log(`<<<<>Flat Dr -${item.amount.split('₦')[1].trim()}`);
         var ind = this.flat.indexOf(Number(`-${item.amount.split('₦')[1].trim()}`));    // <-- Not supported in <IE9
         console.log(`?????>>Index Flat Dr >> ${ind}`);    // <-- Not supported in <IE9
         if (ind !== -1) {
            this.flat.splice(ind, 1);
    }
    }
   
    if(this.flat.length!==0){
                console.log("Flattttttttt");
                let not_num = n => isNaN(n)?0:n;
                this.total_flat=(this.flat.reduce(function(a,b) {return not_num(a)+not_num(b);},0)).toFixed(2).toString();
                console.log(`this.total_flat>>${this.total_flat}`)
                
            }
            else{
                this.total_flat='0.00';
            }
        

            if(this.total_flat==='0.00'){
                this.assignBtn = true;
            }
            else{
                this.assignBtn = false;
            }
    console.log(`?????** Flat ${this.flat}`)
    console.log(`?????%% Percent ${this.percent}`)
    
},

notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
  
  },
  watch: {
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',newValue);
         this.$router.push({name:'JournalView'});
     }
      },
       errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    

  },
  computed: {
   ...mapState({errors: state => state.journal.journal_errors,loading_journal: state => state.journal.journal_loading,
   success: state => state.journal.journal_success,
    response_journal: state => state.journal.journal_response,
    resp_journal: state => state.journal.journal_resp,
   //response_slabSingle:state => state.journal.singleSlab,
   //response_page:state => state.journal.page,
   //response_selectAcc:state => state.journal.selectAcc,
   userInformation: state => state.auth.userInfo
   }),
    ...mapActions(['']),
    ...mapGetters([""]),
    
    },
    mounted(){
       if(Object.keys(this.resp_journal).length !== 0){
            //this.response_prod=this.response_product;
    var journal = [];
    journal = this.resp_journal.entries;
    this.entryId=Math.floor(Math.random()*101);
    this.items = journal.map((item) => {
            return {
                drCr: `${item.drCr}`,
                amount: `₦ ${item.amount}`,
                accountNumber: item.accountNumber,
                narration:item.narration,
                id:this.entryId++,
            };
        });


        console.log('//////////');
        
        for(let i =0;i<journal.length;i++){
            if(journal[i].amount===''){
                continue;
            }
            else{
               this.flat.push((journal[i].drCr==='CR')?Number(journal[i].amount):-(Number(journal[i].amount)));
                
            }
        }
       if(this.flat.length!==0){
                console.log("Flattttttttt");
                let not_num = n => isNaN(n)?0:n;
                this.total_flat=(this.flat.reduce(function(a,b) {return not_num(a)+not_num(b);},0)).toFixed(2).toString();
                console.log(`this.total_flat>>${this.total_flat}`)
                
            }
        
        
    }
    
      else{
        this.notifyVue('danger',"An error as occurred, please go back to continue");
      }
}
}
</script>

<style lang="css" scoped>
td{
    height: 100px;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.table-section tbody {
    max-width: 500px;
    overflow-x: auto;
}
.table-section {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 40px;
    
}
.form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.delete {
    cursor: pointer;
}

.svg-red {
  height: 20px;
  width: auto;
  fill: #f50e0e;
}
.svg-green {
  height: 20px;
  width: auto;
  fill: #0ef553;
}
.entry-total-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
}
.entry-total {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
}
.divider {
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
}
.save-bene {
    padding: 7px 0 0;
}
.save-bene1 {
    padding: 7px 0 0;
}

.checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    color: #525f7f;
}
.checkbox-container .checkbox {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    align-items: center;
}
.checkbox-container input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 0;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.checkbox-container .checkbox-box {
    height: 20px;
    display: flex;
    align-items: center;
}
.checkbox-container input[type=checkbox]:checked+.checkbox-box .checkbox-outer {
    background-color: #6d0605;
}
.checkbox-container .checkbox-outer {
    width: 20px;
    height: 20px;
    position: relative;
    transition: background-color .2s ease-in-out;
    background-color: #e6ebf1;
    border-radius: 50%;
}
.checkbox-container input[type=checkbox]:checked+.checkbox-box .checkbox-inner {
    opacity: 1;
    transform: scale(1);
}
.checkbox-container .checkbox-inner {
    width: 10px;
    height: 8px;
    opacity: 0;
    margin: 6px 0 0 5px;
    transform: scale(1.15);
    transition: all .2s ease-in-out;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABGdBTUEAALGPC/xhBQAAAcZJREFUSA3NlrtKA0EUhrOSwhQG8dIIsRHSiIWdhXaiGNBSU1n6AEJewDxBnkAfQEXriLXYipUo2HhpVEQEr+t3wo5MDrMTMbPigZ9zmXP+fzbs7CSX+2OLstKL47gf7mUQg+0oiu6z0sohNgTOgDGJZQPZGORbRsnya6LWE1oSgRk4Vx288tOGNcTy4MR6KhPeEQyEVYMN0ppRUL71cwYVRKAEnpSQpEcg/GmAdFfYlb2TTwZ9MiGDtKKETNrIQqwA+7lRsPwVcTELwbolYofVLMTKKLzYKknc7ChG4xxoggMw33GAhqQX12aygbJ3noZx8GqNfRCv+IZYr1r9dlj3zbXW6F63J5JYNrDgGqZeBPJSaJOXp+CaaavRtKgnk/wZP93WTEKtkdJf0b2pOQSugyu8D+D78EoM5EBr20kldy0w3QsONUuS3+LljYyAfKq0ySet5OL11hjqA8eaLckv8RspazUvsW8RwkFwmkLsKst1lPdxdlyDYARcuNhV7ZNcLtzuDaIxcK0EdLrZvZLFAPsEkNvaZVIfttrDhJBOAdfFGv4WN1tGcBY8AmN7BL+6xX88hMAoG1gCN2CfP7ZvZkP/2n8B0rR2l1wYbFEAAAAASUVORK5CYII=);
}
.checkbox-container .checkbox-label {
   margin: 0 0 0 0px;
    font-size: 11px;
    font-weight: 600;
    color: #171717;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: flex;
    user-select: none;
}
.checkbox-row{
    grid-gap: 14px 0;
    gap: 14px 0;
    margin: 0px 0 0px;
    padding: 0 0px;
}
</style>


