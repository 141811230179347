<template>
  <!--<div class="content">
     
    <div class="card">-->
        
      <div>
          <notifications></notifications>
        <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
         <!-- <template slot="header">
            <h3 class="mb-0">View {{}} Product</h3>
          </template>-->
          <div>
            <div v-if="Object.keys(response_nip).length !== 0">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
    <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Search"
                      name="search"
                      placeholder="Search"
                      v-model="filter">
          </base-input>
        </div>
</div>
<b-row>
      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="response_nip.data.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >
<!--<template #cell(action)="row">
                       <div class="name-container" @click="viewAccountEntry(row.item, row.index)">
                    <el-tooltip class="mr-3"  content="View more info" placement="left">
                    <i class="fas fa-info-circle"></i>
                   </el-tooltip>
                   </div>
                   
                  </template>-->


<!-- <template #head(accountNumber)="data">
        <span class="font-weight-bolder text-capitalize">{{ data.label. }}</span>
      </template>-->
<template #head()="data">
          <span v-for="n in data.label.split(' ')" :key="n" >
              <span class="text-capitalize">{{n}}</span>
              <br>
          </span>
</template>

      
      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>
 
</div>
  </div>
  <!--</b-container>-->
<!-----------------------------------Charges------------------------------------------------->
<!-----------------------------------End of Charges------------------------------------------>

<!-----------------------------------Funds Transfer------------------------------------------------->
<!-----------------------------------End of Funds Transfer------------------------------------------>

<!-----------------------------------Loan------------------------------------------------->
<!-----------------------------------End of Loan------------------------------------------>

<!-----------------------------------Interest------------------------------------------------->
<!-----------------------------------End of Interest------------------------------------------>

          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          </div>
          </card>
      </div>
   <!-- </div>
    
  </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import country from '@/components/country';
import Loader from '@/components/Loader';
import BaseInput from '../../components/Inputs/BaseInput.vue';

  export default {
    components: { 
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,
       // BaseInput,
      //Loader
      
    },
    data() {
      return {
          products:[],
          light:'light',
          card_options:true,
        modals:{
          modal3:false,
          modal0:false,
          modal1:false,
        },
        items:[],
        fields: [
    { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'SessionId', label: 'SessionId', sortable: true, class: 'text-left' },
          { key: 'DestinationBankCode', label: 'Destination Bank Code', sortable: true, class: 'text-left' },
          { key: 'ChannelCode', label: 'Channel Code', sortable: true, class: 'text-left' },
          { key: 'AccountNumber', label: 'Account Number', sortable: true, class: 'text-left' },
          {key: 'RequestDate',label: 'Request Date',sortable: true,class: 'text-left'},

          {key: 'AccountName',label: 'Account Name',sortable: true,class: 'text-left'},
          {key: 'OriginatorName',label: 'Originator Name',sortable: true,class: 'text-left'},
            {key: 'Narration',label: 'Narration',sortable: true,class: 'text-left'},
          {key: 'PaymentReference',label: 'Payment Reference',sortable: true,class: 'text-left'},
            {key: 'Amount',label: 'Amount',sortable: true,class: 'text-left'},
          {key: 'primaryAccount',label: 'Primary Account',sortable: true,class: 'text-left'},
            {key: 'TransactionStatus',label: 'Transaction Status',sortable: true,class: 'text-left'},
            {key: 'SettlementStatus',label: 'Settlement Status',sortable: true,class: 'text-left'},
            {key: 'FlexSessionID',label: 'Flex SessionID',sortable: true,class: 'text-left'},
            {key: 'NameEnquiryRef',label: 'Name Enquiry Ref',sortable: true,class: 'text-left'},
            {key: 'BeneficiaryAccountNumber',label: 'Beneficiary Account Number',sortable: true,class: 'text-left'},
            {key: 'BeneficiaryBVN',label: 'Beneficiary BVN',sortable: true,class: 'text-left'},
            {key: 'OriginatorAccountNumber',label: 'Originator Account Number',sortable: true,class: 'text-left'},
            {key: 'OriginatorBVN',label: 'Originator BVN',sortable: true,class: 'text-left'},
            {key: 'OriginatorKYCLevel',label: 'Originator KYC Level',sortable: true,class: 'text-left'},
            {key: 'TransactionLocation',label: 'Transaction Location',sortable: true,class: 'text-left'},
            {key: 'FutureUse1',label: 'Future Use1',sortable: true,class: 'text-left'},
            {key: 'FutureUse2',label: 'Future Use2',sortable: true,class: 'text-left'},
            {key: 'BeneficiaryKYCLevel',label: 'Beneficiary KYC Level',sortable: true,class: 'text-left'},
            {key: 'SettledStatus',label: 'Settled Status',sortable: true,class: 'text-left'},
            {key: 'SettledStatusDate',label: 'Settled Status Date',sortable: true,class: 'text-left'},
            {key: 'PostingDate',label: 'Posting Date',sortable: true,class: 'text-left'},
            {key: 'PostingResponseCode',label: 'Posting Response Code',sortable: true,class: 'text-left'},
            {key: 'PostingResponseMessage',label: 'Posting Response Message',sortable: true,class: 'text-left'},
            {key: 'NotificationStatus',label: 'Notification Status',sortable: true,class: 'text-left'},
            {key: 'serviceBank',label: 'Service Bank',sortable: true,class: 'text-left'},
            {key: 'branchCode',label: 'Branch Code',sortable: true,class: 'text-left'},

          //{ key: 'action', label: 'Action' }
      
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({userInformation: state => state.auth.userInfo,
      // response_slabSingle:state => state.product.singleSlab,
       //response_prod: state => state.product.product_response,
    response_nip: state => state.nip.nip_response,
   // response_nip_select: state => state.nip.response_nip_selected,
 loading_nip: state => state.nip.nip_loading,
    success_nip: state => state.nip.nip_success,error_nip: state => state.nip.nip_errors,
    }),
    ...mapActions(['']),
    ...mapGetters([""]),
    
    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
editSlab(slabAeCode,slabBankCode, ){
    this.$store.dispatch("readSingleAccountingEntry", {
        aeCode: slabAeCode,
        aeBankCode: slabBankCode,
        //slabCode: slabCode
    }, { root: false });

},

addNewSlab(){
  this.modals.modal3=false;
  this.$store.dispatch("addSlab", {
        action:'add'
        //slabCode: slabCode
    }, { root: false });
},

readSingleSlabs(slabBankCode,slabAeCode,slabCode){
    this.card_options = false;
    this.$store.dispatch("readSingleSlab", {
        slabAeCode: slabAeCode,
        slabBankCode: slabBankCode,
        slabCode: slabCode
    }, { root: false });
    

},
     
 viewAccountEntry(item, index){
           this.modals.modal3 = true;
     //console.log(this.model.customerId);
    this.$store.dispatch("readSlabs",{
        slabAeCode: item.aeCode,
        slabBankCode: this.userInformation.userBankCode,
  }, { root: false });


        //this.model=branch_obj;
        },
  
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    
    watch: {

response_nip(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      //console.log(`select ${JSON.stringify(this.response_product)}`);
      //console.log(`select>>>>> ${this.response_product_select}`);
       if(Object.keys(this.response_nip).length !== 0 ){
        var data = []; 
    data = this.response_nip.data;
    if(data.length!==0){
    console.log("&$$$$$&");//+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                SessionId:item.SessionId,
                DestinationBankCode:item.DestinationBankCode,
                ChannelCode:item.ChannelCode,
                AccountNumber:item.AccountNumber,
                RequestDate:item.RequestDate,
                AccountName:item.AccountName,
                OriginatorName:item.OriginatorName,
                Narration:item.Narration,
                PaymentReference:item.PaymentReference,
                Amount:item.Amount,
                primaryAccount:item.primaryAccount,
                TransactionStatus:item.TransactionStatus,
                SettlementStatus:item.SettlementStatus,
                FlexSessionID:item.FlexSessionID,
                NameEnquiryRef:item.NameEnquiryRef,	
                BeneficiaryAccountNumber:item.BeneficiaryAccountNumber,
                BeneficiaryBVN:item.BeneficiaryBVN,
                OriginatorAccountNumber:item.OriginatorAccountNumber,
                OriginatorBVN:item.OriginatorBVN,
                OriginatorKYCLevel:item.OriginatorKYCLevel,
                TransactionLocation:item.TransactionLocation,
                FutureUse1:item.FutureUse1,	
                FutureUse2:item.FutureUse2,	
                BeneficiaryKYCLevel:item.BeneficiaryKYCLevel,
                SettledStatus:item.SettledStatus,
                SettledStatusDate:item.SettledStatusDate,
                PostingDate:item.PostingDate,
                PostingResponseCode:item.PostingResponseCode,
                PostingResponseMessage:item.PostingResponseMessage,
                NotificationStatus:item.NotificationStatus,
                serviceBank:item.serviceBank,
                branchCode:item.branchCode		
            };
          });
    }


//////////////////////////////////

       }
        },

   error_nip(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success_nip(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.modals.modal3 = false;
        this.notifyVue('success',newValue);
        //setTimeout(() => this.$router.push({ name: 'AssignEntries' }), 1000);
        
         
console.log("Updated");
        //this.showDismissibleAlert=true;
      }
    },

/*response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
     response_glL2(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.response_glL2).length !== 0){
    var gl = [];
    gl = this.response_glL2.data;
    this.option_gl = gl.map((item) => {
            return {
                value: item.glLevel2Description,
                label: item.glLevel2Description
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },*/


    
   
  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
      this.$store.dispatch("nipInwards",{
          bankCode: this.userInformation.userBankCode,
        readAll: "NO"
    }, { root: false });
    },
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}
.back-point{
    cursor: pointer;
}

.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{
  
  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}

.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}

.view-more-customer-info p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}


.slab-list .slab-card {
    display: flex;
    justify-content: space-between;
    padding: 7px;
    background-color: #e0e0e0;
    color: #212121;
    border-radius: 3px;
    margin-bottom: 3px;
    cursor: pointer;
    transition: .3s ease-in-out all;
}
 .credit {
    color: #34c38f!important;
}
.entry-list-item p{
    margin-bottom: 10px;
}
.view-more-customer-info p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
}
.debit{
    color: #8b0000!important;;
}
</style>