<template>
  <!--<div class="content">

    <div class="card">-->

  <div>
    <notifications></notifications>
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <!-- <template slot="header">
         <h3 class="mb-0">View {{}} Product</h3>
       </template>-->
      <div>
        <div v-if="Object.keys(response_product).length !== 0">

          <!--<b-container class="table-container">--> <!--class="over-flow">-->
          <div class="form-body">
            <div class="table-section col-12">
              <div class="m-2 top-area">
                <div style="width: 100%; display: flex; flex: 1">
                  <div class="search-bar m-2">
                    <input
                      type="search"
                      class="search"
                      placeholder="Search.."
                      v-model="filter"
                      name="search"
                    />
                    <div class="search-icon">
                      <img
                        src="@/assets/search-bar.jpeg"
                        alt=""
                        class=""
                        outline
                        :disabled="loading"
                        @click="qqueryFundTransferSetting()"
                      /><span :class="{ 'spinner-border': loading }"></span>
                    </div>
                  </div>
                  <button
                    size="sm"
                    :disabled="loading"
                    @click="viewProduct('AE')"
                    class="btn m-2 p-2"
                  >
                    <i class="fas fa-plus"></i> Create Transaction Code
                  </button>
                </div>

                <div class="child-nav">
                  <div class="btn-nav">
                    <select class="sel-opt" v-model="perPage">
                      <option
                        v-for="option in [
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '50', value: 50 },
                        { label: '100', value: 100 },
                      ]"
                        class="select-danger"
                        :value="option.value"
                        :label="option.label"
                        :key="option.value"
                      ></option>
                    </select>
                    <span class="page">Per page</span>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-7 col-12">
                    <div class="text-right">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="items.length"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!---Main Table Content----->
              <div>
              <b-table striped hover
                         :items="items"
                         :fields="fields"
                         :current-page="currentPage"
                         :per-page="perPage"
                         :filter="filter"
                         :filter-included-fields="filterOn"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :sort-direction="sortDirection"
                         stacked="lg"
                         show-empty
                         large
                         :head-variant="light"
                         @filtered="onFiltered"
                >
                  <template #cell(action)="row">
                    <div class="name-container" @click="viewAccountEntry(row.item, row.index)">
                      <el-tooltip class="mr-3"  content="View more info" placement="left">
                        <i class="fas fa-info-circle"></i>
                      </el-tooltip>
                    </div>

                  </template>


                  <!-- <template #head(accountNumber)="data">
                          <span class="font-weight-bolder text-capitalize">{{ data.label. }}</span>
                        </template>-->


                  <template #row-details="row">
                    <b-card>
                      <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                      </ul>
                    </b-card>
                  </template>
                </b-table>
              </div>



              <modal :show.sync="modals.modal3"
                     body-classes="p-0"
                     modal-classes="modal-dialog-centered modal-md">
                <div v-if="card_options">
                  <div v-if="Object.keys(response_prod).length !== 0">
                    <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                      <article class="item-right" ><!-- @click="displayDetail()">-->
                        <a><span @click="modals.modal3 = false; card_options= true;"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
                      <!---<div class="card">-->
                      <div class="card-body">
                        <div>
                          <div class="view-more-customer-info">
                            <div class="view-more-customer-info-wrap">
                              <div class="view-more-top">
                                <h4 class="card-title mb-4">Select A Slab</h4>
                              </div>
                              <div class="view-more-base">
                                <div class="slab-list">
                                  <div v-if="response_prod.data.length!==0">
                                    <div  v-for="(slab, slabId) in response_prod.data"
                                          :key="slabId"  class="slab-card" @click="readSingleSlabs(slab.slabBankCode,slab.slabAeCode,slab.slabCode)">
                                      <div class="r">{{slab.slabCode}}</div>
                                      <div class="l">₦ {{slab.slabMaxAmount}}</div>
                                    </div></div>
                                  <div v-else>
                                    <p class="text-center">There Are No Slabs</p>
                                  </div>
                                </div>
                              </div>
                              <p class="text-center mt-2"><span>
                <span class="authorize-btn"><button @click="addNewSlab"  :class="{disabled:loading_product}"  class="btn btn-secondary"><i v-if="!loading_product" class="fas fa-plus"></i>Add New Slab
                         <span :class="{'spinner-border': loading_product}"></span> </button>
                                    </span></span></p>
                            </div></div></div>

                      </div>
                      <!---</card>
                                                                  </div></div>-->

                    </div>
                  </div>
                  <div v-else>
                    <LoadingPanel></LoadingPanel>
                  </div>
                </div>
                <!---------------------------------------Card 2--------------------------->
                <div v-if="!card_options">
                  <div v-if="Object.keys(response_slabSingle).length !== 0">
                    <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                      <article class="item-right" ><!-- @click="displayDetail()">-->
                        <a><span @click="modals.modal3 = false;card_options= true;"
                        ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
                      <!---<div class="card">-->
                      <div class="card-body">
                        <div>
                          <div class="view-more-customer-info">
                            <div class="view-more-customer-info-wrap">
                              <div class="view-more-top">
                                <h4 class="card-title mb-4"><span @click="card_options = true" class="back-point fas fa-arrow-left"></span> {{response_slabSingle.slabAeCode}} Entries</h4>
                                <div class="row">
                                  <div class="col-sm-6">
                                    <p class="queue-title">Description</p>
                                    <p class="queue-value"> {{response_slabSingle.slabAeCode}} </p>
                                  </div><div class="col-sm-6">
                                  <p class="queue-title">Created At</p>
                                  <p class="queue-value"> {{response_slabSingle.slabCreatedAt}} </p>
                                </div>
                                  <div class="col-sm-6">
                                    <p class="queue-title">Maker</p>
                                    <p class="queue-value">{{userInformation.userId}}</p></div>
                                  <div class="col-sm-6">
                                    <p class="queue-title">Updated At</p>
                                    <p class="queue-value"> {{response_slabSingle.slabUpdatedAt}} </p></div>
                                </div>
                              </div>
                              <div class="view-more-base">
                                <div class="slab-list">
                                  <div v-if="response_slabSingle.entries.length!==0">
                                    <div class="privilege-list">
                                      <div class="row">
                                        <div v-for="(entry,index) in response_slabSingle.entries" :key="index" class="col-sm-6">
                                          <div class="entry-list-item" :class="[entry.entryDrCr == 'CR' ? 'credit' : 'debit']">
                                            <div class="row">
                                              <div class="col-6 text-left">
                                                <p><!---->{{entry.entryDrCr == 'CR'?'+':'-'}}
                                                  ₦ {{entry.entryAmount}} </p></div><div class="col-6 text-right">
                                              <p> {{entry.entryAccount}} </p></div></div></div></div>
                                      </div></div>

                                  </div>
                                  <div v-else>
                                    <p class="text-center">There Are No Entries...</p>
                                  </div>
                                </div>
                              </div>
                              <p class="text-center mt-2"><span>
                <span class="authorize-btn"><button @click="editSlab(response_slabSingle.slabAeCode,response_slabSingle.slabBankCode)"  :class="{disabled:loading_product}"  class="btn btn-secondary"><i v-if="!loading_product" ></i>Edit Entry
                         <span :class="{'spinner-border': loading_product}"></span> </button>
                                    </span></span></p>
                            </div></div></div>

                      </div>
                      <!---</card>
                                                                  </div></div>-->

                    </div>
                  </div>
                  <div v-else>
                    <LoadingPanel></LoadingPanel>
                  </div>
                </div>
              </modal>

            </div>
          </div>
          <!--</b-container>-->
          <!-----------------------------------Charges------------------------------------------------->
          <!-----------------------------------End of Charges------------------------------------------>

          <!-----------------------------------Funds Transfer------------------------------------------------->
          <!-----------------------------------End of Funds Transfer------------------------------------------>

          <!-----------------------------------Loan------------------------------------------------->
          <!-----------------------------------End of Loan------------------------------------------>

          <!-----------------------------------Interest------------------------------------------------->
          <!-----------------------------------End of Interest------------------------------------------>

        </div>
        <div v-else>
          <LoadingPanel></LoadingPanel>
        </div>
      </div>
    </card>
  </div>
  <!-- </div>

 </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import country from '@/components/country';
import Loader from '@/components/Loader';
import BaseInput from '../../components/Inputs/BaseInput.vue';

export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    LoadingPanel,
    // BaseInput,
    //Loader

  },
  data() {
    return {
      products:[],
      light:'light',
      card_options:true,
      modals:{
        modal3:false,
        modal0:false,
        modal1:false,
      },
      items:[],
      fields: [],

      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
    ...mapState({userInformation: state => state.auth.userInfo,
      response_slabSingle:state => state.product.singleSlab,
      response_prod: state => state.product.product_response,
      response_product: state => state.product.product_view_AE,
      response_product_select: state => state.product.response_product_selected,
      loading_product: state => state.product.product_loading,
      success_product: state => state.product.product_success,error_product: state => state.product.product_errors,
    }),
    ...mapActions(['']),
    ...mapGetters([""]),

  },
  /*mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },*/
  methods: {
    viewProduct(prdTemplateCode){
      console.log("Hello World");
      this.$store.dispatch("readProductForm",{
        templateBankCode: this.userInformation.userBankCode,
        templateType:prdTemplateCode

      }, { root: false });
    },
    editSlab(slabAeCode,slabBankCode, ){
      this.$store.dispatch("readSingleAccountingEntry", {
        aeCode: slabAeCode,
        aeBankCode: slabBankCode,
        //slabCode: slabCode
      }, { root: false });

    },

    addNewSlab(){
      this.modals.modal3=false;
      this.$store.dispatch("addSlab", {
        action:'add'
        //slabCode: slabCode
      }, { root: false });
    },

    readSingleSlabs(slabBankCode,slabAeCode,slabCode){
      this.card_options = false;
      this.$store.dispatch("readSingleSlab", {
        slabAeCode: slabAeCode,
        slabBankCode: slabBankCode,
        slabCode: slabCode
      }, { root: false });


    },

    viewAccountEntry(item, index){
      this.modals.modal3 = true;
      //console.log(this.model.customerId);
      this.$store.dispatch("readSlabs",{
        slabAeCode: item.aeCode,
        slabBankCode: this.userInformation.userBankCode,
      }, { root: false });


      //this.model=branch_obj;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    notifyVue(type = 'default',msg) {
      console.log(type);
      this.$notify({
        message:`<b>${msg}</b>`,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      });
    },

  },

  watch: {

    response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      console.log(`select ${JSON.stringify(this.response_product)}`);
      console.log(`select>>>>> ${this.response_product_select}`);
      if(Object.keys(this.response_product).length !== 0){

        this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'aeCode', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'aeDescription', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'aeChargeCode', label: 'ChargeCode', sortable: true, class: 'text-left' },
          { key: 'aeMaker', label: 'Creator', sortable: true, class: 'text-left' },
          {key: 'aeCreatedAt',label: 'CreatedAt',sortable: true,class: 'text-left'},
          {key: 'aeUpdatedAt',label: 'UpdatedAt',sortable: true,class: 'text-left'},
          { key: 'action', label: 'Action' }

        ];
        var data = [];
        data = this.response_product.data;
        console.log("&$$$$$&"+this.response_product.data);
        var count = 1;
        this.items = data.map((item) => {
          return {
            sn:count++,
            aeCode:item.aeCode,
            aeDescription:item.aeDescription,
            aeChargeCode:item.aeChargeCode,
            aeMaker:item.aeMaker,
            aeCreatedAt:item.aeCreatedAt,
            aeUpdatedAt:item.aeUpdatedAt

          };
        });


//////////////////////////////////

      }
    },

    error_product(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success_product(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.modals.modal3 = false;

        setTimeout(() => this.modals.modal3 = false, 1000);
        this.notifyVue('success',newValue);
        setTimeout(() => this.$router.push({ name: 'AssignEntries' }), 1000);


        console.log("Updated");
        //this.showDismissibleAlert=true;
      }
    },

    /*response_product(newValue, oldValue) {
          //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
           if(Object.keys(this.response_product).length !== 0){
        var product = [];
        product = this.response_product.data;
        this.option_product = product.map((item) => {
                return {
                    value: `${item.Code}`,
                    label: `${item.Code} (${item.Description})`
                };
            });
            console.log('//////////');
    //console.log("&$$$$$&"+this.option_branch);

        }
            },
         response_glL2(newValue, oldValue) {
          //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
          if(Object.keys(this.response_glL2).length !== 0){
        var gl = [];
        gl = this.response_glL2.data;
        this.option_gl = gl.map((item) => {
                return {
                    value: item.glLevel2Description,
                    label: item.glLevel2Description
                };
            });
     //       console.log('//////////');
    //console.log("&$$$$$&"+this.option_role);

        }

            },*/


    success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue('success',`${newValue}`);
        setTimeout(() => { this.modals.modal3=false; }, 2000);

        //this.modals.modal0=false;

        //this.showDismissibleAlert=true;
      }
    },

  },
  updated() {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
  },
  created:function (){
  },
  mounted:function (){
    this.$store.dispatch("readAccountingEntry",{
      aeBankCode: this.userInformation.userBankCode
    }, { root: false });
  },
}
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 40px;
  /*max-width: 100%;*/
}
.back-point{
  cursor: pointer;
}

.text-title{
  font-size: 0.875rem;
  font-weight: 600;
}
.item-right {
  padding-left: 90%;
  cursor: pointer;
  margin:0px 0% 5px 0px;
  float:right!important;
}
.vodal-dialog {
  overflow-y: scroll;
  overflow-x: hidden !important;
  background-color: #fff;
}
.vodal-dialog .card {
  box-shadow: none !important;
}
body{

  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
  font-weight: 600;
}
.view-more-customer-info {
  color: #545b62;
}
.view-more-customer-info .view-more-top {
  margin-bottom: 10px;
}
.view-more-top .queue-title {
  margin-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #9E9E9E;
}
p{
  color: #495057;
}
.view-more-customer-info p {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0;
}
.view-more-top .queue-value {
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 20px;
}
.view-more-top .queue-title {
  margin-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #9E9E9E;
}
.view-more-customer-info {
  color: #545b62;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #495057;
}
.table-section tbody {
  max-width: 500px;
  overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f9fa;
}
.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #eff2f7;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: 600;
}

.view-more-customer-info p {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}
.view-more-customer-info .view-more-top {
  margin-bottom: 10px;
}


.slab-list .slab-card {
  display: flex;
  justify-content: space-between;
  padding: 7px;
  background-color: #e0e0e0;
  color: #212121;
  border-radius: 3px;
  margin-bottom: 3px;
  cursor: pointer;
  transition: .3s ease-in-out all;
}
.credit {
  color: #34c38f!important;
}
.entry-list-item p{
  margin-bottom: 10px;
}
.view-more-customer-info p {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}


.top-area {
  display: flex;
  margin-top: 0px !important;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  display: flex;
  justify-content: center;
  width: 20%;
}


.child-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
}

.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}


.sel-opt {
  width: 28%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.sel-opt:focus {
  outline: none;
}

.page {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #175d85;
  margin: 10px;
}

.search-bar {
  width: 287px;
  height: 5vh;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search {
  background: rgba(196, 196, 196, 0.11);
  border: none;
  box-sizing: border-box;
  height: 5vh;
  width: 100%;
  padding: 10px;
}

.search:focus {
  outline: none;
}

::placeholder {
  color: rgba(23, 93, 133, 0.26);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
</style>
