<template>
  <!--<div class="content">
     
    <div class="card">-->
        
      <div>
          <notifications></notifications>
        <div>
            <div v-if="response_product_select !== null">
              
  <!--<b-container class="table-container">--> <!--class="over-flow">-->
<div v-if="response_product_select === 'AC' && 'data' in response_product_AC">
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
     <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<label for="search" class="form-control-label">Search</label>
        <input type="text" v-model="filter_AC" placeholder="Search with Account Number" class="form-control" name="search">
        </div>
</div>
<div class="row my-1">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
          <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage_AC">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
        <b-pagination
          v-model="currentPage_AC"
          :total-rows="items_AC.length"
          :per-page="perPage_AC"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
    </div>
    </div>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items_AC"
      :fields="fields_AC"
      :current-page="currentPage_AC"
      :per-page="perPage_AC"
      :filter="filter_AC"
      :filter-included-fields="filterOn_AC"
      :sort-by.sync="sortBy_AC"
      :sort-desc.sync="sortDesc_AC"
      :sort-direction="sortDirection_AC"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered_AC"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editProduct(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Product</a>
                            </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>

      <template #cell(Status)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                       </badge>
                </template>
      </template>
<template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>     
</div>
  </div>
            </div>
  <!--</b-container>-->
<!-----------------------------------Charges------------------------------------------------->
  <div v-else-if="response_product_select === 'CH' && 'data' in response_product_CH">
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
     <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<label for="search" class="form-control-label">Search</label>
        <input type="text" v-model="filter_CH" placeholder="Search with Account Number" class="form-control" name="search">
        </div>
</div>
<div class="row my-1">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
          <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage_CH">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
        <b-pagination
          v-model="currentPage_CH"
          :total-rows="items_CH.length"
          :per-page="perPage_CH"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
    </div>
    </div>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items_CH"
      :fields="fields_CH"
      :current-page="currentPage_CH"
      :per-page="perPage_CH"
      :filter="filter_CH"
      :filter-included-fields="filterOn_CH"
      :sort-by.sync="sortBy_CH"
      :sort-desc.sync="sortDesc_CH"
      :sort-direction="sortDirection_CH"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered_CH"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editProduct(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Product</a>
                            </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>

      <template #cell(Status)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                       </badge>
                </template>
      </template>
<template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>     
</div>
  </div>
            </div>
<!-----------------------------------End of Charges------------------------------------------>

<!-----------------------------------Funds Transfer------------------------------------------------->
  <div v-else-if="response_product_select === 'FT' && 'data' in response_product_FT">
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
     <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<label for="search" class="form-control-label">Search</label>
        <input type="text" v-model="filter_FT" placeholder="Search with Account Number" class="form-control" name="search">
        </div>
</div>
<div class="row my-1">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
          <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage_FT">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
        <b-pagination
          v-model="currentPage_FT"
          :total-rows="items_FT.length"
          :per-page="perPage_FT"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
    </div>
    </div>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items_FT"
      :fields="fields_FT"
      :current-page="currentPage_FT"
      :per-page="perPage_FT"
      :filter="filter_FT"
      :filter-included-fields="filterOn_FT"
      :sort-by.sync="sortBy_FT"
      :sort-desc.sync="sortDesc_FT"
      :sort-direction="sortDirection_FT"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered_FT"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editProduct(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Product</a>
                            </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>

      <template #cell(Status)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                       </badge>
                </template>
      </template>
<template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>     
</div>
  </div>
            </div>

<!-----------------------------------End of Funds Transfer------------------------------------------>

<!-----------------------------------Loan------------------------------------------------->
<div v-else-if="response_product_select === 'LO' && 'data' in response_product_LO">
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
     <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<label for="search" class="form-control-label">Search</label>
        <input type="text" v-model="filter_LO" placeholder="Search with Account Number" class="form-control" name="search">
        </div>
</div>
<div class="row my-1">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
          <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage_LO">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
        <b-pagination
          v-model="currentPage_LO"
          :total-rows="items_LO.length"
          :per-page="perPage_LO"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
    </div>
    </div>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items_LO"
      :fields="fields_LO"
      :current-page="currentPage_LO"
      :per-page="perPage_LO"
      :filter="filter_LO"
      :filter-included-fields="filterOn_LO"
      :sort-by.sync="sortBy_LO"
      :sort-desc.sync="sortDesc_LO"
      :sort-direction="sortDirection_LO"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered_LO"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editProduct(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Product</a>
                            </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>

      <template #cell(Status)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                       </badge>
                </template>
      </template>
<template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>     
</div>
  </div>
            </div>
<!-----------------------------------End of Loan------------------------------------------>

<!-----------------------------------Interest------------------------------------------------->
<div v-else-if="response_product_select === 'IN' && 'data' in response_product_IN">
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
     <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<label for="search" class="form-control-label">Search</label>
        <input type="text" v-model="filter_IN" placeholder="Search with Account Number" class="form-control" name="search">
        </div>
</div>
<div class="row my-1">
<div class="col-lg-6 col-md-6 col-sm-5 col-12">
          <el-select class="select-danger" filterable
             placeholder="" 
             v-model="perPage_IN">
    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"
               class="select-danger"
               :value="option.value"
               :label="option.label"
               :key="option.value">
    </el-option>
  </el-select>
  <label class="form-control-label ml-1" for="">Per Page</label>
        </div>
     

    <div class="col-lg-6 col-md-6 col-sm-7 col-12">
        <b-pagination
          v-model="currentPage_IN"
          :total-rows="items_IN.length"
          :per-page="perPage_IN"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
    </div>
    </div>

    <!-- Main table element -->
    <div>
    <b-table striped hover  
      :items="items_IN"
      :fields="fields_IN"
      :current-page="currentPage_IN"
      :per-page="perPage_IN"
      :filter="filter_IN"
      :filter-included-fields="filterOn_IN"
      :sort-by.sync="sortBy_IN"
      :sort-desc.sync="sortDesc_IN"
      :sort-direction="sortDirection_IN"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered_IN"
    >
      
      <template #cell(action)="row">
                <template>
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      <!--<i class="ni ni-settings"></i>-->
                    </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                          <a class="dropdown-item" @click="editProduct(row.item, row.index)">
                             <!-- <i class="far fa-edit"></i>-->
                             <i class="fas fa-user-edit"></i>
                             <!--<i class="ni ni-ruler-pencil"></i>-->
                              Edit Product</a>
                            </el-dropdown-menu>
                    </el-dropdown>
                </template>
      </template>

      <template #cell(Status)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success" size="lg">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                       </badge>
                </template>
      </template>
<template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>     
</div>
  </div>
            </div>
<!-----------------------------------End of Interest------------------------------------------>

          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          </div>
          <!---</card>-->
          <modal :show.sync="modals.modal3"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
               <div v-if="Object.keys(response_prod).length !== 0">
               <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal3 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
          <div class="view-more-top">
            <h4 class="card-title mb-4">Edit Product</h4>
            </div>
                        <div class="view-more-base">
                          <div class="table-responsive">
                            <form 
          @submit.prevent="submitEdit ">
                                <div class="row">
<!------------------------------Line 1-------------------------------------->
           <div  v-for="(prod, templateSequence) in response_prod.data"
                  :key="templateSequence" class="col-lg-12 col-md-12 col-sm-12 col-12">
<div class="form-group">
    <label class="form-control-label"> {{prod.templateLabel}}</label>
    <div class="has-label"><!---->
    <div v-if="prod.templateDataType==='NUMBER'">
    <input :readonly="(prod.templateDisabled==='YES')?true:false" type="number" :name="prod.templateDescription"  :value="prod.templateValue"
    :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else-if="prod.templateDataType==='FLOAT'">
    <input :readonly="(prod.templateDisabled==='YES')?true:false" type="number" step=0.01  :value="prod.templateValue"
     :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else-if="prod.templateDataType==='STRING'">
     <input :readonly="(prod.templateDisabled==='YES')?true:false" type="text"  :value="prod.templateValue"
     :name="prod.templateDescription" :placeholder="prod.templateLabel" class="form-control" required><!---->
    </div>
    <div v-else>
    <select class="form-control" :placeholder="prod.templateLabel" :name="prod.templateDescription" :readonly="(prod.templateDisabled==='YES')?true:false" required>
         <option>Select {{prod.templateLabel}}</option> 
        <option  v-for="(item,index) in selectOption(prod.templateDescription)" :key="index" :value="item"
        :selected="item === prod.templateValue"
        >{{item}}</option>
   
    </select>
    
    </div>
    </div><!----><!----></div>

        </div>
<!------------------------------End of Line 1-------------------------------------->

</div>                 
<p class="text-right"><span>
                <span class="authorize-btn"><button  :class="{disabled:loading_product}" type="submit"  class="btn btn-primary mr-1"><i v-if="!loading_product" class="fas fa-check"></i>Update
                         <span :class="{'spinner-border': loading_product}"></span> </button>
                                    </span></span></p>
</form>
                       </div></div>
                    
                                            </div></div></div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>
               </div>
                <div v-else>
                      <LoadingPanel></LoadingPanel>
                    </div>
        </modal>
      </div>
   <!-- </div>
    
  </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import country from '@/components/country';
import Loader from '@/components/Loader';
import BaseInput from '../../components/Inputs/BaseInput.vue';

  export default {
    components: { 
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,
       // BaseInput,
      //Loader
      
    },
    data() {
      return {
          products:[],
          light:'light',
        modals:{
          modal3:false,
          modal0:false,
          modal1:false,
        },
        items_AC:[],
        fields_AC: [],
        totalRows_AC: 1,
        currentPage_AC: 1,
        perPage_AC: 5,
        pageOptions_AC: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy_AC: '',
        sortDesc_AC: false,
        sortDirection_AC: 'asc',
        filter_AC: null,
        filterOn_AC: [],

        items_CH:[],
        fields_CH: [],
        totalRows_CH: 1,
        currentPage_CH: 1,
        perPage_CH: 5,
        pageOptions_CH: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy_CH: '',
        sortDesc_CH: false,
        sortDirection_CH: 'asc',
        filter_CH: null,
        filterOn_CH: [],

        items_LO:[],
        fields_LO: [],
        totalRows_LO: 1,
        currentPage_LO: 1,
        perPage_LO: 5,
        pageOptions_LO: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy_LO: '',
        sortDesc_LO: false,
        sortDirection_LO: 'asc',
        filter_LO: null,
        filterOn_LO: [],

        items_IN:[],
        fields_IN: [],
        totalRows_IN: 1,
        currentPage_IN: 1,
        perPage_IN: 5,
        pageOptions_IN: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy_IN: '',
        sortDesc_IN: false,
        sortDirection_IN: 'asc',
        filter_IN: null,
        filterOn_IN: [],

        items_FT:[],
        fields_FT: [],
        totalRows_FT: 1,
        currentPage_FT: 1,
        perPage_FT: 5,
        pageOptions_FT: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy_FT: '',
        sortDesc_FT: false,
        sortDirection_FT: 'asc',
        filter_FT: null,
        filterOn_FT: [],

        items_AE:[],
        fields_AE: [],
        totalRows_AE: 1,
        currentPage_AE: 1,
        perPage_AE: 5,
        pageOptions_AE: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy_AE: '',
        sortDesc_AE: false,
        sortDirection_AE: 'asc',
        filter_AE: null,
        filterOn_AE: [],

      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({userInformation: state => state.auth.userInfo,
       response_prod: state => state.product.product_response,
    response_product_CH: state => state.product.product_view_CH,
    response_product_IN: state => state.product.product_view_IN,
    response_product_LO: state => state.product.product_view_LO,
    response_product_AC: state => state.product.product_view_AC,
    response_product_FT: state => state.product.product_view_FT,
    response_product_AE: state => state.product.product_view_AE,
   response_product: state => state.product.product_view,
    response_product_select: state => state.product.response_product_selected,
 loading_product: state => state.product.product_loading,
    success_product: state => state.product.product_success,error_product: state => state.product.product_errors,
    }),
    ...mapActions(['']),
    ...mapGetters([""]),
    
    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
      selectOption(id){
    let option_obj=this.response_prod.data.find(obj => ((obj.templateDescription === id)));
    let optionsList=option_obj.templateList.split(",");
    return optionsList;
},
       
         resetForm(){
            //console.log(`>>><<<${this.nameCtrl}`);
            this.inputRequired=true;
        },
        submitForm(){
     // alert('this.model.username');
     if(this.validateSelect()){
        this.inputRequired=false;
         this.$store.dispatch("branchUpdate", this.model, { root: false });
       
     }
      },
 editProduct(item, index){
           this.modals.modal3 = true;
     //console.log(this.model.customerId);
    this.$store.dispatch("readProductForm1",{
        productCode: item.Code,
        templateBankCode: this.userInformation.userBankCode,
        templateType: this.response_product_select
  }, { root: false });


        //this.model=branch_obj;
        },
  submitEdit(){
  // var formData = new FormData(this.$refs.editForm);
  //       for(var pair of formData.entries()) {
  //  console.log(pair);
//}
this.products.push({
        bankcode:this.userInformation.userBankCode,
         productname:this.response_product_select,
         userId:this.userInformation.userId,
          key: 'source',
          value:'WEB',
          });
         var formData = new FormData(this.$refs.editForm);
         for(var pair of formData.entries()) {
          this.products.push({
         bankcode:this.userInformation.userBankCode,
         productname:this.response_product_select,
         userId:this.userInformation.userId,
          key: pair[0],
          value:pair[1],
          });
    console.log(pair);
}
console.log(this.products);
this.$store.dispatch("updateProduct",{
        requestType:"update",
        products:this.products,
         productBankCode: this.userInformation.userBankCode,
        productType: this.response_product_select
    }, { root: false });

      },
      
      onFiltered_AC(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_AC = filteredItems_AC.length
        this.currentPage_AC = 1
      },
     onFiltered_CH(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_CH = filteredItems_CH.length
        this.currentPage_CH = 1
      },
      onFiltered_LO(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_LO = filteredItems_LO.length
        this.currentPage_LO = 1
      },
      onFiltered_FT(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_FT = filteredItems_FT.length
        this.currentPage_FT = 1
      },
      onFiltered_IN(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows_IN = filteredItems_IN.length
        this.currentPage_IN = 1
      },

      onFiltered_AE(filteredItems) {
        // Trigger pagAEation to update the number of buttons/pages due to filterAEg
        this.totalRows_AE = filteredItems_AE.length
        this.currentPage_AE = 1
      },


      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      
    },
    
    watch: {
      response_product_AC(newValue, oldValue) {
if('data' in this.response_product_AC){
this.fields_AC=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'MinBalance', label: 'Min Balance', sortable: true, class: 'text-left' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'AllowCash',label: 'Allow Cash',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'MaxBalance', label: 'Max Balance', sortable: true, class: 'text-left' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'AllowCard', label: 'Allow Card', sortable: true, class: 'text-left' },
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
          {key: 'Type',label: 'Type',sortable: true,class: 'text-left'},
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
         {key: 'AllowDebit',label: 'Allow Debit',sortable: true,class: 'text-left'},
          { key: 'MaxDebitPerTxn', label: 'Max Debit Per Txn', sortable: true, class: 'text-left' },
           { key: 'AllowCredit', label: 'Allow Credit', sortable: true, class: 'text-left' },
            { key: 'MaxCreditPerTxn', label: 'Max Credit Per Txn', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AC.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_AC = data.map((item) => {
            return {
                sn:count++,
                MinBalance:item.MinBalance,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                AllowCash:item.AllowCash,
                BankCode: item.BankCode,
                MaxBalance:item.MaxBalance,
                Code:item.Code,
                AllowCard:item.AllowCard,
                AllowFt:item.AllowFt,
                Type:item.Type,
                AllowChannel:item.AllowChannel,
                AllowDebit:item.AllowDebit,
                MaxDebitPerTxn:item.MaxDebitPerTxn,
                AllowCredit:item.AllowCredit,
                MaxCreditPerTxn:item.MaxCreditPerTxn
     
            };
          });

           }
  },
response_product_CH(newValue, oldValue) {
if('data' in this.response_product_CH){
this.fields_CH=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'VatAmount', label: 'Vat Amount', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'VatAmountType', label: 'Vat Amount Type', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          {key: 'SlabFourAmount',label: 'Slab Four Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFive',label: 'Slab Five',sortable: true,class: 'text-left'},
          {key: 'AfterTrnCountMonthly',label: 'After Trn Count Monthly',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'VatAccount',label: 'Vat Account',sortable: true,class: 'text-left'},
          {key: 'SlabTwoAmount',label: 'Slab Two Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFour',label: 'Slab Four',sortable: true,class: 'text-left'},
          {key: 'SlabThree',label: 'Slab Three',sortable: true,class: 'text-left'},
          { key: 'SlabFiveAmount', label: 'Slab Five Amount', sortable: true, class: 'text-left' },
          { key: 'SlabOne', label: 'Slab One', sortable: true, class: 'text-left' },
          { key: 'SlabThreeAmount', label: 'Slab Three Amount', sortable: true, class: 'text-left' },
          {key: 'AfterTrnCountDaily',label: 'After Trn Count Daily',sortable: true,class: 'text-left'},
          {key: 'Status',label: 'Status',sortable: true,class: 'text-left'},
          {key: 'SlabOneAmount',label: 'Slab One Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
         {key: 'Amount',label: 'Amount',sortable: true,class: 'text-left'},
          { key: 'SlabTwo', label: 'Slab Two', sortable: true, class: 'text-left' },
           { key: 'BankCode', label: 'Bank Code', sortable: true, class: 'text-left' },
            { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
            { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
            { key: 'AmountType', label: 'Amount Type', sortable: true, class: 'text-left' },
            { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_CH.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_CH = data.map((item) => {
            return {
                sn:count++,
                VatAmount:item.VatAmount,
         Account:item.Account,
         VatAmountType:item.VatAmountType,
         Description:item.Description,
         SlabFourAmount:item.SlabFourAmount,
         SlabFive:item.SlabFive,
         AfterTrnCountMonthly:item.AfterTrnCountMonthly,
         CreatedAt:item.CreatedAt,
         VatAccount:item.VatAccount,
         SlabTwoAmount:item.SlabTwoAmount,
         SlabFour:item.SlabFour,
         SlabThree:item.SlabThree,
         SlabFiveAmount:item.SlabFiveAmount,
         SlabOne:item.SlabOne,
         SlabThreeAmount:item.SlabThreeAmount,
         AfterTrnCountDaily:item.AfterTrnCountDaily,
         Status:item.Status,
         SlabOneAmount:item.SlabOneAmount,
         CreatedBy:item.CreatedBy,
         Amount:item.Amount,
         SlabTwo:item.SlabTwo,
         BankCode:item.BankCode,
         Code:item.Code,
         AccountingEntry:item.AccountingEntry,
         AmountType:item.AmountType,
         Frequency:item.Frequency
     
            };
          });
}
           },

response_product_FT(newValue, oldValue){
    if('data' in this.response_product_FT){
this.fields_FT=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product.data_FT;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_FT = data.map((item) => {
            return {
                sn:count++,
                Status: item.Status,
                AccountingEntry: item.AccountingEntry,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                MinAmount: item.MinAmount,
                CreatedAt:item.CreatedAt,
                BankCode: item.BankCode,
                Code:item.Code,
                MaxAmount:item.MaxAmount,
               
            };
          });

           }
    },

response_product_LO(newValue,oldValue){
    if('data' in this.response_product_LO){
this.fields_LO=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'MaxRate', label: 'Max Rate', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
          { key: 'Penalty', label: 'Penalty', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          {key: 'MinRate',label: 'Min Rate',sortable: true,class: 'text-left'},
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'InterestBasis',label: 'Interest Basis',sortable: true,class: 'text-left'},
         
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_LO.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_LO = data.map((item) => {
            return {
               sn:count++,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                MaxRate: item.MaxRate,
                BankCode: item.BankCode,
                Code:item.Code,
                Penalty:item.Penalty,
                MaxAmount:item.MaxAmount,
                AccountingEntry:item.AccountingEntry,
                MinRate:item.MinRate,
                MinAmount:item.MinAmount,
                InterestBasis:item.InterestBasis,
            };
          });

           }
    },

response_product_IN(newValue,oldValue){
if('data' in this.response_product_IN){
this.fields_IN=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'TransactionType', label: 'Transaction Type', sortable: true, class: 'text-left' },
          {key: 'Description',label: 'Description',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
          {key: 'Rate',label: 'Rate',sortable: true,class: 'text-left'},
          { key: 'AccrualType', label: 'Accrual Type', sortable: true, class: 'text-left' },
          { key: 'CreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
          { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
          {key: 'CodeExemption',label: 'Code Exemption',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
         { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_IN.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_IN = data.map((item) => {
            return {
                sn:count++,
                Status	: item.Status,
                Account: item.Account,
                TransactionType: item.TransactionType,
                Description: item.Description,
                CreatedBy:item.CreatedBy,
                Rate: item.Rate,
                AccrualType:item.AccrualType,
                CreatedAt:item.CreatedAt,
                Frequency:item.Frequency,
                CodeExemption:item.CodeExemption,
                BankCode:item.BankCode,
                Code:item.Code,
                
            };
          });

           }
    },

response_product_AE(newValue, oldValue){
if('data' in this.response_product_AE){
this.fields_AE=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'Charge Code', label: 'Charge Code	', sortable: true, class: 'text-left' },
          { key: 'Creator', label: 'Creator', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'UpdatedAt',label: 'Updated At',sortable: true,class: 'text-left'},
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AE.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_AE = data.map((item) => {
            return {

                sn:count++,
                Code:item.Code,
                Description	: item.Description,
                ChargeCode: item.ChargeCode,
                Creator: item.Creator,
                CreatedAt: item.CreatedAt,
                UpdatedAt:item.UpdatedAt,
                
            };
          });

           }

       },
  

/*response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      console.log(`select ${JSON.stringify(this.response_product)}`);
      console.log(`select>>>>> ${this.response_product_select}`);
       if(Object.keys(this.response_product).length !== 0){
           if(this.response_product_select==='AC'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'MinBalance', label: 'Min Balance', sortable: true, class: 'text-left' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'AllowCash',label: 'Allow Cash',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'MaxBalance', label: 'Max Balance', sortable: true, class: 'text-left' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'AllowCard', label: 'Allow Card', sortable: true, class: 'text-left' },
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
          {key: 'Type',label: 'Type',sortable: true,class: 'text-left'},
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
         {key: 'AllowDebit',label: 'Allow Debit',sortable: true,class: 'text-left'},
          { key: 'MaxDebitPerTxn', label: 'Max Debit Per Txn', sortable: true, class: 'text-left' },
           { key: 'AllowCredit', label: 'Allow Credit', sortable: true, class: 'text-left' },
            { key: 'MaxCreditPerTxn', label: 'Max Credit Per Txn', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AC.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                MinBalance:item.MinBalance,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                AllowCash:item.AllowCash,
                BankCode: item.BankCode,
                MaxBalance:item.MaxBalance,
                Code:item.Code,
                AllowCard:item.AllowCard,
                AllowFt:item.AllowFt,
                Type:item.Type,
                AllowChannel:item.AllowChannel,
                AllowDebit:item.AllowDebit,
                MaxDebitPerTxn:item.MaxDebitPerTxn,
                AllowCredit:item.AllowCredit,
                MaxCreditPerTxn:item.MaxCreditPerTxn
     
            };
          });

           }
//////////////////////////////////
else if(this.response_product_select==='CH'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'VatAmount', label: 'Vat Amount', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'VatAmountType', label: 'Vat Amount Type', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          {key: 'SlabFourAmount',label: 'Slab Four Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFive',label: 'Slab Five',sortable: true,class: 'text-left'},
          {key: 'AfterTrnCountMonthly',label: 'After Trn Count Monthly',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'VatAccount',label: 'Vat Account',sortable: true,class: 'text-left'},
          {key: 'SlabTwoAmount',label: 'Slab Two Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFour',label: 'Slab Four',sortable: true,class: 'text-left'},
          {key: 'SlabThree',label: 'Slab Three',sortable: true,class: 'text-left'},
          { key: 'SlabFiveAmount', label: 'Slab Five Amount', sortable: true, class: 'text-left' },
          { key: 'SlabOne', label: 'Slab One', sortable: true, class: 'text-left' },
          { key: 'SlabThreeAmount', label: 'Slab Three Amount', sortable: true, class: 'text-left' },
          {key: 'AfterTrnCountDaily',label: 'After Trn Count Daily',sortable: true,class: 'text-left'},
          {key: 'Status',label: 'Status',sortable: true,class: 'text-left'},
          {key: 'SlabOneAmount',label: 'Slab One Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
         {key: 'Amount',label: 'Amount',sortable: true,class: 'text-left'},
          { key: 'SlabTwo', label: 'Slab Two', sortable: true, class: 'text-left' },
           { key: 'BankCode', label: 'Bank Code', sortable: true, class: 'text-left' },
            { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
            { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
            { key: 'AmountType', label: 'Amount Type', sortable: true, class: 'text-left' },
            { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_CH.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                VatAmount:item.VatAmount,
         Account:item.Account,
         VatAmountType:item.VatAmountType,
         Description:item.Description,
         SlabFourAmount:item.SlabFourAmount,
         SlabFive:item.SlabFive,
         AfterTrnCountMonthly:item.AfterTrnCountMonthly,
         CreatedAt:item.CreatedAt,
         VatAccount:item.VatAccount,
         SlabTwoAmount:item.SlabTwoAmount,
         SlabFour:item.SlabFour,
         SlabThree:item.SlabThree,
         SlabFiveAmount:item.SlabFiveAmount,
         SlabOne:item.SlabOne,
         SlabThreeAmount:item.SlabThreeAmount,
         AfterTrnCountDaily:item.AfterTrnCountDaily,
         Status:item.Status,
         SlabOneAmount:item.SlabOneAmount,
         CreatedBy:item.CreatedBy,
         Amount:item.Amount,
         SlabTwo:item.SlabTwo,
         BankCode:item.BankCode,
         Code:item.Code,
         AccountingEntry:item.AccountingEntry,
         AmountType:item.AmountType,
         Frequency:item.Frequency
     
            };
          });

           }

           /////////////////////////////
           else if(this.response_product_select==='FT'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product.data_FT;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                Status: item.Status,
                AccountingEntry: item.AccountingEntry,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                MinAmount: item.MinAmount,
                CreatedAt:item.CreatedAt,
                BankCode: item.BankCode,
                Code:item.Code,
                MaxAmount:item.MaxAmount,
               
            };
          });

           }
           //////////////////////////////////
          else if(this.response_product_select==='LO'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'MaxRate', label: 'Max Rate', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
          { key: 'Penalty', label: 'Penalty', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          {key: 'MinRate',label: 'Min Rate',sortable: true,class: 'text-left'},
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'InterestBasis',label: 'Interest Basis',sortable: true,class: 'text-left'},
         
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_LO.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
               sn:count++,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                MaxRate: item.MaxRate,
                BankCode: item.BankCode,
                Code:item.Code,
                Penalty:item.Penalty,
                MaxAmount:item.MaxAmount,
                AccountingEntry:item.AccountingEntry,
                MinRate:item.MinRate,
                MinAmount:item.MinAmount,
                InterestBasis:item.InterestBasis,
            };
          });

           }
           /////////////////////////////
           else if(this.response_product_select==='IN'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'TransactionType', label: 'Transaction Type', sortable: true, class: 'text-left' },
          {key: 'Description',label: 'Description',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
          {key: 'Rate',label: 'Rate',sortable: true,class: 'text-left'},
          { key: 'AccrualType', label: 'Accrual Type', sortable: true, class: 'text-left' },
          { key: 'CreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
          { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
          {key: 'CodeExemption',label: 'Code Exemption',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
         { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_IN.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                Status	: item.Status,
                Account: item.Account,
                TransactionType: item.TransactionType,
                Description: item.Description,
                CreatedBy:item.CreatedBy,
                Rate: item.Rate,
                AccrualType:item.AccrualType,
                CreatedAt:item.CreatedAt,
                Frequency:item.Frequency,
                CodeExemption:item.CodeExemption,
                BankCode:item.BankCode,
                Code:item.Code,
                
            };
          });

           }
           //////////////////////////
          else if(this.response_product_select==='AE'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'Charge Code', label: 'Charge Code	', sortable: true, class: 'text-left' },
          { key: 'Creator', label: 'Creator', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'UpdatedAt',label: 'Updated At',sortable: true,class: 'text-left'},
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AE.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {

                sn:count++,
                Code:item.Code,
                Description	: item.Description,
                ChargeCode: item.ChargeCode,
                Creator: item.Creator,
                CreatedAt: item.CreatedAt,
                UpdatedAt:item.UpdatedAt,
                
            };
          });

           }

       }
        },
*/
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
     error_product(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success_product(newValue, oldValue) {
      console.log(`Updating from >>?????? ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
        this.notifyVue('success',newValue);
        this.modals.modal3 = !true;
         
console.log("Updated");
        //this.showDismissibleAlert=true;
      }
    },

/*response_product(newValue, oldValue) {
      //console.log(`Updating from Branch ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
       if(Object.keys(this.response_product).length !== 0){
    var product = [];
    product = this.response_product.data;
    this.option_product = product.map((item) => {
            return {
                value: `${item.Code}`,
                label: `${item.Code} (${item.Description})`
            };
        });
        console.log('//////////');
//console.log("&$$$$$&"+this.option_branch);
      
    }
        },
     response_glL2(newValue, oldValue) {
      //console.log(`Updating from Role ${JSON.stringify(oldValue)} to ${JSON.stringify(newValue)}`);
      if(Object.keys(this.response_glL2).length !== 0){
    var gl = [];
    gl = this.response_glL2.data;
    this.option_gl = gl.map((item) => {
            return {
                value: item.glLevel2Description,
                label: item.glLevel2Description
            };
        });
 //       console.log('//////////');
//console.log("&$$$$$&"+this.option_role);
      
    }
   
        },*/


    success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
          this.modals.modal3=false;
         this.modals.modal0=false;
         
        //this.showDismissibleAlert=true;
      }
    },
   
  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
      console.log(`select ${JSON.stringify(this.response_prod)}`);
        console.log(`select ${JSON.stringify(this.response_product)}`);
      console.log(`select ${this.response_product_select}`);

if('data' in this.response_product_AC){
this.fields_AC=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'MinBalance', label: 'Min Balance', sortable: true, class: 'text-left' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'AllowCash',label: 'Allow Cash',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'MaxBalance', label: 'Max Balance', sortable: true, class: 'text-left' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'AllowCard', label: 'Allow Card', sortable: true, class: 'text-left' },
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
          {key: 'Type',label: 'Type',sortable: true,class: 'text-left'},
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
         {key: 'AllowDebit',label: 'Allow Debit',sortable: true,class: 'text-left'},
          { key: 'MaxDebitPerTxn', label: 'Max Debit Per Txn', sortable: true, class: 'text-left' },
           { key: 'AllowCredit', label: 'Allow Credit', sortable: true, class: 'text-left' },
            { key: 'MaxCreditPerTxn', label: 'Max Credit Per Txn', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AC.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_AC = data.map((item) => {
            return {
                sn:count++,
                MinBalance:item.MinBalance,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                AllowCash:item.AllowCash,
                BankCode: item.BankCode,
                MaxBalance:item.MaxBalance,
                Code:item.Code,
                AllowCard:item.AllowCard,
                AllowFt:item.AllowFt,
                Type:item.Type,
                AllowChannel:item.AllowChannel,
                AllowDebit:item.AllowDebit,
                MaxDebitPerTxn:item.MaxDebitPerTxn,
                AllowCredit:item.AllowCredit,
                MaxCreditPerTxn:item.MaxCreditPerTxn
     
            };
          });

           }
  
if('data' in this.response_product_CH){
this.fields_CH=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'VatAmount', label: 'Vat Amount', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'VatAmountType', label: 'Vat Amount Type', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          {key: 'SlabFourAmount',label: 'Slab Four Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFive',label: 'Slab Five',sortable: true,class: 'text-left'},
          {key: 'AfterTrnCountMonthly',label: 'After Trn Count Monthly',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'VatAccount',label: 'Vat Account',sortable: true,class: 'text-left'},
          {key: 'SlabTwoAmount',label: 'Slab Two Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFour',label: 'Slab Four',sortable: true,class: 'text-left'},
          {key: 'SlabThree',label: 'Slab Three',sortable: true,class: 'text-left'},
          { key: 'SlabFiveAmount', label: 'Slab Five Amount', sortable: true, class: 'text-left' },
          { key: 'SlabOne', label: 'Slab One', sortable: true, class: 'text-left' },
          { key: 'SlabThreeAmount', label: 'Slab Three Amount', sortable: true, class: 'text-left' },
          {key: 'AfterTrnCountDaily',label: 'After Trn Count Daily',sortable: true,class: 'text-left'},
          {key: 'Status',label: 'Status',sortable: true,class: 'text-left'},
          {key: 'SlabOneAmount',label: 'Slab One Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
         {key: 'Amount',label: 'Amount',sortable: true,class: 'text-left'},
          { key: 'SlabTwo', label: 'Slab Two', sortable: true, class: 'text-left' },
           { key: 'BankCode', label: 'Bank Code', sortable: true, class: 'text-left' },
            { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
            { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
            { key: 'AmountType', label: 'Amount Type', sortable: true, class: 'text-left' },
            { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_CH.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_CH = data.map((item) => {
            return {
                sn:count++,
                VatAmount:item.VatAmount,
         Account:item.Account,
         VatAmountType:item.VatAmountType,
         Description:item.Description,
         SlabFourAmount:item.SlabFourAmount,
         SlabFive:item.SlabFive,
         AfterTrnCountMonthly:item.AfterTrnCountMonthly,
         CreatedAt:item.CreatedAt,
         VatAccount:item.VatAccount,
         SlabTwoAmount:item.SlabTwoAmount,
         SlabFour:item.SlabFour,
         SlabThree:item.SlabThree,
         SlabFiveAmount:item.SlabFiveAmount,
         SlabOne:item.SlabOne,
         SlabThreeAmount:item.SlabThreeAmount,
         AfterTrnCountDaily:item.AfterTrnCountDaily,
         Status:item.Status,
         SlabOneAmount:item.SlabOneAmount,
         CreatedBy:item.CreatedBy,
         Amount:item.Amount,
         SlabTwo:item.SlabTwo,
         BankCode:item.BankCode,
         Code:item.Code,
         AccountingEntry:item.AccountingEntry,
         AmountType:item.AmountType,
         Frequency:item.Frequency
     
            };
          });
}

if('data' in this.response_product_FT){
this.fields_FT=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product.data_FT;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_FT = data.map((item) => {
            return {
                sn:count++,
                Status: item.Status,
                AccountingEntry: item.AccountingEntry,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                MinAmount: item.MinAmount,
                CreatedAt:item.CreatedAt,
                BankCode: item.BankCode,
                Code:item.Code,
                MaxAmount:item.MaxAmount,
               
            };
          });

           }

    if('data' in this.response_product_LO){
this.fields_LO=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'MaxRate', label: 'Max Rate', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
          { key: 'Penalty', label: 'Penalty', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          {key: 'MinRate',label: 'Min Rate',sortable: true,class: 'text-left'},
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'InterestBasis',label: 'Interest Basis',sortable: true,class: 'text-left'},
         
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_LO.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_LO = data.map((item) => {
            return {
               sn:count++,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                MaxRate: item.MaxRate,
                BankCode: item.BankCode,
                Code:item.Code,
                Penalty:item.Penalty,
                MaxAmount:item.MaxAmount,
                AccountingEntry:item.AccountingEntry,
                MinRate:item.MinRate,
                MinAmount:item.MinAmount,
                InterestBasis:item.InterestBasis,
            };
          });

           }

if('data' in this.response_product_IN){
this.fields_IN=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'TransactionType', label: 'Transaction Type', sortable: true, class: 'text-left' },
          {key: 'Description',label: 'Description',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
          {key: 'Rate',label: 'Rate',sortable: true,class: 'text-left'},
          { key: 'AccrualType', label: 'Accrual Type', sortable: true, class: 'text-left' },
          { key: 'CreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
          { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
          {key: 'CodeExemption',label: 'Code Exemption',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
         { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_IN.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_IN = data.map((item) => {
            return {
                sn:count++,
                Status	: item.Status,
                Account: item.Account,
                TransactionType: item.TransactionType,
                Description: item.Description,
                CreatedBy:item.CreatedBy,
                Rate: item.Rate,
                AccrualType:item.AccrualType,
                CreatedAt:item.CreatedAt,
                Frequency:item.Frequency,
                CodeExemption:item.CodeExemption,
                BankCode:item.BankCode,
                Code:item.Code,
                
            };
          });

           }

if('data' in this.response_product_AE){
this.fields_AE=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'Charge Code', label: 'Charge Code	', sortable: true, class: 'text-left' },
          { key: 'Creator', label: 'Creator', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'UpdatedAt',label: 'Updated At',sortable: true,class: 'text-left'},
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AE.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items_AE = data.map((item) => {
            return {

                sn:count++,
                Code:item.Code,
                Description	: item.Description,
                ChargeCode: item.ChargeCode,
                Creator: item.Creator,
                CreatedAt: item.CreatedAt,
                UpdatedAt:item.UpdatedAt,
                
            };
          });

           }

       /*if(Object.keys(this.response_product).length !== 0){
           if(this.response_product_select==='AC'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'MinBalance', label: 'Min Balance', sortable: true, class: 'text-left' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'AllowCash',label: 'Allow Cash',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'MaxBalance', label: 'Max Balance', sortable: true, class: 'text-left' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'AllowCard', label: 'Allow Card', sortable: true, class: 'text-left' },
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
          {key: 'Type',label: 'Type',sortable: true,class: 'text-left'},
          {key: 'AllowFt',label: 'Allow Ft',sortable: true,class: 'text-left'},
         {key: 'AllowDebit',label: 'Allow Debit',sortable: true,class: 'text-left'},
          { key: 'MaxDebitPerTxn', label: 'Max Debit Per Txn', sortable: true, class: 'text-left' },
           { key: 'AllowCredit', label: 'Allow Credit', sortable: true, class: 'text-left' },
            { key: 'MaxCreditPerTxn', label: 'Max Credit Per Txn', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AC.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                MinBalance:item.MinBalance,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                AllowCash:item.AllowCash,
                BankCode: item.BankCode,
                MaxBalance:item.MaxBalance,
                Code:item.Code,
                AllowCard:item.AllowCard,
                AllowFt:item.AllowFt,
                Type:item.Type,
                AllowChannel:item.AllowChannel,
                AllowDebit:item.AllowDebit,
                MaxDebitPerTxn:item.MaxDebitPerTxn,
                AllowCredit:item.AllowCredit,
                MaxCreditPerTxn:item.MaxCreditPerTxn
     
            };
          });

           }
//////////////////////////////////
else if(this.response_product_select==='CH'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'VatAmount', label: 'Vat Amount', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'VatAmountType', label: 'Vat Amount Type', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          {key: 'SlabFourAmount',label: 'Slab Four Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFive',label: 'Slab Five',sortable: true,class: 'text-left'},
          {key: 'AfterTrnCountMonthly',label: 'After Trn Count Monthly',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'VatAccount',label: 'Vat Account',sortable: true,class: 'text-left'},
          {key: 'SlabTwoAmount',label: 'Slab Two Amount',sortable: true,class: 'text-left'},
          {key: 'SlabFour',label: 'Slab Four',sortable: true,class: 'text-left'},
          {key: 'SlabThree',label: 'Slab Three',sortable: true,class: 'text-left'},
          { key: 'SlabFiveAmount', label: 'Slab Five Amount', sortable: true, class: 'text-left' },
          { key: 'SlabOne', label: 'Slab One', sortable: true, class: 'text-left' },
          { key: 'SlabThreeAmount', label: 'Slab Three Amount', sortable: true, class: 'text-left' },
          {key: 'AfterTrnCountDaily',label: 'After Trn Count Daily',sortable: true,class: 'text-left'},
          {key: 'Status',label: 'Status',sortable: true,class: 'text-left'},
          {key: 'SlabOneAmount',label: 'Slab One Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
         {key: 'Amount',label: 'Amount',sortable: true,class: 'text-left'},
          { key: 'SlabTwo', label: 'Slab Two', sortable: true, class: 'text-left' },
           { key: 'BankCode', label: 'Bank Code', sortable: true, class: 'text-left' },
            { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
            { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
            { key: 'AmountType', label: 'Amount Type', sortable: true, class: 'text-left' },
            { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_CH.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                VatAmount:item.VatAmount,
         Account:item.Account,
         VatAmountType:item.VatAmountType,
         Description:item.Description,
         SlabFourAmount:item.SlabFourAmount,
         SlabFive:item.SlabFive,
         AfterTrnCountMonthly:item.AfterTrnCountMonthly,
         CreatedAt:item.CreatedAt,
         VatAccount:item.VatAccount,
         SlabTwoAmount:item.SlabTwoAmount,
         SlabFour:item.SlabFour,
         SlabThree:item.SlabThree,
         SlabFiveAmount:item.SlabFiveAmount,
         SlabOne:item.SlabOne,
         SlabThreeAmount:item.SlabThreeAmount,
         AfterTrnCountDaily:item.AfterTrnCountDaily,
         Status:item.Status,
         SlabOneAmount:item.SlabOneAmount,
         CreatedBy:item.CreatedBy,
         Amount:item.Amount,
         SlabTwo:item.SlabTwo,
         BankCode:item.BankCode,
         Code:item.Code,
         AccountingEntry:item.AccountingEntry,
         AmountType:item.AmountType,
         Frequency:item.Frequency
     
            };
          });

           }

           /////////////////////////////
           else if(this.response_product_select==='FT'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_FT.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                Status: item.Status,
                AccountingEntry: item.AccountingEntry,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                MinAmount: item.MinAmount,
                CreatedAt:item.CreatedAt,
                BankCode: item.BankCode,
                Code:item.Code,
                MaxAmount:item.MaxAmount,
               
            };
          });

           }
           //////////////////////////////////
          else if(this.response_product_select==='LO'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'MaxRate', label: 'Max Rate', sortable: true, class: 'text-left' },
          { key: 'CreatedBy', label: 'Created By', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
          { key: 'Penalty', label: 'Penalty', sortable: true, class: 'text-left' },
          { key: 'MaxAmount', label: 'Max Amount', sortable: true, class: 'text-left' },
          { key: 'AccountingEntry', label: 'Accounting Entry', sortable: true, class: 'text-left' },
          {key: 'MinRate',label: 'Min Rate',sortable: true,class: 'text-left'},
          {key: 'MinAmount',label: 'Min Amount',sortable: true,class: 'text-left'},
          {key: 'InterestBasis',label: 'Interest Basis',sortable: true,class: 'text-left'},
         
            { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_LO.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
               sn:count++,
                Status	: item.Status,
                Description: item.Description,
                CreatedBy: item.CreatedBy,
                CreatedAt: item.CreatedAt,
                MaxRate: item.MaxRate,
                BankCode: item.BankCode,
                Code:item.Code,
                Penalty:item.Penalty,
                MaxAmount:item.MaxAmount,
                AccountingEntry:item.AccountingEntry,
                MinRate:item.MinRate,
                MinAmount:item.MinAmount,
                InterestBasis:item.InterestBasis,
            };
          });

           }
           /////////////////////////////
           else if(this.response_product_select==='IN'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Status', label: 'Status', sortable: true, class: 'text-left' },
          { key: 'Account', label: 'Account', sortable: true, class: 'text-left' },
          { key: 'TransactionType', label: 'Transaction Type', sortable: true, class: 'text-left' },
          {key: 'Description',label: 'Description',sortable: true,class: 'text-left'},
          {key: 'CreatedBy',label: 'Created By',sortable: true,class: 'text-left'},
          {key: 'Rate',label: 'Rate',sortable: true,class: 'text-left'},
          { key: 'AccrualType', label: 'Accrual Type', sortable: true, class: 'text-left' },
          { key: 'CreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
          { key: 'Frequency', label: 'Frequency', sortable: true, class: 'text-left' },
          {key: 'CodeExemption',label: 'Code Exemption',sortable: true,class: 'text-left'},
          {key: 'BankCode',label: 'Bank Code',sortable: true,class: 'text-left'},
          {key: 'Code',label: 'Code',sortable: true,class: 'text-left'},
         { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_IN.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                Status	: item.Status,
                Account: item.Account,
                TransactionType: item.TransactionType,
                Description: item.Description,
                CreatedBy:item.CreatedBy,
                Rate: item.Rate,
                AccrualType:item.AccrualType,
                CreatedAt:item.CreatedAt,
                Frequency:item.Frequency,
                CodeExemption:item.CodeExemption,
                BankCode:item.BankCode,
                Code:item.Code,
                
            };
          });

           }
           //////////////////////////
          else if(this.response_product_select==='AE'){
this.fields=[
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'Code', label: 'Code', sortable: true, class: 'text-left' },
          { key: 'Description', label: 'Description', sortable: true, class: 'text-left' },
          { key: 'Charge Code', label: 'Charge Code	', sortable: true, class: 'text-left' },
          { key: 'Creator', label: 'Creator', sortable: true, class: 'text-left' },
          {key: 'CreatedAt',label: 'Created At',sortable: true,class: 'text-left'},
          {key: 'UpdatedAt',label: 'Updated At',sortable: true,class: 'text-left'},
          { key: 'action', label: 'Action' }
      
        ]; 
        var data = []; 
    data = this.response_product_AE.data;
    console.log("&$$$$$&"+this.response_product.data);
    var count = 1;
    this.items = data.map((item) => {
            return {

                sn:count++,
                Code:item.Code,
                Description	: item.Description,
                ChargeCode: item.ChargeCode,
                Creator: item.Creator,
                CreatedAt: item.CreatedAt,
                UpdatedAt:item.UpdatedAt,
                
            };
          });

           }

       }*/
    },
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%; 
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{
  
  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
    margin-bottom: 0;
}

.view-more-base .view-more-base-value {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #556ee6;
    font-weight: 600;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
.view-more-customer-info .view-more-top .customer-bio {
    display: flex;
    margin: 0;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar {
    margin-right: 10px;
}
.view-more-customer-info p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-base .view-more-base-value .customer-signature {
    height: auto;
    width: 100%;
    cursor: pointer;
}
.sample-file-download {
    cursor: pointer;
}
.view-more-customer-info .view-more-top .customer-bio .customer-avatar img {
    height: 80px;
    border-radius: 10%;
    cursor: pointer;
}
img, svg {
    vertical-align: middle;
}
.view-more-customer-info .view-more-top .customer-bio .name {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 10px;
}
.view-more-customer-info .view-more-top .customer-bio .age {
    display: block;
    margin-bottom: 5px;
}
.view-more-customer-info .view-more-top .customer-bio .status {
    display: inline-block;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    height: calc(1.5em + 1.25rem + 5px);
    line-height: 1.5;
    width: 100%;
}

</style>