<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Manual Reversal</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Transaction</a></li>
              <li class="breadcrumb-item active" aria-current="page">Manual Reversal</li>
            </ol>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <card>
    <!-- Card header -->
    <h3 slot="header" class="mb-0">Manual Reversal</h3>

    <!-- Card body -->
    
    <form 
          @submit.prevent="submitForm">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="CBA Reference"
                      name="CBA Reference"
                      placeholder="CBA Reference"
                      required
                      v-model="model.cbaReference">
          </base-input>
        </div>
      </div>

      <div class="text-right">
          <base-button icon type="primary" native-type="submit" :class="{disabled:loading_transaction}">
     <span class="btn-inner--text">Submit</span>
       <span :class="{'spinner-border': loading_transaction}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
    </form>
  </card>
  
        </div>
      </div>
</div>
</div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader';
import { mapState, mapActions, mapGetters } from "vuex";
export default {

    components: {
        BaseHeader,
        
      },
    data() {
      return {
          model: {
           cbaReference:''
           
        }
      }
    },
    methods: {
 
submitForm() {
   this.$store.dispatch("reverseTransaction", {
              bankCode: this.userInformation.userBankCode,
cbaReference: this.model.cbaReference,
module: "BA",
source: "WEB"
 } , { root: false });

        
},
         
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    },
    watch:{
    error_transaction(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },
    success_transaction(newValue, oldValue) {
      ///console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        document.getElementById("submitForm").reset();
        //this.showDismissibleAlert=true;
      }
    }
    },
   mounted:function (){
    
    },
    computed:{
           ...mapState({
              userInformation: state => state.auth.userInfo,
        response_user: state => state.user.user_response,
    response_transaction: state => state.transaction.transaction_response, loading_transaction: state => state.transaction.transaction_loading,
    success_transaction: state => state.transaction.transaction_success,error_transaction: state => state.transaction.transaction_errors,
    error_user: state => state.user.user_error
    
    }),
    ...mapActions(['userRead']),
    ...mapGetters([""]),
    
    }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
 .form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
</style>
