<template>
  <!--<div class="content">

    <div class="card">-->

      <div>
          <notifications></notifications>
        <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
<!--          <template slot="header">-->
<!--            <h3 class="mb-0">All Internal Account</h3>-->
<!--          </template>-->
          <div>
            <div v-if="Object.keys(response).length !== 0">

  <b-container class="table-container"> <!--class="over-flow">-->


    <!-- User Interface controls -->
<!--     <div class="row">-->

<!--      <div class="col-lg-6 col-md-6 col-sm-6 col-12">-->
<!--<base-input label="Search"-->
<!--                      name="search"-->
<!--                      placeholder="Search"-->
<!--                      v-model="filter">-->
<!--          </base-input>-->
<!--        </div>-->
<!--</div>-->
<!--<b-row>-->
<!--      <b-col sm="5" md="6" class="my-1">-->
<!--        <b-form-group-->
<!--          label="Per page"-->
<!--          label-for="per-page-select"-->
<!--          label-cols-sm="6"-->
<!--          label-cols-md="4"-->
<!--          label-cols-lg="3"-->
<!--          label-align-sm="right"-->
<!--          label-size="sm"-->
<!--          class="mb-0"-->
<!--        >-->
<!--          <b-form-select-->
<!--            id="per-page-select"-->
<!--            v-model="perPage"-->
<!--            :options="pageOptions"-->
<!--            size="sm"-->
<!--          ></b-form-select>-->
<!--        </b-form-group>-->
<!--      </b-col>-->

<!--      <b-col sm="7" md="6" class="my-1">-->
<!--        <b-pagination-->
<!--          v-model="currentPage"-->
<!--          :total-rows="response.data.length"-->
<!--          :per-page="perPage"-->
<!--          align="fill"-->
<!--          size="sm"-->
<!--          class="my-0"-->
<!--        ></b-pagination>-->
<!--      </b-col>-->
<!--    </b-row>-->

    <!-- Main table element -->
    <div class="table-section">
    <b-table striped hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :head-variant="light"
      stacked="lg"
      show-empty
      small
      @filtered="onFiltered"
    >
      <!--<template #cell(privileges)="row">
        <div class="pointer" @click="modalsDisplay(row.item, row.index)">
          <i class="ni ni-zoom-split-in"></i>
          <span class="ml-1">Info</span>


        </div>
      </template>-->

      <template #cell(accountStatus)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot mr-4" type="">
                      <div v-if="row.value==='ACTIVE'">
                        <badge type="success">ACTIVE</badge>
                        </div>
                        <div v-else>
                        <badge type="danger">PENDING</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>


      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>



  </b-container>
          </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>
          </div>
          </card>
      </div>
   <!-- </div>

  </div>-->
</template>

<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from '@/components/LoadingPanel';
import Loader from '@/components/Loader';

  export default {
    components: {
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,
      //Loader

    },
    data() {
      return {
        light:'light',
        modals:{
          modal3:false,
          modal0:false,
          modal1:false,
        },
        items:[],
        fields: [
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'accountBankCode', label: 'Bank Code', sortable: true, class: 'text-left' },
          { key: 'accountName', label: 'Account Name', sortable: true, class: 'text-left' },
          { key: 'accountInterestRate', label: 'Interest Rate', sortable: true, class: 'text-left' },
          { key: 'accountClass', label: 'Account Class', sortable: true, class: 'text-left' },
          {key: 'accountCurrency',label: 'Currency',sortable: true,class: 'text-left'},
          { key: 'accountBalance', label: 'Account Balance', sortable: true, class: 'text-left' },
          { key: 'accountNumber', label: 'Account Number  ', sortable: true, class: 'text-left' },
        { key: 'accountCustomerId', label: 'CustomerId', sortable: true, class: 'text-left' },
          { key: 'accountStatus', label: 'Status',sortable: true, class: 'text-left' }
        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({errors: state => state.internalAccount.internal_acc_errors,loading: state => state.internalAccount.internal_acc_loading,
    success: state => state.internalAccount.internal_acc_success,userInformation: state => state.auth.userInfo,
    response: state => state.internalAccount.internal_acc_response,
    }),
    ...mapActions(['internalAccountRead']),
    ...mapGetters([""]),

    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      callapi(){
        //console.log(`<<<>>> ${this.userInformation.role.roleBankCode}`);
        this.$store.dispatch("roleRead", {
              readAll: "YES",
              roleBankCode:"00004"} , { root: false });

      }
    },

    watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },

    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',` Role Created ${newValue}fully`);
         this.modals.modal3=false;
         this.modals.modal0=false;
        //this.showDismissibleAlert=true;
      }
    },

    response(newValue, oldValue){
      if(Object.keys(this.response).length !== 0){
    var data = [];
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
                sn:count++,
                accountBankCode: item.accountBankCode,
                accountName: item.accountName,
                accountInterestRate: item.accountInterestRate,
                accountType: item.accountType,
                accountClass: item.accountClass,
                accountCurrency: item.accountCurrency,
                accountBalance:item.accountBalance,
                accountNumber:item.accountNumber,
                accountCustomerId:item.accountCustomerId,
                accountStatus:item.accountStatus
            };
        });
      //this.totalRows = this.items.length;
      //this.items = data;
    }
    }

  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
    this.$store.dispatch("internalAccountRead", {
              readAll: "YES",
               accountBankCode:this.userInformation.userBankCode
              } , { root: false });

    },
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    max-width: 100%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}</style>
