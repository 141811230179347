<template>
<div>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">View Loans </h6>
<!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
<!--            <ol class="breadcrumb breadcrumb-links">-->
<!--              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>-->
<!--              <li class="breadcrumb-item"><a href="#">Loan</a></li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">View Loans</li>           -->
<!--              </ol>-->
<!--          </nav>-->
        </div>
      <div class="col-lg-6 col-5 text-right">
        <router-link to="/loan/book-loan"><base-button size="md" type="neutral">Book Loans</base-button></router-link>

        <!--          <base-button size="sm" type="neutral">New</base-button>-->
<!--          <base-button size="sm" type="neutral">Filters</base-button>-->
     </div>
      </div>
      </base-header>
<div class="container-fluid mt--6">
  <div class="row">
    <!--<div class="col-lg-4 col-md-4 col-sm-4 col-12">
        <user-info></user-info>
          </div>-->
         <div class="col-lg-12 col-md-12 col-sm-12 col-12">

             <card class="no-border-card" body-classes="px-3 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0">View Loans</h3>
          </template>
          <div>
            <div v-if="Object.keys(response).length !== 0">

  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <div class="form-body">
      <div class="table-section col-12">
           <div class="m-2 top-area">
                    <div class="search-bar">
                      <input
                        type="search"
                        class="search"
                        placeholder="Search.."
                        v-model="filter"
                        name="search"
                        required
                      />
                      <div class="search-icon">
                        <img
                          src="@/assets/search-bar.jpeg"
                          alt=""
                          class=""
                          outline
                          :disabled="loading"
                          @click="querySingleUser()"
                        /><span :class="{ 'spinner-border': loading }"></span>
                      </div>
                    </div>
                    <div class="child-nav">
                      <div class="btn-nav">
                        <select class="sel-opt" v-model="perPage">
                          <option
                            v-for="option in [
                              { label: '5', value: 5 },
                              { label: '10', value: 10 },
                              { label: '50', value: 50 },
                              { label: '100', value: 100 },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                          ></option>
                        </select>
                        <span class="page">Per page</span>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-7 col-12">
                        <div class="text-right">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="items.length"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
    <!-- User Interface controls -->
     <!-- <div class="row">

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
<base-input label="Search"
                      name="search"
                      placeholder="Search"
                      v-model="filter">
          </base-input>
        </div>
</div>
<b-row>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row> -->

    <!-- Main table element -->
    <div>
    <b-table striped hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"
      @filtered="onFiltered"
    >

    <!--<template #table-colgroup="scope">
    <col
      v-for="field in scope.fields"
      :key="field.key"
      :style="{ width: '180px',height:'200px' }"
    >
  </template>-->
  <template #cell(moreInfo)="row">
                       <div class="name-container" @click="viewInfo(row.item, row.index)">
                    <el-tooltip class="mr-3"  content="View Loan info" placement="left">
                    <i class="fas fa-info-circle hand"></i>
                    </el-tooltip>
                   <!--- <span v-for="n in row.value.split(' - ')" :key="n">
              <span>{{n}}</span>
              <br>
          </span>-->

                       </div>

                  </template>
  <!-- A custom formatted header cell for field 'name' -->
      <template #cell(accountName)="data">
          <span v-for="n in data.value.split(' ')" :key="n" >
              <span>{{n}}</span>
              <br>
          </span>
</template>
    <template #cell(loanStatus)="row">
       <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
        <template >
                    <badge class="badge-dot" type="">
                      <div v-if="row.value==='PENDING'||row.value==='LIQUIDATE'">
                        <badge type="success" size="lg">{{row.value}}</badge>
                        </div>
                        <div v-else>
                        <badge type="danger" size="lg">{{row.value}}</badge>
                        </div>
                        <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                    </badge>
                </template>
      </template>

        <template #cell(action)="row">
        <template>
                    <div v-if="!loading">
                    <el-dropdown trigger="click" class="dropdown">
                    <span class="btn btn-sm btn-icon-only text-dark">
                      <i class="fas fa-ellipsis-v mt-2"></i>
                      </span>
                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                            <a class="dropdown-item" @click="initiateApproval(row.item, row.index)">
                             <i class="fas fa-check-double"></i>
                              Approve</a>
                            <a class="dropdown-item" @click="initiateReject(row.item, row.index)" >
                                <i class="fas fa-ban"></i>
                                Reject
                            </a>
                            <a class="dropdown-item" @click="initiateLiquidate(row.item, row.index)" >
                                <i class="fas fa-money-check"></i>
                                Liquidated
                            </a>
                            <a class="dropdown-item" @click="initiateSettle(row.item, row.index)" >
                                <i class="fas fa-book"></i>
                                Settled
                            </a>
                            <a class="dropdown-item" @click="initiateDelete(row.item, row.index)" >
                                <i class="fas fa-trash"></i>
                                Deleted
                            </a>
                            <a class="dropdown-item" @click="schedule(row.item, row.index)" >
                                <i class="fas fa-list"></i>
                                View Loan Schedule
                            </a>
                        </el-dropdown-menu>
                    </el-dropdown>
                    </div>
                    <div v-else>
                        <span :class="{'spinner-border': loading}"></span>
                    </div>
                </template>
      </template>



      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>




  </div>
  <!--</b-container>-->
          </div>
            </div>
          <div v-else>
            <LoadingPanel></LoadingPanel>
          </div>


          </div>
          </card>

<modal :show.sync="modals.modal3"
               body-classes="p-0"
               modal-classes="modal-dialog-centered modal-md">
               <div class="vodal-dialog" style="width: 500px; height: 450px; animation-duration: 300ms;">
                     <article class="item-right" ><!-- @click="displayDetail()">-->
                    <a><span @click="modals.modal3 = false"
            ><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="fill:#171717;" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
</span></a></article>
<!---<div class="card">-->
  <div class="card-body">
    <div>
      <div class="view-more-customer-info">
        <div class="view-more-customer-info-wrap">
            <div class="view-more-top">
            <h4 class="card-title mb-4">Loan Info</h4>
            </div>
            <div class="view-more-base">
                          <div class="table-responsive">
                            <table class="table table-striped table-hover mb-0">
                              <tbody>
                                    <tr v-for="(value,key) in modalItems" :key="key">
                                      <td><p class="view-more-base-tag text-capitalize">{{key}}</p></td>
                                      <td><p class="view-more-base-value">{{value}}</p></td>
                                      </tr>
                                        </tbody>
                                        </table>
                                            </div></div>

                                            </div></div></div>
  </div>
    <!---</card>
                                                </div></div>-->

               </div>

        </modal>
         <modal modal-classes="modal-sm" :show.sync="modals.modal0">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
     </template>
     <div>
       <form id="form_reason" @submit.prevent="completeApproval">
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <label>Please enter the OTP sent to your mail.</label>
          </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <base-input label="OTP"
                      name="OTP"
                      placeholder="OTP"
                      required
                      v-model="otp">
          </base-input>
        </div>
      </div>
       </form>
     </div>
     <template slot="footer">
         <!--<base-button type="secondary" @click="modals.modal0 = false">No</base-button>-->
         <base-button :class="{disabled:loading}" type="primary" native-type="submit" form="form_reason" >{{button}} <span :class="{'spinner-border': loading}"></span></base-button>
     </template>
   </modal>

        </div>
  </div>

</div>
</div>
</template>
<script>
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { mapState, mapActions, mapGetters } from "vuex";
import BaseHeader from '@/components/BaseHeader';
import LoadingPanel from '@/components/LoadingPanel';

  export default {
    components: {
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      LoadingPanel,


    },
    data() {
      return {
         modalItems:{},
         selectedItem:{},
         light:'light',
         otp:'',
         condition:'',
         title:'',
         button:'',
        modals:{
            modal0:false,
            modal3:false
        },
        items:[],
        fields: [
        { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'moreInfo', label: 'More Info', sortable: true, class: 'text-left' },
          { key: 'loanProductCode', label: 'Loan product', sortable: true, class: 'text-left' },
          { key: 'accountName', label: 'Account Name', sortable: true, class: 'text-left' },
          { key: 'loanAccountNumber', label: 'Account Number', sortable: true, class: 'text-left' },
          { key: 'loanAmount', label: 'Amount', sortable: true, class: 'text-left' },
          { key: 'loanInterestRate', label: 'Interest Rate', sortable: true, class: 'text-left' },
          { key: 'loanTenure', label: 'Tenure', sortable: true, class: 'text-left' },
          { key: 'loanCreatedAt', label: 'Created At', sortable: true, class: 'text-left' },
          { key: 'loanStatus', label: 'Loan Status', sortable: true, class: 'text-left' },
          { key: 'accountMaker', label: 'Maker', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }

        ],

        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
       ...mapState({errors: state => state.loan.loan_errors,loading: state => state.loan.loan_loading,
    success: state => state.loan.loan_success,
    success1: state => state.loan.loan_success1
    ,userInformation: state => state.auth.userInfo,
    loading1: state => state.loan.loan_loading1,
    response: state => state.loan.loan_response,response_user: state => state.user.user_response,
    }),
    ...mapActions(['branchReadAll','branchUpdate']),
    ...mapGetters(["getBranchResponse"]),

    },
    /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
    methods: {
        completeApproval(){
            if(this.condition==="APPROVE"){
                  this.$store.dispatch("loanUpdateStatusApprove", {
       loanAction: "COMPLETE",
loanBankCode: this.selectedItem.loanBankCode,
loanChecker: this.selectedItem.loanMaker,
loanId: this.selectedItem.loanId,
loanOtp: this.otp,
loanStatus: "APPROVED"
      }, { root: false });
            }
            else if(this.condition==="REJECTED"){
                this.$store.dispatch("loanUpdateStatusApprove", {
       loanAction: "COMPLETE",
loanBankCode: this.selectedItem.loanBankCode,
loanChecker: this.selectedItem.loanMaker,
loanId: this.selectedItem.loanId,
loanOtp: this.otp,
loanStatus: "REJECTED"
      }, { root: false });
            }
        else if(this.condition==="LIQUIDATED"){
                this.$store.dispatch("loanUpdateStatusApprove", {
       loanAction: "COMPLETE",
loanBankCode: this.selectedItem.loanBankCode,
loanChecker: this.selectedItem.loanMaker,
loanId: this.selectedItem.loanId,
loanOtp: this.otp,
loanStatus: "LIQUIDATED"
      }, { root: false });
            }
            else if(this.condition==="SETTLED"){
                this.$store.dispatch("loanUpdateStatusApprove", {
       loanAction: "COMPLETE",
loanBankCode: this.selectedItem.loanBankCode,
loanChecker: this.selectedItem.loanMaker,
loanId: this.selectedItem.loanId,
loanOtp: this.otp,
loanStatus: "SETTLED"
      }, { root: false });
            }

        else{
             this.$store.dispatch("loanUpdateStatusApprove", {
       loanAction: "COMPLETE",
loanBankCode: this.selectedItem.loanBankCode,
loanChecker: this.selectedItem.loanMaker,
loanId: this.selectedItem.loanId,
loanOtp: this.otp,
loanStatus: "DELETED"
      }, { root: false });
      this.otp=""
        }

        },

        initiateApproval(item,index){
            console.log("approve");
            this.title="Confirm Loan Approval";
            this.button="Loan Approval";
            this.condition="APPROVE";
            this.selectedItem=item;
            this.$store.dispatch("loanUpdateStatus", {
       loanAction: "INITIATE",
loanBankCode: item.loanBankCode,
loanChecker: item.loanMaker,
loanId: item.loanId,
loanStatus: "APPROVED"
      }, { root: false });

        },

        initiateReject(item,index){
            console.log("reject");
            this.title="Confirm Loan Rejection";
            this.button="Loan Rejection"
            this.condition="REJECTED";
            this.selectedItem=item;
            this.$store.dispatch("loanUpdateStatus", {
       loanAction: "INITIATE",
loanBankCode: item.loanBankCode,
loanChecker: item.loanMaker,
loanId: item.loanId,
loanStatus: "REJECTED"
      }, { root: false });

        },

        initiateLiquidate(item,index){
            console.log("liquidate");
            this.title="Confirm Loan Liquidation";
            this.button="Loan Liquidation"
            this.condition="LIQUIDATED";
            this.selectedItem=item;
            this.$store.dispatch("loanUpdateStatus", {
       loanAction: "INITIATE",
loanBankCode: item.loanBankCode,
loanChecker: item.loanMaker,
loanId: item.loanId,
loanStatus: "LIQUIDATED"
      }, { root: false });

        },

        initiateSettle(item,index){
            console.log("settle");
            this.title="Confirm Loan Settle";
            this.button="Loan Settle"
            this.condition="SETTLED";
            this.selectedItem=item;
            this.$store.dispatch("loanUpdateStatus", {
       loanAction: "INITIATE",
loanBankCode: item.loanBankCode,
loanChecker: item.loanMaker,
loanId: item.loanId,
loanStatus: "SETTLED"
      }, { root: false });

        },

        initiateDelete(item,index){
            console.log("delete");
            this.title="Confirm Loan Delete";
            this.button="Loan Delete"
            this.condition="DELETED";
            this.selectedItem=item;
            this.$store.dispatch("loanUpdateStatus", {
       loanAction: "INITIATE",
loanBankCode: item.loanBankCode,
loanChecker: item.loanMaker,
loanId: item.loanId,
loanStatus: "DELETED"
      }, { root: false });

        },


schedule(item,index){
this.$store.dispatch("loanAmortization", {
       loanBankCode: item.loanBankCode,
        loanId: item.loanId
      }, { root: false });

},

viewInfo(item,index){
    this.modals.modal3 = true;
    this.modalItems=item;
},

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },

    },

    watch: {
        success1(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
         this.modals.modal0 = true;
        //this.showDismissibleAlert=true;
      }
    },
success(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',`${newValue}`);
         this.modals.modal0 = false;
        //this.showDismissibleAlert=true;
      }
    },
    errors(newValue, oldValue) {
      console.log(`Success from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',`${newValue}`);

        //this.showDismissibleAlert=true;
      }
    },

    response(newValue, oldValue){
         //console.log("<><<><><><"+this.response.data);
      if(Object.keys(this.response).length !== 0){
         if ('data' in this.response){
    var data = [];
    data = this.response.data;
    console.log("&$$$$$&"+this.response.data);
    var count = 1;
    this.items = data.map((item) => {
            return {
              sn:count++,
              loanBankCode:item.loanBankCode,
         accountUpdatedDate:item.accountUpdatedDate,
         loanFrequency:item.loanFrequency,
         accountInterestRate:item.accountInterestRate,
         accountPenalty:item.accountPenalty,
         accountCurrency:item.accountCurrency,
         loanCreatedAt:item.loanCreatedAt,
         loanOtp:item.loanOtp,
         accountMaker:item.accountMaker,
         accountCreatedDate:item.accountCreatedDate,
         accountCustomerId:item.accountCustomerId,
         accountOverdraftIssuedDate:item.accountOverdraftIssuedDate,
         accountDailyDebit:item.accountDailyDebit,
         loanStatus:item.loanStatus,
         loanInterestRate:`${item.loanInterestRate}%`,
         accountBankCode:item.accountBankCode,
         accountMonthlyCredit:item.accountMonthlyCredit,
         accountMonthlyDebit:item.accountMonthlyDebit,
         accountType:item.accountType,
         accountAllowNegativeBalance:item.accountAllowNegativeBalance,
         accountOverdraftExpiry:item.accountOverdraftExpiry,
         loanTerminatedBy:item.loanTerminatedBy,
         accountDateClosed:item.accountDateClosed,
         accountGlCategory:item.accountGlCategory,
         accountBranchCode:item.accountBranchCode,
         accountId:item.accountId,
         accountRate:item.accountRate,
         accountClass:item.accountClass,
         loanCbaReference:item.loanCbaReference,
         loanTenure:item.loanTenure,
         accountParent:item.accountParent,
         loanId:item.loanId,
         accountChecker:item.accountChecker,
         loanProductCode:item.loanProductCode,
         loanScheduleCount:item.loanScheduleCount,
         loanReason:item.loanReason,
         accountName:item.accountName,
         accountEndDate:item.accountEndDate,
         accountSort:item.accountSort,
         accountOverdraftStatus:item.accountOverdraftStatus,
         loanParent:item.loanParent,
         accountOverdraft:item.accountOverdraft,
         accountStatus:item.accountStatus,
         accountLedgerBalance:item.accountLedgerBalance,
         accountDateOpened:item.accountDateOpened,
         loanChecker:item.loanChecker,
         accountGlLevel1Code:item.accountGlLevel1Code,
         loanMaker:item.loanMaker,
         accountDailyCredit:item.accountDailyCredit,
         accountNumber:item.accountNumber,
         accountLastAccessTimestamp:item.accountLastAccessTimestamp,
         loanAmount:`₦ ${item.loanAmount}`,
         accountOverdraftRate:item.accountOverdraftRate,
         accountStartDate:item.accountStartDate,
         loanAccountNumber:item.loanAccountNumber,
         loanUpdatedAt:item.loanUpdatedAt,
         accountGlLevel2Code:item.accountGlLevel2Code,
         accountBalance:item.accountBalance,
         accountInterest:item.accountInterest


            };
          });
         }
         else{
             this.items = [];
         }

     // this.totalRows = this.items.length;
      //this.items = data;
    }
    }

  },
    updated() {
        //this.data = this.$store.getters.getPrivilageResponse;
        //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
    },
    created:function (){
    },
    mounted:function (){
this.$store.dispatch("loanRead", {
loanBankCode: this.userInformation.userBankCode,
              } , { root: false });

  }
  }
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.top-area {
  display: flex;
  margin-top: 0px !important;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  display: flex;
  justify-content: center;
  width: 20%;
}
.nav-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.child-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
}
.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.nav-btn {
  width: 35px;
  height: 35px;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.num-dis {
  width: 35px;
  height: 35px;

  background: #175d85;
  border: 1px solid #175d85;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.sel-opt {
  width: 28%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.sel-opt:focus {
  outline: none;
}

.page {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #175d85;
  margin: 10px;
}

.search-bar {
  width: 287px;
  height: 5vh;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search {
  background: rgba(196, 196, 196, 0.11);
  border: none;
  box-sizing: border-box;
  height: 5vh;
  width: 100%;
  padding: 10px;
}

.search:focus {
  outline: none;
}


::placeholder {
  color: rgba(23, 93, 133, 0.26);
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}


.table-section {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 40px;
  /*max-width: 100%;*/
  color: #6666 !important;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000000 !important;
  font-weight: 900;
}
.table-section tbody {
  max-width: 500px;
  overflow-x: scroll;
  color: #000 !important;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}
.hand{
    cursor: pointer;
}


.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.item-right {
   padding-left: 90%;
  cursor: pointer;
  margin:0px 0% 5px 0px;
    float:right!important;
}
 .vodal-dialog {
    overflow-y: scroll;
    overflow-x: hidden !important;
    background-color: #fff;
}
.vodal-dialog .card {
    box-shadow: none !important;
}
body{

  overflow-x: hidden !important;
}
.text-title{
  font-size: 0.875rem;
    font-weight: 600;
}
.view-more-customer-info {
    color: #545b62;
}
.view-more-customer-info .view-more-top {
    margin-bottom: 10px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
p{
    color: #495057;
}
.view-more-customer-info p {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
}
.view-more-top .queue-value {
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    margin-bottom: 20px;
}
.view-more-top .queue-title {
    margin-bottom: 2px;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: #9E9E9E;
}
.view-more-customer-info {
    color: #545b62;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #495057;
}
.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #eff2f7;
}
.view-more-base .view-more-base-tag {
    margin-bottom: 0;
}

.view-more-base .view-more-base-value {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: #556ee6;
    font-weight: 600;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}
.title{
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    color: #546ee6;
}
.sub{
    margin-bottom: 10px;
}
</style>
