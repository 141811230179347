<template>
  <div class="content">
    <notifications></notifications>
    <div class="mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-3 pb-1"
          footer-classes="pb-2"
        >
          <!--          <template slot="header">-->
          <!--            <h3 class="mb-0">Roles</h3>-->
          <!--          </template>-->
          <div>
            <div v-if="Object.keys(response).length !== 0">
              <div class="form-body">
                <div class="table-section Table-striped col-12">
                  <div class="m-2 top-area">
                    <div class="search-bar">
                      <input
                        type="search"
                        class="search"
                        placeholder="Search.."
                        v-model="filter"
                        name="search"
                      />
                      <div class="search-icon">
                        <img
                          src="@/assets/search-bar.jpeg"
                          alt=""
                          class=""
                          outline
                          :disabled="loading"
                          @click="querySingleRole()"
                        /><span :class="{ 'spinner-border': loading }"></span>
                      </div>
                    </div>
                    <div class="child-nav">
                      <div class="btn-nav">
                        <select class="sel-opt" v-model="perPage">
                          <option
                            v-for="option in [
                              { label: '5', value: 5 },
                              { label: '10', value: 10 },
                              { label: '50', value: 50 },
                              { label: '100', value: 100 },
                            ]"
                            class="select-danger"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value"
                          ></option>
                        </select>
                        <span class="page">Per page</span>
                      </div>

                      <div class="col-lg-6 col-md-6 col-sm-7 col-12">
                        <div class="text-right">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="items.length"
                            :per-page="perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--        <div class="row mb-2">-->
                  <!-- <label for="search" class="form-control-label">Search</label>-->
                  <!--        <div class="input-group">-->
                  <!--  <input type="text" v-model="filter" placeholder="Search ..." class="form-control" name="search">-->
                  <!--  <div class="input-group-append">-->
                  <!--    <base-button size="md" outline :disabled="loading" @click="querySingleRole()"  class="btn btn-primary"><i class="fas fa-search"></i>Search More  <span :class="{'spinner-border': loading}"></span></base-button>-->
                  <!--  </div>-->
                  <!--</div>-->
                  <!--</div>-->

                  <!--<div class="row my-1 mx&#45;&#45;4">-->
                  <!--<div class="col-lg-6 col-md-6 col-sm-5 col-12">-->

                  <!--        <el-select class="select-danger" filterable-->
                  <!--             placeholder=""-->
                  <!--             v-model="perPage">-->
                  <!--    <el-option v-for="option in [{label:'5',value:5},{label:'10',value:10},{label:'15',value:15}, {label:'100',value:100} ]"-->
                  <!--               class="select-danger"-->
                  <!--               :value="option.value"-->
                  <!--               :label="option.label"-->
                  <!--               :key="option.value">-->
                  <!--    </el-option>-->
                  <!--  </el-select>-->
                  <!--  <label class="form-control-label ml-1" for="">Per Page</label>-->
                  <!--        </div>-->

                  <!--    <div class="col-lg-6 col-md-6 col-sm-7 col-12">-->
                  <!--      <div class="text-right">-->
                  <!--        <b-pagination-->
                  <!--          v-model="currentPage"-->
                  <!--          :total-rows="items.length"-->
                  <!--          :per-page="perPage"-->
                  <!--          align="fill"-->
                  <!--          size="sm"-->
                  <!--          class="my-0"-->
                  <!--        ></b-pagination>-->
                  <!--        </div>-->
                  <!--    </div>-->
                  <!--    </div>-->

                  <!-- Main table element -->
                  <div>
                    <b-table
                      striped
                      hover
                      :items="items"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      :head-variant="light"
                      stacked="md"
                      show-empty
                      small
                      @filtered="onFiltered"
                    >
                      <template #cell(privileges)="row">
                        <div
                          class="pointer"
                          @click="modalsDisplay(row.item, row.index)"
                        >
                          <!--<i class="ni ni-zoom-split-in"></i>-->
                          <i class="fa fa-info-circle"></i>
                          <span class="ml-1">Info</span>
                          <!--<i class="fas fa-info-circle"></i>-->
                        </div>
                      </template>

                      <template #cell(roleStatus)="row">
                        <!-- {{ row.value }} {{ row.value}}{{ row.value}}-->
                        <template>
                          <badge class="badge-dot mr-4" type="">
                            <div v-if="row.value === 'ACTIVE'">
                              <badge type="success">ACTIVE</badge>
                            </div>
                            <div v-else>
                              <badge type="danger">NOT ACTIVE</badge>
                            </div>
                            <!--<i :class="[row.value == 'ACTIVE' ? 'bg-success' : 'bg-danger']"></i>
                        <span class="status">{{row.value}}</span>-->
                          </badge>
                        </template>
                      </template>

                      <template #cell(actions)="row">
                        <!--<b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
          Info modal
        </b-button>
        <b-button size="sm" @click="row.toggleDetails">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>-->
                        <template>
                          <el-dropdown trigger="click" class="dropdown">
                            <span class="btn btn-sm btn-icon-only text-dark">
                              <i class="fa fa-ellipsis-v mt-2"></i>
                              <!--<i class="ni ni-settings"></i>-->
                            </span>
                            <el-dropdown-menu
                              class="dropdown-menu dropdown-menu-arrow show"
                              slot="dropdown"
                            >
                              <a
                                class="dropdown-item"
                                @click="editRole(row.item, row.index)"
                              >
                                <i class="far fa-edit"></i>
                                <!--<i class="ni ni-ruler-pencil"></i>-->
                                Edit</a
                              >
                              <!--<a class="dropdown-item" href="#">Another action</a>
                            <a class="dropdown-item" href="#">Something else here</a>-->
                            </el-dropdown-menu>
                          </el-dropdown>
                        </template>
                      </template>

                      <template #row-details="row">
                        <b-card>
                          <ul>
                            <li v-for="(value, key) in row.item" :key="key">
                              {{ key }}: {{ value }}
                            </li>
                          </ul>
                        </b-card>
                      </template>
                    </b-table>
                  </div>
                  <!-- Info modal -->
                  <modal1
                    :show.sync="modals.modal3"
                    body-classes="p-0"
                    modal-classes="modal-dialog-centered modal-md"
                  >
                    <div
                      class="vodal-dialog"
                      style="
                        width: 500px;
                        height: 450px;
                        animation-duration: 300ms;
                      "
                    >
                      <article class="item-right">
                        <!-- @click="displayDetail()">-->
                        <a
                          ><span @click="modals.modal3 = false"
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              style="fill: #171717"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                              />
                            </svg> </span
                        ></a>
                      </article>
                      <!---<div class="card">-->
                      <div
                        class="card-body"
                        v-if="Object.keys(response_read).length !== 0"
                      >
                        <div>
                          <div class="view-more-customer-info">
                            <div class="view-more-customer-info-wrap">
                              <div class="view-more-top">
                                <h4 class="card-title mb-4">
                                  {{ model.roleName }} Privileges
                                </h4>
                              </div>
                              <div class="row mt-2">
                                <div class="col-sm-6">
                                  <p class="queue-title">Maker</p>
                                  <p class="queue-value">
                                    {{ response_read.roleMaker }}
                                  </p>
                                </div>
                                <div class="col-sm-6">
                                  <p class="queue-title">Created At</p>
                                  <p class="queue-value">
                                    {{ response_read.roleCreatedAt }}
                                  </p>
                                </div>
                              </div>
                              <div class="view-more-base mt-3">
                                <div class="privilege-list">
                                  <div class="row">
                                    <div
                                      class="col-sm-6"
                                      v-for="(
                                        resp, index
                                      ) in response_read.privileges"
                                      :key="index"
                                    >
                                      <p class="queue-value">
                                        <span>
                                          <i class="ni ni-collection"></i>
                                          {{
                                            capitalizeWord(resp.privilegeType)
                                          }}
                                          {{
                                            capitalizeWord(
                                              resp.privilegeModuleName
                                            )
                                          }}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <LoadingPanel></LoadingPanel>
                      </div>
                    </div>
                  </modal1>

                  <modal1
                    :show.sync="modals.modal2"
                    body-classes="p-0"
                    modal-classes="modal-dialog-centered modal-md"
                  >
                    <card
                      type="secondary"
                      header-classes="bg-white pb-0"
                      body-classes="bg-white px-lg-1 py-lg-1"
                      class="border-0"
                    >
                      <div
                        class="scrol"
                        v-if="Object.keys(response_read).length !== 0"
                      >
                        <h3 slot="header" class="mb-2 mt-2 text-center">
                          {{ model.roleName }} Privileges
                        </h3>
                        <!--<div class="card-header text-left">{{model.roleName}} Privileges</div>-->
                        <div class="card-body mt-3 mb-5">
                          <div class="row mt-2">
                            <div class="col-sm-6">
                              <p class="queue-title">Maker</p>
                              <p class="queue-value">
                                {{ response_read.roleMaker }}
                              </p>
                            </div>
                            <div class="col-sm-6">
                              <p class="queue-title">Created At</p>
                              <p class="queue-value">
                                {{ response_read.roleCreatedAt }}
                              </p>
                            </div>
                          </div>
                          <div class="view-more-base mt-3">
                            <div class="privilege-list">
                              <div class="row">
                                <div
                                  class="col-sm-6"
                                  v-for="(
                                    resp, index
                                  ) in response_read.privileges"
                                  :key="index"
                                >
                                  <p class="queue-value">
                                    <span>
                                      <i class="ni ni-collection"></i>
                                      {{ capitalizeWord(resp.privilegeType) }}
                                      {{
                                        capitalizeWord(resp.privilegeModuleName)
                                      }}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="text-right">
                          <base-button
                            @click="modals.modal3 = false"
                            type="primary"
                            class="my-2"
                            >Close</base-button
                          >
                        </div>
                      </div>
                      <div v-else>
                        <LoadingPanel></LoadingPanel>
                      </div>
                    </card>
                  </modal1>
                </div>
              </div>
            </div>
            <div v-else>
              <LoadingPanel></LoadingPanel>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
import { mapState, mapActions, mapGetters } from "vuex";
import LoadingPanel from "@/components/LoadingPanel";
export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    LoadingPanel,
  },
  data() {
    return {
      light: "light",
      model: {
        roleName: "",
        roleMaker: "",
        roleCreatedAt: "",
      },
      modals: {
        modal3: false,
        modal2: false,
      },
      items: [],
      fields: [
        { key: "rid", label: "S/N", sortable: true, sortDirection: "desc" },
        { key: "roleId", label: "ID", sortable: true, class: "text-left" },
        {
          key: "roleName",
          label: "Role Name",
          sortable: true,
          class: "text-left",
        },
        {
          key: "creator",
          label: "Creator",
          sortable: true,
          class: "text-left",
        },
        { key: "roleStatus", label: "Role Status", sortable: true },
        {
          key: "privileges",
          label: "Privileges",
          sortable: true,
          class: "text-left",
        },
        {
          key: "roleTrnLimit",
          label: "Transaction Limit",
          sortable: true,
          class: "text-left",
        },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    ...mapState({
      errors: (state) => state.role.role_errors,
      loading: (state) => state.role.role_loading,
      success: (state) => state.role.role_success,
      userInformation: (state) => state.auth.userInfo,
      response: (state) => state.role.role_response,
      response_read: (state) => state.role.role_read_response,
      response_single: (state) => state.role.role_read_response1,
    }),
    ...mapActions(["roleRead", "roleReadSingle"]),
    ...mapGetters(["getPrivilageResponse"]),
  },
  /*mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },*/
  methods: {
    querySingleRole() {
      if (
        this.filter !== null &&
        this.filter !== "" &&
        this.filter.length !== 0
      ) {
        this.$store.dispatch(
          "roleReadSingle1",
          {
            roleBankCode: this.userInformation.userBankCode,
            roleId: this.filter,
          },
          { root: false }
        );
      } else {
        this.notifyVue("danger", "Enter a role ID");
      }
    },

    editRole(item, index) {
      //this.modals.modal3 = true;
      this.model.roleName = item.roleName;
      this.model.roleMaker = item.roleMaker;
      //this.model.roleCreatedAt=item.roleCreatedAt;
      this.$store.dispatch(
        "roleReadSingle",
        {
          roleBankCode: this.userInformation.userBankCode,
          roleId: item.roleId,
          action: "edit",
        },
        { root: false }
      );
      /*{
          roleBankCode:roleBankCode,
          roleId:roleId
        }*/
    },
    modalsDisplay(item, index) {
      this.modals.modal3 = true;
      this.model.roleName = item.roleName;
      this.model.roleMaker = item.roleMaker;
      this.model.roleCreatedAt = item.roleCreatedAt;
      this.$store.dispatch(
        "roleReadSingle",
        {
          roleBankCode: this.userInformation.userBankCode,
          roleId: item.roleId,
          action: "display",
        },
        { root: false }
      );
      /*{
          roleBankCode:roleBankCode,
          roleId:roleId
        }*/
    },
    capitalizeWord(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    info(item, index) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    notifyVue(type = "default", msg) {
      console.log(type);
      this.$notify({
        message: `<b>${msg}</b>`,
        timeout: 5000,
        icon: "ni ni-bell-55",
        type,
      });
    },
    callapi() {
      //console.log(`<<<>>> ${this.userInformation.role.roleBankCode}`);
      this.$store.dispatch(
        "roleRead",
        {
          readAll: "YES",
          roleBankCode: "00004",
        },
        { root: false }
      );
    },
  },

  watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("danger", newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.notifyVue("success", `${newValue}`);
        //this.showDismissibleAlert=true;
      }
    },

    response_single(newValue, oldValue) {
      console.log("Response Query " + this.response_single);
      if (Object.keys(this.response_single).length !== 0) {
        if ("roleId" in this.response_single) {
          var arr = [];
          // data = this.response.data;
          arr.push(this.response_single);
          //console.log("&$$$$$&"+this.response.data);
          var count = 1;
          this.items = arr.map((item) => {
            return {
              rid: count++,
              roleId: item.roleId,
              roleName: item.roleName,
              creator: item.creator,
              roleStatus: item.roleStatus,
              privileges: item.privileges,
              roleTrnLimit: item.roleTrnLimit,
            };
          });
        } else {
          this.items = [];
          //this.initialLoading = true;
        }
      } else {
        this.items = [];
        //this.initialLoading = true;
      }
    },

    response(newValue, oldValue) {
      console.log("Response Query " + this.response);
      if (Object.keys(this.response).length !== 0) {
        if ("data" in this.response) {
          var data = [];
          data = this.response.data;
          //console.log("&$$$$$&"+this.response.data);
          var count = 1;
          this.items = data.map((item) => {
            return {
              rid: count++,
              roleId: item.roleId,
              roleName: item.roleName,
              creator: item.creator,
              roleStatus: item.roleStatus,
              privileges: item.privileges,
              roleTrnLimit: item.roleTrnLimit,
            };
          });
        } else {
          this.items = [];
          //this.initialLoading = true;
        }
      } else {
        this.items = [];
        //this.initialLoading = true;
      }
    },
  },
  updated() {
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("{{{{{{{{{{{{&&&&&&&&&&&&&&&&&&&&");
  },
  created: function () {
    //this.$store.dispatch("roleRead", {
    //   readAll: "YES",
    // roleBankCode:"00004"} , { root: false });
    //this.$store.dispatch("privilageRead", {
    //     readAll: "YES"} , { root: false });
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data[0].privilageModuleName);
  },
  //beforeRouteEnter(to, from, next) {
  //ledgerBalance((err, balance) => {
  //next(vm => vm.callapi());

  // },
  mounted: function () {
    if ("data" in this.response) {
      var data = [];
      data = this.response.data;
      //console.log("&$$$$$&"+this.response.data);
      var count = 1;
      this.items = data.map((item) => {
        return {
          rid: count++,
          roleId: item.roleId,
          roleName: item.roleName,
          creator: item.creator,
          roleStatus: item.roleStatus,
          privileges: item.privileges,
          roleTrnLimit: item.roleTrnLimit,
        };
      });
    }
    //this.data = this.$store.getters.getPrivilageResponse;
    //console.log("&$$$$$&"+this.data);
    this.$store.dispatch(
      "roleRead",
      {
        readAll: "YES",
        roleBankCode: this.userInformation.userBankCode,
      },
      { root: false }
    );
  },
};
</script>
<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.top-area {
  display: flex;
  margin-top: 0px !important;
  justify-content: space-between;
  align-items: center;
}

.search-icon {
  display: flex;
  justify-content: center;
  width: 20%;
}

.nav-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  margin: 10px;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

.child-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
}

.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.nav-btn {
  width: 35px;
  height: 35px;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
}

.num-dis {
  width: 35px;
  height: 35px;

  background: #175d85;
  border: 1px solid #175d85;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #ffffff;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  font-family: Rubik;
  font-style: normal;
}

.sel-opt {
  width: 28%;
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
}

.sel-opt:focus {
  outline: none;
}

.page {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #175d85;
  margin: 10px;
}

.search-bar {
  width: 25%;
  height: 5vh;
  background: rgba(196, 196, 196, 0.11);
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.search {
  background: rgba(196, 196, 196, 0.11);
  border: none;
  box-sizing: border-box;
  height: 5vh;
  width: 100%;
  padding: 10px;
}

.search:focus {
  outline: none;
}

::placeholder {
  color: rgba(23, 93, 133, 0.26);
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.table-section {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 40px;
  /*max-width: 100%;*/
  color: #6666 !important;
}

.scroll {
  overflow-y: scroll;
}
.privilege-list-item {
  margin-bottom: 15px;
}
.view-more-customer-info p {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
}
.pointer {
  cursor: pointer;
}
.queue-value {
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 20px;
}
.queue-title {
  margin-bottom: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #9e9e9e;
}
.left-pane {
  overflow-y: scroll;
  display: inline-block;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  width: 18%;
  height: 100%;
  background-color: #ffffff;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
  font-weight: 0;
}
.table-section tbody {
  max-width: 500px;
  overflow-x: scroll;
  color: #000 !important;
}
.table-btn {
  margin-top: 2rem !important;
}
.item-right {
  padding-left: 90%;
  cursor: pointer;
  margin: 0px 0% 5px 0px;
  float: right !important;
}
.vodal-dialog {
  overflow-y: scroll;
  overflow-x: hidden !important;
  background-color: #fff;
}
.vodal-dialog .card {
  box-shadow: none !important;
}
</style>
