import { render, staticRenderFns } from "./ProductViewTableCH.vue?vue&type=template&id=43341d96&scoped=true&"
import script from "./ProductViewTableCH.vue?vue&type=script&lang=js&"
export * from "./ProductViewTableCH.vue?vue&type=script&lang=js&"
import style0 from "./ProductViewTableCH.vue?vue&type=style&index=0&id=43341d96&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43341d96",
  null
  
)

export default component.exports