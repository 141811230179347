<template>
<div>
    <notifications></notifications>
<base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Create Customer Upload</h6>
<!--          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">-->
<!--            <ol class="breadcrumb breadcrumb-links">-->
<!--              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>-->
<!--              <li class="breadcrumb-item"><a href="#">Customer</a></li>-->
<!--              <li class="breadcrumb-item active" aria-current="page">Create Customer Upload</li>-->
<!--            </ol>-->
<!--          </nav>-->
        </div>
<!--        <div class="col-lg-6 col-5 text-right">-->
<!--          <base-button size="sm" type="neutral">New</base-button>-->
<!--          <base-button size="sm" type="neutral">Filters</base-button>-->
<!--        </div>-->
      </div>
      </base-header>
<div class="container-fluid mt--6">
      <div class="row">

        <div class="col">
            <card>
    <!-- Card header -->
<!--    <h3 slot="header" class="mb-0">Create Customer Upload</h3>-->

    <!-- Card body -->

    <form
         >
      <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <base-input label="Bank Logo">
          <b-form-file  id="myfile"
       name="csv_file"  @change="loadCSV($event)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder=""
    ></b-form-file>
    <div class="output"></div>
    <p v-if="fileCtrl" class="form-error-message"> The CSV file is required </p>
                </base-input>

        </div>
      </div>
        <div class="row">
            <div class="col-6 text-left">
                <a  href="https://assets-base.s3.amazonaws.com/csv/powerbank/create-customer-bulk-sample.csv" target="_blank" class="sample-file-download ">
                <span  class="fa fa-file-download"></span> Download Sample CSV File </a>
            </div>
      <div class="col-6 text-right">
          <base-button @click="loadData" icon type="primary" :class="{disabled:loading_customer}">
     <span class="btn-inner--text">Upload</span>
       <span :class="{'spinner-border': loading_customer}"></span>
     </base-button>
      <!--<base-button type="primary" native-type="submit">Submit form</base-button>-->
      </div>
        </div>
    </form>


  <!--<b-container class="table-container">--> <!--class="over-flow">-->
  <b-collapse id="collapse-4" v-model="collapse_ctrl" class="mt-2">
  <card class="" body-classes="px-3 pb-1" footer-classes="pb-2">
  <div class="form-body">
      <div class="table-section col-12">
    <!-- User Interface controls -->
    <b-row>
      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="customerArr.length"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <div>
    <b-table striped hover
      :items="customerArr"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="lg"
      show-empty
      large
      :head-variant="light"

    >

      <template #cell(action)="row">
        <div class="pointer" @click="customerDeleteItem(row.item)">
        <el-tooltip content="Delete" placement="left">

          <!--<i class="ni ni-zoom-split-in"></i>
          <span class="ml-1">Info</span>-->
        <i class="fas fa-trash-alt" style="color:red"></i>
        </el-tooltip>
        </div>
      </template>




      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    </div>

</div>
  </div>
</card>
  </b-collapse>
  </card>

        </div>
      </div>
</div>
</div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    data() {
    return {
        fileCtrl:false,
        //collapse_ctrl:false,
      channel_name: '',
      channel_fields: [],
      channel_entries: [],
      parse_header: [],
      parse_csv: [],
      items:[],
      csvContent:[],
      fields: [
          { key: 'sn', label: 'S/N', sortable: true, sortDirection: 'desc' },
          { key: 'customerFirstName', label: 'First Name', sortable: true, class: 'text-left' },
            { key: 'customerMiddleName', label: 'Middle Name', sortable: true, class: 'text-left' },
            { key: 'customerLastName', label: 'Last Name', sortable: true, class: 'text-left' },
          { key: 'customerBvn', label: 'BVN', sortable: true, class: 'text-left' },
          {key: 'customerPhoneNumber',label: 'PhoneNumber',sortable: true,class: 'text-left'},
          { key: 'customerCountryCode', label: 'CountryCode', sortable: true, class: 'text-left' },
          { key: 'customerAddress', label: 'Address  ', sortable: true, class: 'text-left' },
          { key: 'customerCountry', label: 'Country', sortable: true, sortDirection: 'desc' },
          { key: 'customerDob', label: 'Date of Brith', sortable: true, class: 'text-left' },
          { key: 'dateCreated', label: 'Created At', sortable: true, class: 'text-left' },
          { key: 'customerEmail', label: 'Email', sortable: true, class: 'text-left' },
          { key: 'customerState', label: 'State', sortable: true, class: 'text-left' },
          {key: 'customerBranchCode',label: 'BranchCode',sortable: true,class: 'text-left'},
          { key: 'customerGender', label: 'Gender', sortable: true, class: 'text-left' },
          { key: 'accountNumber', label: 'AccountNumber  ', sortable: true, class: 'text-left' },
          { key: 'accountClass', label: 'ccountClass  ', sortable: true, class: 'text-left' },
          { key: 'accountCurrency', label: 'Currency  ', sortable: true, class: 'text-left' },
          { key: 'action', label: 'Action' }

        ],
      sortOrders:{},
      sortKey: '',

light:'light',
      totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
    };
  },
  filters: {
    capitalize: function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  methods: {
    /*sortBy: function (key) {
      var vm = this
      vm.sortKey = key
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
    csvJSON(csv){
      var vm = this
      var lines = csv.split("\n")
      var result = []
      var headers = lines[0].split(",")
      vm.parse_header = lines[0].split(",")
      lines[0].split(",").forEach(function (key) {
        vm.sortOrders[key] = 1
      })

      lines.map(function(line, indexLine){
        if (indexLine < 1) return // Jump header line

        var obj = {}
        var currentline = line.split(",")

        headers.map(function(header, indexHeader){
          obj[header] = currentline[indexHeader]
        })

        result.push(obj)
      })

      result.pop() // remove the last item because undefined values

      return result // JavaScript object
    },*/
    loadCSV(e) {
      var vm = this
      if (window.FileReader) {

        var reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        // Handle errors load
        reader.onload = function(event) {
          var csv = event.target.result;
         // vm.parse_csv = vm.csvJSON(csv);
         var lines = csv.split("\n");
        var file_header = lines[0].split(",")
        console.log(file_header);
        var required_header = ["customerFirstName", "customerMiddleName", "customerLastName", "customerBvn", "customerPhoneNumber", "customerCountryCode", "customerAddress", "customerCountry", "customerDob", "dateCreated", "customerEmail", "customerState", "customerBranchCode", "customerGender", "accountNumber", "accountClass", "accountCurrency"];

        if(vm.areEqual(required_header, file_header)){
                       //var lines = csv.split("\n");
var results = [];
//var split_objects =
lines.map(function(str,index) {
   if(index<1){return}
  var obj ={};
  var box = str.split(',');
  obj['customerFirstName'] = box[0];
  obj['customerMiddleName'] = box[1];
  obj['customerLastName'] = box[2];
  obj['customerBvn'] = box[3];
  obj['customerPhoneNumber'] = box[4];
  obj['customerCountryCode'] = box[5];
  obj['customerAddress'] = box[6];
  obj['customerCountry'] = box[7];
  obj['customerDob'] = box[8];
  obj['dateCreated'] = box[9];
  obj['customerEmail'] = box[10];
  obj['customerState'] = box[11];
  obj['customerBranchCode'] = box[12];
  obj['customerGender'] = box[13];
  obj['accountNumber'] = box[14];
  obj['accountClass'] = box[15];
  obj['accountCurrency'] = box[16];
   results.push(obj);
  return {}
});

vm.callApi(results);
console.log(results);
        }
        else{
            vm.notifyVue('danger','CSV File Structure Not Supported ');
        }


 };
        reader.onerror = function(evt) {
          if(evt.target.error.name == "NotReadableError") {
            alert("Canno't read file !");
          }
        };
      } else {
        alert('FileReader are not supported in this browser.');
      }
    },

callApi(result){
    console.log(`>>>??? ${result}`);
    this.$store.dispatch("customerArray", result, { root: false });
},

areEqual (first, second){
console.log(first.length);
console.log(second.length);

  if(first.length !== second.length){
      return false;
   };
   return true;
  /* for(let i = 0; i < first.length; i++){
      if(!second.includes(first[i])){
         return false;
      };
   };
   return true;*/
  // return first.sort().toString() === second.sort().toString();
},

customerDeleteItem(item){
    console.log(`????? ${item}`)
    this.$store.dispatch("customerDelete", {
        customerBvn:item.customerBvn,
        customerPhoneNumber:item.customerPhoneNumber,
        accountNumber:item.accountNumber,
        customerBranchCode:item.customerBranchCode
    }, { root: false });
},

notifyVue(type = 'default',msg) {
        console.log(type);
        this.$notify({
          message:`<b>${msg}</b>`,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
    loadData(){
        if( document.getElementById("myfile").files.length == 0 ){
                this.fileCtrl=true;
            }else{
                if(this.$store.getters.getCustomerUploadArr.length!==0){
              this.fileCtrl=false;
            this.$store.dispatch("customerUpload",
            {data:this.$store.getters.getCustomerUploadArr,
                createCustomer:'YES',
                bankCode:this.userInformation.userBankCode,
                maker: this.userInformation.userId,
                requestType: "bulkUpload"
              }
            , { root: false });

                }
                else{
                    this.notifyVue('danger','No data to Upload');
                }
          }
    }
  },
  watch: {
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('success',newValue);
     }
      },
       errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
     if (newValue !== null) {
         this.notifyVue('danger',newValue);
        //this.showDismissibleAlert=true;
      }
    },
  },
  computed: {
   ...mapState({errors: state => state.customer.customer_errors,loading_customer: state => state.customer.customer_loading,
   success: state => state.customer.customer_success, customerArr: state => state.customer.customer_arr,
   collapse_ctrl: state => state.customer.customer_ctrl,
   userInformation: state => state.auth.userInfo
   }),
    ...mapActions(['customerArray']),
    ...mapGetters([""]),

    },
}
</script>

<style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.table-section tbody {
    max-width: 500px;
    overflow-x: scroll;
}
.table-section {
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 40px;
    /*max-width: 100%;*/
}
.form-error-message {
   width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #fb6340;
}
.sample-file-download {
    cursor: pointer;
}
</style>


